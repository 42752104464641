import { memo, useState } from 'react'
import { useWebsiteContext } from '@app/components/providers/website'
import { MonitoringEnabledInput } from '../../inputs/monitoring-input'

export const MonitoringEnabledWrapper = ({
  enabled,
  url,
  activeSubscription,
  domain,
}: {
  enabled?: boolean
  url?: string
  activeSubscription?: boolean
  domain?: string
}) => {
  const [monitoringEnabled, setMonitoring] = useState<boolean>(!!enabled)
  const { updateWebsite } = useWebsiteContext()

  const onMonitoringEvent = async () => {
    let nextValue = !monitoringEnabled

    setMonitoring(nextValue)

    try {
      await updateWebsite({
        variables: { url, monitoringEnabled: nextValue },
      })
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <MonitoringEnabledInput
      checked={monitoringEnabled}
      activeSubscription={activeSubscription}
      onChange={onMonitoringEvent}
      domain={domain}
    />
  )
}

export const MonitoringEnabledBox = memo(MonitoringEnabledWrapper)
