import { useState } from 'react'

type Field = { path: string; events: string[] }
type FieldOptions = Field[] | { customFields?: Field[] }

const initCustomFields = (options?: FieldOptions) => {
  const baseFields = Array.isArray(options) ? options : options?.customFields
  // establish default fields to add
  const fields =
    baseFields && baseFields?.length
      ? baseFields.map(({ path, events }) => ({ path, events })) // remove gql types
      : [{ path: '', events: [] }]

  return fields
}

// actions events for website
export function useInputActions(options?: FieldOptions, defaultOpen?: boolean) {
  const [customActions, setCustomActions] = useState(!!defaultOpen)
  const [customFields, setCustomField] = useState(initCustomFields(options))

  const addFormField = () => {
    const newFields = customFields.slice()
    newFields.push({ path: '', events: [] })
    setCustomField(newFields)
  }

  const updateFormField = (value: any, index: number, type: string) => {
    const cloneField = customFields?.map((item: any, i: number) => {
      if (i === index) {
        item[type] = value
      }
      return item
    })
    setCustomField(cloneField)
  }

  const removeFormField = (index: number) => {
    const newFields = customFields.slice()
    newFields.splice(index, 1)
    setCustomField(newFields)
  }

  return {
    removeFormField,
    updateFormField,
    addFormField,
    customFields,
    customActions,
    setCustomActions,
  }
}
