import { classNames } from '@app/utils/classes'

export const dialogBtnStyles =
  'min-w-[13rem] py-2 px-1 rounded border text-gray-50 bg-gray-900 dark:text-gray-50 dark:bg-gray-800 hover:bg-gray-800 hover:text-blue-300 hover:border-blue-600 dark:hover:text-blue-300 dark:hover:border-blue-600'

export const getBtnDialogStyle = (websitUrl: boolean | string) =>
  classNames(
    !websitUrl
      ? 'text-primary-400 dark:text-primary-600'
      : 'text-white dark:text-black hover:rounded hover:bg-gray-200 hover:text-blue-600 dark:hover:text-blue-600 dark:hover:bg-gray-800',
    `font-bold w-full border-l-0 border-b-0 border-r-0 border-t rounded-none py-2 md:py-3`
  )

export const baseFormDialogButtonStyle = (
  {
    buttonStyles,
    iconButton,
  }: { buttonStyles?: string; iconButton?: boolean } = {
    buttonStyles: '',
    iconButton: false,
  }
) => classNames(buttonStyles || '', !iconButton ? 'px-8 lg:px-12' : '')
