// request data errors to make to translations
export const dataErrors: Record<string, string> = {
  'Max websites added. Upgrade your account or remove a website to continue.':
    'max-websites',
  'Please check your email and password and try again.': 'auth-email',
  'Error, please sign in to continue.': 'auth-error',
  'Error, a paid plan is required to make API request.': 'paid-api',
  'Authentication and paid plan required. Add your authentication header and try again.':
    'paid-auth',
  'Error, an issue has occurred.': 'general-error',
  'JWT: expired please sign in again to continue.': 'jwt-expired',
  'Website already exist, try another name.': 'website-exist',
  'RATE EXCEEDED: Try again later or upgrade your account.': 'rate-exceeded',
  'Email confirmation required to commence scans and crawls.':
    'email-confirmation',
  'Scan timeout, rendered too slow over 15000 ms or not at all. Check your url and try again.':
    'scan-timeout',
  'Account upgrade required.': 'upgrade',
  'Scan limit reached, upgrade your account or wait until your limit resets.':
    'scan-limit',
}
