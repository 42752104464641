import { memo, useState } from 'react'
import { useWebsiteContext } from '@app/components/providers/website'
import { MobileInput } from '../../inputs/mobile-input'

export const MobileBoxWrapper = ({
  mobile,
  url,
  domain,
  activeSubscription,
}: {
  mobile?: boolean
  url?: string
  domain?: string
  activeSubscription?: boolean
}) => {
  const [isMobile, setMobile] = useState<boolean>(!!mobile)
  const { updateWebsite } = useWebsiteContext()

  const onMobileEvent = async () => {
    let nextValue = !isMobile

    setMobile(nextValue)

    try {
      await updateWebsite({
        variables: { url, mobile: nextValue },
      })
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <MobileInput
      onChange={onMobileEvent}
      url={url}
      domain={domain}
      mobile={isMobile}
      activeSubscription={activeSubscription}
    />
  )
}

export const MobileBox = memo(MobileBoxWrapper)
