import { memo } from 'react'
import { Pages } from '@app/types'
import { ListCellPagesHeader } from './pages-header'
import { Translate } from 'next-translate'

// return issues maped
const PagesWrapper = ({
  handleMainClick,
  domain,
  url,
  pageUrl,
  online,
  pageLoadTime,
  totalTTL,
  extraActive,
  demo,
  t,
}: Pages & {
  totalTTL: number
  open?: boolean
  small?: boolean
  singleRow?: boolean
  extraActive?: boolean
  handleMainClick?(ata: any, name: string, _mini: boolean, url: string): void
  demo?: boolean
  t?: Translate
}) => (
  <ListCellPagesHeader
    url={url || pageUrl}
    domain={domain as string}
    online={online}
    totalTTL={totalTTL}
    duration={pageLoadTime?.duration}
    handleMainClick={handleMainClick}
    extraActive={extraActive}
    demo={demo}
    t={t}
  />
)

// memo expensive issues
export const PagesList = memo(PagesWrapper)
