import { memo } from 'react'
import { GrAdd, GrSubtract } from 'react-icons/gr'
import { useInputActionsEvents } from '../hooks/input-actions-events'
import { ActionsSelectInput } from '../select/actions-input'
import { Button } from '../buttons'

const InputActionItem = ({
  _item,
  path,
  index,
  updateFormEvents,
  updateFormField,
  removeFormField,
  customFields,
  addFormField,
}: any) => {
  const onStandardChange = (value: string) => {
    updateFormField(value, index, 'events')
    // update parent form field of action events
    updateFormEvents &&
      updateFormEvents(customFields.map((item: any) => item.events))
  }

  const onRemoveItems = () => {
    removeFormField(index)
    // update parent form field of action events
    updateFormEvents &&
      updateFormEvents(
        customFields
          .filter((_: any, i: number) => i !== index)
          .map((item: any) => item.events)
      )
  }

  return (
    <>
      <ActionsSelectInput
        onStandardChange={onStandardChange}
        path={path}
        {..._item}
      />
      {customFields.length > 1 ? (
        <Button
          aria-label='Remove actions field'
          onClick={onRemoveItems}
          className='hover:border-blue-600 hover:text-blue-600'
          iconButton
        >
          <GrSubtract className='grIcon' />
        </Button>
      ) : null}
      {index === customFields.length - 1 ? (
        <Button
          aria-label='Add actions field'
          onClick={addFormField}
          className='hover:border-blue-600 hover:text-blue-600'
          iconButton
        >
          <GrAdd className='grIcon' />
        </Button>
      ) : null}
    </>
  )
}

const InputActionsEventsComponent = ({
  updateFormEvents,
  path,
  events,
}: {
  path: string
  updateFormEvents?: (a: any) => void
  events: string[]
}) => {
  const { customFields, removeFormField, addFormField, updateFormField } =
    useInputActionsEvents({ events, path })

  if (!path) {
    return null
  }

  if (customFields && !customFields.length) {
    return (
      <InputActionItem
        customFields={customFields}
        index={0}
        addFormField={addFormField}
        removeFormField={removeFormField}
        updateFormField={updateFormField}
        updateFormEvents={updateFormEvents}
        path={path}
      />
    )
  }

  // path set iterate events passed in
  return (
    <ul className='py-1 list-none'>
      {customFields?.map((_item: any, index: number) => {
        return (
          <li className={`flex flex-1 gap-x-3 px-1 py-1`} key={index}>
            <InputActionItem
              _item={_item}
              customFields={customFields}
              index={index}
              addFormField={addFormField}
              removeFormField={removeFormField}
              updateFormField={updateFormField}
              updateFormEvents={updateFormEvents}
              path={path}
            />
          </li>
        )
      })}
    </ul>
  )
}

export const InputActionsEvents = memo(InputActionsEventsComponent)
