import { Fragment } from 'react'
import { Link } from '@app/components/stateless/typo/link'
import { PageIssue, Website } from '@app/types'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { classNames } from '@app/utils/classes'
import { routeParseAbsolute } from '@app/lib/router-handler'

type CDNProps = {
  website: Website & {
    issues: PageIssue[]
  }
  disablePlayground: boolean
  authenticated: boolean
  dashboard?: boolean
}

// TODO: rename
const CtaCdn = ({ website, disablePlayground, authenticated }: CDNProps) => {
  const { t, lang } = useTranslation()

  const totalCurrentIssues = website?.issuesInfo?.totalIssues || 0
  const currentIssues = website?.issues?.length || 0
  const pdfView = website && 'result' in website

  const suf = totalCurrentIssues === 1 ? 'issues' : 'issues'

  let limitedResonse = t('audit-form:complete')

  // use loading property for page
  if (!website || (!pdfView && website && !website?.issues)) {
    limitedResonse = t('audit-form:gathering')
  }

  if (website && website?.issuesInfo) {
    const issuesT = t(`audit-form:${suf}`)

    // no auth
    if (!authenticated) {
      limitedResonse = `${t('audit-form:limited-start')} ${currentIssues}/${
        totalCurrentIssues
          ? `${totalCurrentIssues}${
              currentIssues < totalCurrentIssues ? '+' : ''
            }`
          : '_'
      } ${issuesT} ${t('audit-form:limited-end')}`
    } else {
      limitedResonse = t('audit-form:limited-auth', {
        total: totalCurrentIssues,
      })
    }
  }

  return (
    <Fragment>
      <div className='py-2'>
        <p className='text-sm'>{limitedResonse}</p>
      </div>

      {disablePlayground || authenticated ? null : (
        <div className={'flex align-center space-x-2'}>
          <Link
            href={routeParseAbsolute('/login', lang)}
            shouldPrefetch={false}
            className={classNames(
              `px-6 py-1.5 bg-black text-gray-100 rounded focus:text-blue-600 dark:bg-blue-600 dark:text-gray-100 dark:focus:bg-blue-500`,
              'hover:no-underline hover:text-gray-100 hover:bg-gray-700 dark:hover:bg-blue-500 dark:hover:text-gray-50'
            )}
          >
            {t('common:login')}
          </Link>
          <Link
            href={routeParseAbsolute('/register', lang)}
            shouldPrefetch={false}
            className={classNames(
              `px-6 py-1.5 border-none rounded text-grey-700 no-underline`,
              'hover:bg-gray-200 hover:no-underline hover:text-blue-600 dark:hover:bg-gray-800 dark:focus:text-blue-600 dark:focus:bg-gray-800'
            )}
          >
            {t('common:register')}
          </Link>
        </div>
      )}
    </Fragment>
  )
}

export { CtaCdn }
