export enum Standard {
  'WAIT_FOR_ELEMENT',
  'CLICK_ELEMENT',
  'SET_FIELD',
  'CLEAR_FIELD',
  'CHECK_FIELD',
  'UNCHECK_FIELD',
}

export enum WAIT_OPTIONS {
  'ADDED',
  'REMOVED',
  'VISIBLE',
  'HIDDEN',
  'EMIT_LOAD',
  'EMIT_LOADED',
}
