export const domainList = [
  '.com',
  '.co',
  '.org',
  '.us',
  '.net',
  '.blog',
  '.io',
  '.biz',
  '.gov',
  '.me',
  '.ai',
  '.mil',
  '.edu',
  '.int',
  '.club',
  '.ninja',
  '.health',
  '.online',
  '.tech',
  '.cloud',
  '.sh',
  '.star',
  '.xyz',
  // '',
]
