import {
  Analytic,
  IssueMeta,
  PageIssue,
  PageLoadTimeMeta,
  Pages,
} from '@app/types'
import { useMemo } from 'react'

type LiveItem = Analytic & {
  pageLoadTime: PageLoadTimeMeta
  issues?: PageIssue[]
}

// format live data for a website
export const useWebsiteLiveData = ({
  issues,
  issuesInfo,
}: {
  issues?: Pages[]
  issuesInfo?: IssueMeta | undefined
  url?: string
}) => {
  return useMemo(() => {
    let errors = 0
    let warnings = 0
    let notices = 0
    let liveData: LiveItem[] = []

    const issueArray = issues && Array.isArray(issues) && issues.length

    if (issueArray) {
      for (const iss of issues) {
        const pageInfo = iss?.issuesInfo
        const pageIssues = iss?.issues

        let currentErrors = 0
        let currentWarnings = 0
        let currentNotices = 0

        // direct object handling
        if (pageInfo) {
          currentErrors = pageInfo.errorCount ?? 0
          currentWarnings = pageInfo.warningCount ?? 0
          currentNotices = pageInfo.noticeCount ?? 0
          errors += currentErrors
          warnings += currentWarnings
          notices += currentNotices
        } else if (pageIssues) {
          for (const page of pageIssues) {
            if (page?.type === 'error') {
              currentErrors++
            }
            if (page?.type === 'warning') {
              currentWarnings++
            }
            if (page?.type === 'notice') {
              currentNotices++
            }
            errors += currentErrors
            warnings += currentWarnings
            notices += currentNotices
          }
        }

        liveData.push({
          pageUrl: (iss.pageUrl || iss.url) as string,
          pageLoadTime: iss.pageLoadTime as PageLoadTimeMeta,
          errorCount: currentErrors,
          warningCount: currentWarnings,
          noticeCount: currentNotices,
          issues: iss.issues,
        })
      }
    } else if (issuesInfo) {
      errors = issuesInfo.errorCount || 0
      warnings = issuesInfo.warningCount || 0
      notices = issuesInfo.noticeCount || 0
    }

    return {
      issuesFixedByCdn: 0,
      errorCount: errors,
      warningCount: warnings,
      noticeCount: notices,
      totalIssues: errors + warnings + notices,
      liveData,
    }
  }, [issues, issuesInfo])
}
