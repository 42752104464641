import { useState } from 'react'
import { Standard, WAIT_OPTIONS } from '../select/action-enums'
// import { WAIT_OPTIONS, Standard} from "./action-enums"

type Field = { path: string; events: string }
type FieldProps =
  | Field[]
  | { customFields?: Field[]; events?: string[]; path?: string }

const isNumeric = (n: string) => !isNaN(parseFloat(n))

const establishCustomFields = (
  options?: FieldProps & {
    customFields?: Field[]
    events?: string[]
    path?: string
  }
) => {
  // build the event fields
  const fields =
    Array.isArray(options) && options.length
      ? options
      : options?.customFields && options?.customFields.length
        ? options?.customFields
        : [{ path: options?.path ?? '', events: '' }]

  // map through and create the fields
  if (
    options &&
    !Array.isArray(options) &&
    options.events &&
    Array.isArray(options.events) &&
    options.events.length
  ) {
    // todo: display the default data properties per event to take over
    return options.events.map((event) => {
      let defaultSelector
      let defaultValue

      let defaultWaitfor
      let defaultAction

      // replace the event with the item
      let eventReplacer = event

      for (const item in Standard) {
        if (!isNumeric(item)) {
          const cl = item.toLowerCase().replaceAll('_', ' ')

          if (event && event.startsWith(cl)) {
            defaultAction = item.toString()
            eventReplacer = eventReplacer.replace(cl, '')
          }
        }
      }

      for (const item in WAIT_OPTIONS) {
        if (!isNumeric(item)) {
          const cl = item.toLowerCase().replaceAll('_', ' ')

          if (event && event.endsWith(cl)) {
            defaultWaitfor = item.toString()
            eventReplacer = eventReplacer.replace(cl, '')
          }
        }
      }

      const itemS = eventReplacer && eventReplacer.split(' ').filter((it) => it)

      if (itemS && itemS.length === 1) {
        defaultSelector = itemS[0]
      }

      // input field
      if (itemS && itemS.length === 2 && !defaultAction) {
        defaultSelector = itemS[0]
        defaultValue = itemS[1]
      }

      return {
        path: options?.path ?? '',
        events: event || '',
        // TODO: default properties
        defaultSelector,
        defaultValue,
        // end values
        defaultAction,
        defaultWaitfor,
      }
    })
  }

  return fields
}

// ActionsEvents events for website
export function useInputActionsEvents(options?: FieldProps) {
  const [customActionsEvents, setCustomActionsEvents] = useState(false)
  const [customFields, setCustomField] = useState(
    establishCustomFields(options)
  )

  const addFormField = () => {
    const newFields = customFields.slice()
    newFields.push({ path: '', events: '' })
    setCustomField(newFields)
  }

  const updateFormField = (value: any, index: number, type: string) => {
    const cloneField = customFields?.map((item: any, i: number) => {
      if (i === index) {
        item[type] = value
      }
      return item
    })
    setCustomField(cloneField)
  }

  const removeFormField = (index: number) => {
    const newFields = customFields.slice()
    newFields.splice(index, 1)
    setCustomField(newFields)
  }

  return {
    removeFormField,
    updateFormField,
    addFormField,
    customFields,
    customActionsEvents,
    setCustomActionsEvents,
  }
}
