import { websiteFragments } from '@app/apollo'
import gql from 'graphql-tag'

export const ADD_WEBSITE = gql`
  ${websiteFragments}
  mutation AddWebsite(
    $url: String!
    $customHeaders: [CreatePageHeaders]
    $mobile: Boolean
    $standard: String
    $ua: String
    $actions: [PageActionsInput]
    $robots: Boolean
    $subdomains: Boolean
    $tld: Boolean
    $ignore: [String]
    $rules: [String]
    $runners: [String]
    $proxy: String
    $sitemap: Int
    $monitoringEnabled: Boolean
    $crawlDelay: Int
    $rootElement: String
    $hideElements: [String]
    $theme: [String]
    $warningsEnabled: Boolean
    $crawlLimit: Int
    $blacklist: [String]
    $profileVisible: Boolean
  ) {
    addWebsite(
      url: $url
      customHeaders: $customHeaders
      mobile: $mobile
      standard: $standard
      ua: $ua
      actions: $actions
      robots: $robots
      subdomains: $subdomains
      tld: $tld
      ignore: $ignore
      rules: $rules
      runners: $runners
      proxy: $proxy
      sitemap: $sitemap
      monitoringEnabled: $monitoringEnabled
      crawlDelay: $crawlDelay
      rootElement: $rootElement
      hideElements: $hideElements
      theme: $theme
      warningsEnabled: $warningsEnabled
      crawlLimit: $crawlLimit
      blacklist: $blacklist
      profileVisible: $profileVisible
    ) {
      website {
        ...WebsiteParts
      }
      code
      success
      message
    }
  }
`
