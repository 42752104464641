import { REST_API, DOMAIN_NAME } from '@app/configs'
import { useMemo } from 'react'

// get domain links
export const useDomainLinks = ({ url }: { url: string }) => {
  const { linkUrl, domainHost } = useMemo(() => {
    const encodedUrl = url ? encodeURIComponent(url) : ''
    const reportsLink = `${REST_API}${
      REST_API.endsWith('/') ? '' : '/'
    }reports/${encodedUrl}`
    const reportsPageLink = `/reports/${encodedUrl}`
    // hostname should always be valid - ignore try catching
    const hostname = url && new URL(url).hostname

    return {
      domainHost: hostname,
      encodedUrl,
      reportsLink,
      reportsPageLink,
      linkUrl: `${DOMAIN_NAME}/website-details?url=${encodedUrl}`,
    }
  }, [url])

  return {
    linkUrl,
    domainHost,
  }
}
