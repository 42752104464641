import { frameDom } from '@app/utils/frame-dom'

const injectedStyles = `
  .relative {
    position: relative;
  }
  .popover-wrapper {

  }
  .popover-container {
    padding: 0.2rem;
  }
  code.popover-code-block {
    font-size: 0.75rem;
  }
  .popover-closebtn {
    border-radius: 5rem;
    padding: 0.3rem;
  }
  .popover-closebtn:hover {
      outline: 1px solid #ccc;
  }`

// IFRAME LOADER
export const onLoad = (event: any, { setFrameContent, iframeRef }: any) => {
  const dom =
    (typeof iframeRef !== 'undefined' && iframeRef?.current?.contentDocument) ||
    event?.target?.contentDocument

  if (dom) {
    frameDom.setFrameDom(dom)
    const style = document.createElement('style')

    style.type = 'text/css'
    style.innerHTML = injectedStyles
    document.head.appendChild(style)

    if (typeof setFrameContent === 'function') {
      setFrameContent()
    }
  }
}
