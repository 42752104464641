import gql from 'graphql-tag'

export const ISSUE_SUBSCRIPTION = gql`
  subscription issueAdded {
    issueAdded {
      domain
      url
      issuesInfo {
        accessScore
        totalIssues
        errorCount
        warningCount
        noticeCount
        issueMeta {
          skipContentIncluded
        }
      }
      pageLoadTime {
        duration
      }
      issue {
        code
        type
        selector
        message
        context
        recurrence
        runner
        runnerExtras {
          description
          impact
          helpUrl
        }
        clip {
          x
          y
          width
          height
        }
        clipBase64
      }
    }
  }
`
