import { ElementType, Fragment, memo } from 'react'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { useRouter } from 'next/router'
import { classNames } from '@app/utils/classes'
import { Menu } from '@headlessui/react'
import { HiTranslate } from 'react-icons/hi'
import { getTranslateStyleMarketing } from './translate-style'
import { translateMap } from './translate-map'
import { GrDown, GrUp } from 'react-icons/gr'
import { PopoverBtn } from './translate-button'
import { twMerge } from 'tailwind-merge'

type TranslateProps = {
  asComponent?: ElementType
  className?: string
  left?: boolean
  noicon?: boolean
  onChangeLanguage?(n: string): Promise<void>
  full?: boolean
}

const TranslateComponentLink = ({
  left,
  noicon,
  className,
  asComponent,
  full,
}: TranslateProps) => {
  const router = useRouter()
  const { t, lang } = useTranslation('lang')
  const translateText = t('translate')

  return (
    <Menu
      as={asComponent ?? 'div'}
      className={twMerge('relative', className ?? '')}
    >
      {({ open }) => (
        <Fragment>
          <Menu.Button
            type={'button'}
            className={twMerge(
              getTranslateStyleMarketing,
              full
                ? 'border text-gray-700 dark:text-gray-300 dark:border-gray-700'
                : ''
            )}
            title={translateText}
          >
            {noicon ? (
              <span>{translateText}</span>
            ) : full ? (
              <span className='flex gap-x-1.5 place-items-center'>
                <span className='sr-only'>{translateText}</span>
                <HiTranslate className='grIcon' />
                <span className='text-sm uppercase font-semibold'>
                  {translateMap[lang as keyof typeof translateMap]}
                </span>
                {open ? (
                  <GrUp className='grIcon text-xs' />
                ) : (
                  <GrDown className='grIcon text-xs' />
                )}
              </span>
            ) : (
              <span className='flex gap-x-1.5'>
                <span className='sr-only'>{translateText}</span>
                <HiTranslate className='grIcon' />
              </span>
            )}
          </Menu.Button>

          <Menu.Items
            static
            className={classNames(
              'absolute z-20 mt-2 w-[90vw] max-w-xs rounded bg-white border-2 border-gray-400 dark:border-gray-400 dark:bg-black text-black dark:text-white',
              left
                ? 'ltr:-left-5 ltr:md:left-0 rtl:-right-5 rtl:md:right-0'
                : 'ltr:right-0 rtl:left-0',
              !open ? 'hidden' : 'visible'
            )}
          >
            {[
              'de',
              'en',
              'es',
              'fr',
              'hi',
              'ja',
              'pt-BR',
              'pt-PT',
              'ru',
              'zh-CN',
              'zh-TW',
              'ar',
            ].map((item) => (
              <PopoverBtn
                key={item}
                item={item}
                defaultLocale={router.defaultLocale}
                pathname={router.pathname}
                lang={lang}
                t={t}
                Component={Menu.Item}
              />
            ))}
          </Menu.Items>
        </Fragment>
      )}
    </Menu>
  )
}

export const TranslateLanguageLink = memo(TranslateComponentLink)
