import { routeParseAbsolute } from '@app/lib/router-handler'
import Head from 'next/head'
import { companyName, twitterSite } from '@app/configs/app-config'
import { inlineTheme } from '@app/lib/inline-theme'

type LayoutProps = {
  lang: string
  pathName: string
  pageFind?: boolean
  metaTitle: string
  metaDescription: string
}

export const LayoutHead = ({
  pathName,
  lang,
  pageFind,
  metaTitle,
  metaDescription,
}: LayoutProps) => {
  const gimage = routeParseAbsolute(
    `/api/og?title=${metaTitle}&pathName=${pathName}`
  )

  return (
    <Head>
      <meta charSet='utf-8' key={'charset'}></meta>
      <meta
        name='viewport'
        content='width=device-width, initial-scale=1'
        key='viewport'
      ></meta>
      <title key='title'>{metaTitle}</title>
      <meta
        name='description'
        content={metaDescription}
        key='description'
      ></meta>
      <meta
        name='twitter:title'
        key={'twitter:title'}
        content={metaTitle}
      ></meta>
      <meta
        name='twitter:card'
        content='summary_large_image'
        key={'twitter:card'}
      ></meta>
      <meta
        name='twitter:site'
        content={`@${twitterSite}`}
        key={'twitter:site'}
      ></meta>
      <meta
        name='twitter:creator'
        content={twitterSite}
        key={'twitter:creator'}
      ></meta>
      <meta name='twitter:image' content={gimage} key={'twitter:image'}></meta>
      <meta property='og:type' key='og:type' content='website'></meta>
      <meta
        property='og:url'
        content={routeParseAbsolute(
          pathName, // keep relative
          lang
        )}
        key={'og:url'}
      ></meta>
      <meta
        property='og:description'
        content={metaDescription}
        key={'og:description'}
      ></meta>
      {metaTitle ? (
        <meta property='og:title' content={metaTitle} key={'og:title'}></meta>
      ) : null}
      <meta property='og:image' content={gimage} key={'og:image'}></meta>
      <meta
        property='og:image:alt'
        content={metaDescription}
        key='og:image:alt'
      ></meta>
      <meta property='og:site_name' content={companyName}></meta>
      <meta
        content='max-snippet:-1, max-image-preview:large, max-video-preview:-1'
        name='robots'
        key={'robots'}
      ></meta>
      <meta name='mobile-web-app-capable' content='yes'></meta>
      <link rel='manifest' href='/manifest.json' key={'manifest'} />

      <meta
        name='format-detection'
        content='telephone=no'
        key={'format-detection'}
      ></meta>
      <meta
        name='apple-mobile-web-app-capable'
        content='yes'
        key={'apple-mobile-web-app-capable'}
      ></meta>
      <link
        rel='apple-touch-icon'
        href='/img/apple-touch-icon.png'
        key={'apple-touch-icon'}
      />
      <link
        rel='icon'
        type='image/x-icon'
        href='/img/favicon.ico'
        key={'image/x-icon'}
      />
      <script
        dangerouslySetInnerHTML={{ __html: inlineTheme }}
        key={'inline-theme'}
      />
      <noscript data-pagefind-meta={'url[href]'} key='pagefind-meta' />
      <meta name='color-scheme' content='dark light' key='color-scheme' />
      {pageFind ? (
        <>
          <link
            href='/css/_pagefind.css'
            rel='stylesheet'
            key={'_pagefindcss'}
          />
          <link
            href='/_pagefind/pagefind-ui.css'
            rel='stylesheet'
            key={'_pagefinduicss'}
          />
          <script
            src='/_pagefind/pagefind-ui.js'
            type='text/javascript'
            key={'_pagefinduijs'}
            defer
          />
        </>
      ) : null}
    </Head>
  )
}
