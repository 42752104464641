import { useCallback, useState, useTransition } from 'react'
import { AppManager, UserManager } from '@app/managers'
import { searchQuery } from '@app/utils'
import type { Website } from '@app/types'
import { ModalParams } from '@app/components/providers/defaults/rest-website'
import { scanPDF, scanWebsite } from './website-scan'

interface Scan {
  data?: { data?: Website; report?: any } // TODO: reduce dup data
  loading: boolean
}

// TODO: USE OpenAPI CALL or REDUCER
export function useSearchRest() {
  const [_, startTransition] = useTransition()
  const [search, setQuery] = useState<string>('')
  const [html, setHtml] = useState<string>('')
  const [selectedFile, setSelectedFile] = useState<any>(null)

  // MODAL STATE
  const [state, setScan] = useState<Scan>({
    loading: false,
    data: undefined,
  })

  const { data: scanState, loading } = state ?? {}

  const setSearch = useCallback(
    (event: any) => setQuery(event?.target?.value),
    [setQuery]
  )

  const scanPage = useCallback(
    async (
      options: ModalParams,
      markup?: boolean,
      mutationCallback?: (a: any) => Promise<any>
    ) => {
      const { html, query, standard, lang, runners } = options ?? {}
      const q = query || search

      startTransition(() => {
        setScan({ loading: true })
      })

      const [querySearch, _] = markup
        ? ['', false]
        : searchQuery(q, q && !q.startsWith('https'))

      const authed =
        typeof window === 'undefined'
          ? false
          : window.location.pathname === '/dashboard' && !!UserManager.token

      let response: {
        success?: boolean
        code?: number
        message?: string
        data?: any
      }

      if (mutationCallback) {
        response = await mutationCallback({
          querySearch,
          authed,
          markup: html, // direct markup
          standard,
          lang,
          runners,
        })
      } else {
        // if html bool found run body
        response = await scanWebsite(
          querySearch,
          authed,
          markup ? html : undefined, // direct markup
          standard,
          lang,
          runners
        )

        if (
          response &&
          response.code &&
          ([300, 400].includes(response.code) || !response.success)
        ) {
          AppManager.toggleSnack(true, response.message)
        }
      }

      startTransition(() => {
        setScan({
          loading: false,
          data: response,
        })
      })
    },
    [setScan, search]
  )

  const closeModal = useCallback(() => {
    setScan({ loading: false, data: undefined })
  }, [setScan])

  // move validation
  const toggleModal = useCallback(
    async ({ html, standard, query, lang, runners }: ModalParams) => {
      return await scanPage({ html, query, standard, lang, runners }, !!html)
    },
    [scanPage]
  )

  const onFileChange = (event: any) => {
    setSelectedFile(event.target.files[0])
  }

  const onPdfScanEvent = useCallback(
    async (e: any) => {
      if (e && e.preventDefault) {
        e.preventDefault()
      }

      if (!selectedFile) {
        return
      }

      startTransition(() => {
        setScan({ loading: true })
      })

      // Create an object of formData
      const formData = new FormData()

      // Update the formData object
      formData.append('file', selectedFile, selectedFile.name)

      const pdfData = await scanPDF(formData)

      startTransition(() => {
        setScan({
          loading: false,
          data: pdfData,
        })
      })
    },
    [selectedFile]
  )

  return {
    search,
    html,
    setSearch,
    scanPage,
    setHtml,
    loading,
    data: scanState ? scanState?.data : undefined,
    closeModal,
    toggleModal,
    onFileChange,
    onPdfScanEvent,
    selectedFile,
  }
}
