import { Fragment, ReactElement } from 'react'
import { NavBarTitle } from '../navigation'
import { NavBar } from '../navigation/navbar'
import { Footer } from '../footer'
import { MarketingAlternatives } from '@app/components/stateless/marketing/alternative'
import { classNames } from '@app/utils/classes'
import { containerStyle } from '@app/styles/container'
import { companyName } from '@app/configs'

type MarketingDrawerProps = {
  children?: ReactElement | ReactElement[]
  initClosed?: boolean
  navPosition?: 'fixed' | 'absolute' | 'relative' | 'static'
  footerSpacing?: boolean
  emptyFooter?: boolean
  emptyNav?: boolean
  loading?: boolean
  headerType?: string
  marketingLinks?: boolean
  authenticated?: boolean
  title?: string
  marketing?: boolean // switch off marketing views
  backButton?: boolean
  authPage?: boolean
  podcast?: boolean
}

// main marketing pages drawer
export const MarketingDrawer = ({
  children,
  initClosed,
  navPosition,
  footerSpacing,
  emptyFooter,
  emptyNav,
  loading,
  headerType,
  marketingLinks = true,
  authenticated,
  marketing = true,
  backButton,
  authPage,
}: MarketingDrawerProps & { banner?: boolean }) => {
  return (
    <Fragment>
      {emptyNav ? null : (
        <NavBar
          marketing={marketing}
          position={navPosition}
          authenticated={authenticated}
          loading={loading}
          marketingLinks={marketingLinks}
          backButton={backButton}
          notitle={backButton}
          authPage={authPage}
        >
          <NavBarTitle
            href={backButton ? '/dashboard' : '/'}
            title={companyName}
            marketing={marketing}
            hideCompanyName={backButton}
          />
        </NavBar>
      )}
      <main
        data-pagefind-body
        className={classNames(
          initClosed ? '' : 'overflow-hidden',
          footerSpacing ? 'pb-[20vh]' : ''
        )}
        id='main-content'
      >
        {initClosed ? (
          children
        ) : (
          <>
            <div className={containerStyle}>{children}</div>
            {emptyNav || authPage ? null : (
              <MarketingAlternatives
                headerType={headerType}
                disabled={emptyNav}
              />
            )}
          </>
        )}
      </main>
      {initClosed || emptyFooter ? null : <Footer />}
    </Fragment>
  )
}
