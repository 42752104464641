import atob from 'atob'

const defaultToken = { subject: '', audience: null }

function parseJwt(token?: string): any {
  if (!token) {
    return defaultToken
  }

  const u = token.split('.')

  if (u.length >= 2) {
    const base64Url = u[1]
    const base64 = base64Url && base64Url.replace('-', '+').replace('_', '/')
    const atb = base64 && atob(base64)

    try {
      return JSON.parse(atb)
    } catch (e) {
      console.error(e)
      return defaultToken
    }
  }

  return defaultToken
}

export { parseJwt }
