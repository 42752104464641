import { useMemo } from 'react'
import { useQuery, useMutation, useSubscription } from '@apollo/client'
import { ADD_PAYMENT_SUBSCRIPTION, CANCEL_SUBSCRIPTION } from '@app/mutations'
import { GET_PAYMENTS } from '@app/queries'
import { UserManager } from '@app/managers'
import { ROLE_ADJUSTED_SUBSCRIPTION } from '@app/subscriptions'
import { useAuthContext } from '@app/components/providers/auth'

// get a user payments @param skip queries
export const usePayments = (skip?: boolean) => {
  const { data, loading, refetch } = useQuery(GET_PAYMENTS, {
    variables: {},
    ssr: false,
    skip,
  })

  const [
    addSubscription,
    { data: updateUserData, loading: addPaymentLoading },
  ] = useMutation(ADD_PAYMENT_SUBSCRIPTION)

  const [cancelSubscription, { loading: cancelSubscriptionLoading }] =
    useMutation(CANCEL_SUBSCRIPTION)

  const { onRoleAdjustedEvent } = useAuthContext()

  useSubscription(ROLE_ADJUSTED_SUBSCRIPTION, {
    onData: onRoleAdjustedEvent,
  })

  const user = data?.user
  const newUser = updateUserData?.updateUserData?.user

  // merge user between updates
  const userData = useMemo(() => {
    if (user) {
      return user
    }
    if (user && newUser) {
      return Object.assign({}, user, newUser)
    }
    return null
  }, [user, newUser])

  useMemo(() => {
    if (newUser && newUser?.role) {
      UserManager.setJwt(newUser.jwt)
    }
  }, [newUser])

  return {
    data: userData,
    loading: loading || addPaymentLoading || cancelSubscriptionLoading,
    addSubscription,
    cancelSubscription,
    refetch,
  }
}
