import { Dispatch, Fragment, SetStateAction, useState } from 'react'
import { useTranslation } from '@app/lib/hooks/useTranslation'
// import { Tab } from '@headlessui/react'
import { priceConfig } from '@app/lib/utils'
import { Link } from '@app/components/stateless/typo/link'
import { SectionContainer } from '@app/components/stateless/containers/section-container'
import { PriceCell } from './cells/price-cell'
import { PriceFeat } from './cells/price-feat'
import { Button } from './buttons'
import { PricingInfo } from './payments/pricing-info'
import { PricingNeedMore } from './payments/pricing-needmore'
import { classNames } from '@app/utils/classes'
import { routeParseAbsolute } from '@app/lib/router-handler'
import { Address } from './mailto'
import { Paragraph } from '../stateless/typo/paragraph'
import { blueLink } from '../stateless/typo/link-style'
import { GrLinkNext } from 'react-icons/gr'
import { PriceTotal } from './payments/total'
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@app/@/components/ui/tabs'
import { buildFormatter } from '@app/lib/utils/price-config'
import { twMerge } from 'tailwind-merge'

// active hover styles for switch btns TODO: move to base
const getStyles = (yearly: boolean) =>
  yearly
    ? 'border-gray-700 dark:border-indigo-400 dark:text-indigo-400 dark:hover:text-indigo-300 dark:hover:border-indigo-300'
    : 'text-blue-600 border-blue-600 dark:text-blue-400 dark:border-blue-400 dark:hover:text-blue-200 dark:hover:border-blue-200'

// pricing page component
export const MainButton = ({
  title,
  navigate,
  yearly,
  selectedIndex,
  credits,
}: {
  title: string
  yearly?: boolean
  navigate: boolean
  selectedIndex?: number
  credits?: number
}) => {
  const { t, lang } = useTranslation('common')

  if (navigate) {
    return (
      <div className='pb-3 justify-center flex'>
        <Link
          className={classNames(
            `w-full tracking-wide text-lg text-white bg-black dark:bg-white dark:text-black px-8 py-2 min-w-1/3 rounded-3xl text-center md:px-8`,
            'hover:ring hover:no-underline hover:bg-blue-600 dark:hover:bg-blue-800 dark:hover:text-white'
          )}
          href={routeParseAbsolute(
            `/register${
              title
                ? credits
                  ? `?credits=${credits}`
                  : `?plan=${!selectedIndex ? 'C1' : title.toLowerCase()}${
                      yearly ? '&yearly=true' : ''
                    }`
                : ''
            }`,
            lang
          )}
        >
          {t('get-started')}
        </Link>
      </div>
    )
  }

  return null
}

interface PriceProps {
  onClick?(newState: string): void
  yearly?: boolean // render the yearly price
  setYearly?: Dispatch<SetStateAction<boolean>> // toggle yearly
  pricingPage?: boolean // pricing page
  initialIndex?: number // selected plan index
  highPlan?: boolean // high plans
  hobbyPlan?: boolean
  currentPlan?: string // users current plan
  selectIndex?: number
  __convertor?: number
}

const indexToValue = (index: number) => {
  if (!index) {
    return 'hobby'
  }
  if (index === 1) {
    return 'pro'
  }
  if (index === 2) {
    return 'enterprise'
  }
}

// todo: update this code to make safe
export const PaymentPlans = ({
  onClick,
  yearly: year,
  setYearly: setYear,
  pricingPage,
  initialIndex,
  highPlan = false,
  currentPlan,
  selectIndex,
  __convertor,
}: PriceProps) => {
  const { t, lang } = useTranslation('price')
  const [yearly, onSetYear] = useState<boolean>(!!year)

  const [selectedPlan, onSelectPlan] = useState<number>(initialIndex ?? 2)
  const [selectHighPlans, onSelectHigh] = useState<boolean>(highPlan)
  const [selectedIndex, setSelectedIndex] = useState<number>(selectIndex ?? 1)

  const setYearly = (cb: (x: boolean) => boolean) => {
    typeof setYear === 'function' && setYear(cb)
    // inner state
    onSetYear(cb)
  }

  const onSetYearlyEvent = () => setYearly((x: boolean) => !x)

  const onPlanClick = (title: string, index: number) => {
    // set the active payment plan
    typeof onClick === 'function' && onClick(title)
    onSelectPlan(index)
  }

  const onTogglePlans = () => {
    onSelectHigh((x: boolean) => !x)

    // if click event found
    if (onClick && typeof onClick === 'function') {
      const p = !selectHighPlans ? priceConfig.hPlans : priceConfig.lPlans
      const sP = selectedPlan >= 0 && p[selectedPlan]

      const s = sP && sP.title

      s && onClick(s)
      return
    }
  }

  const plans = selectHighPlans ? priceConfig.hPlans : priceConfig.lPlans
  const selectedItem = selectedPlan >= 0 && plans[selectedPlan ?? 0]
  const selected = selectedItem && selectedItem?.title

  const btnT = t(yearly ? 'm-free-yearly' : 'm-free')

  const onTabChange = (v: string) => {
    if (typeof onClick === 'function') {
      if (!v || v === 'hobby') {
        onClick('C1')
      } else {
        onClick(
          v === 'enterprise' ? 'enterprise' : selected || currentPlan || 'L1'
        )
      }
    }

    setSelectedIndex(v === 'pro' ? 1 : v === 'enterprise' ? 2 : 0)
  }

  const getStartedStyles = classNames(
    `border dark:border-blue-600 relative px-2 rounded-3xl py-2 text-sm whitespace-nowrap min-w-[18.3rem] px-8 flex place-items-center place-content-center`,
    'focus:outline-none focus:ring-1 focus:ring-gray-500',
    'hover:border-blue-400 hover:text-blue-400 max-w-[90vw] truncate',
    getStyles(yearly)
  )

  const selectedValue = indexToValue(selectedIndex)

  // price for the hobby plan. We may need an env variable for this.
  const baseHobbyPrice = yearly ? 50 * 100 : 5 * 100

  return (
    <>
      <Tabs value={selectedValue} onValueChange={onTabChange}>
        <TabsList className='flex space-x-1 bg-gray-200 dark:bg-gray-800'>
          {['hobby', 'pro', 'enterprise'].map((category) => (
            <TabsTrigger
              key={`category-${category}`}
              value={category}
              className={classNames(
                'w-full rounded-lg text-lg font-medium leading-5 capitalize',
                'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                selectedValue === category
                  ? 'bg-black text-white dark:bg-white shadow dark:text-black'
                  : 'text-[#666] dark:bg-gray-800 dark:text-gray-400 hover:bg-blue-600 dark:hover:bg-white/[0.12] hover:text-white'
              )}
            >
              {t(category)}
            </TabsTrigger>
          ))}
        </TabsList>
        <TabsContent value='hobby' className={'space-y-4 py-4'}>
          <div className='flex flex-1 gap-4 flex-wrap-reverse'>
            <div className='flex flex-1 flex-col relative text-center place-items-center place-content-center gap-y-3 p-4 border-2 border-t-4 rounded bg-blue-700 border-blue-600'>
              <div>
                {currentPlan === 'C1' ? (
                  <div className='text-xs font-semibold absolute -top-2.5 px-2 rounded-xl border bg-blue-600 text-white border-blue-500'>
                    {t('active-p')}
                  </div>
                ) : null}
                <Paragraph className='text-4xl font-semibold text-white'>
                  <PriceTotal
                    price={
                      __convertor
                        ? __convertor * baseHobbyPrice
                        : baseHobbyPrice
                    }
                    noui
                    lang={__convertor && __convertor !== 1 ? lang : undefined}
                  />
                  /{yearly ? 'y' : 'm'}
                </Paragraph>
              </div>
              <div>
                <Paragraph className='text-lg text-white'>
                  {t('hobby-d')}
                </Paragraph>
                <Paragraph className='text-base py-2 text-white'>
                  {t('included', {
                    usage: buildFormatter(lang, true).format(1000),
                  })}
                </Paragraph>
              </div>
            </div>
            <PriceFeat
              yearly={yearly}
              title={t('hobby-includes')}
              details={t('pricing-hobby', null, { returnObjects: true })}
              hobby
            >
              {pricingPage && selected ? (
                <div className='px-4 w-full'>
                  <MainButton
                    title={selected}
                    navigate={pricingPage}
                    yearly={yearly}
                    selectedIndex={selectedIndex}
                  />
                </div>
              ) : null}
            </PriceFeat>
          </div>
          <Button
            type='button'
            onClick={onSetYearlyEvent}
            border={false}
            className={getStartedStyles}
          >
            {btnT}
          </Button>
        </TabsContent>
        <TabsContent value='pro' className='py-4'>
          <div className='flex flex-1 gap-3 flex-wrap-reverse'>
            <ul className={`grid nowrap gap-3 list-none flex-1`}>
              {plans.map((planProps, index) => {
                const title = planProps.title
                const onPriceClick = () => onPlanClick(title, index)

                const selectedItem = index === selectedPlan
                const activePlan = currentPlan === title

                return (
                  <Fragment key={`price-${title}`}>
                    <li className='w-full h-full'>
                      <PriceCell
                        onClick={onPriceClick}
                        yearly={yearly}
                        selected={selectedItem}
                        selectHighPlans={selectHighPlans}
                        activePlan={activePlan}
                        index={index}
                        __convertor={__convertor}
                        {...planProps}
                      />
                    </li>
                  </Fragment>
                )
              })}
              <li className='w-full'>
                <PricingInfo
                  onTogglePlans={onTogglePlans}
                  selectHighPlans={selectHighPlans}
                >
                  <Button
                    type='button'
                    onClick={onSetYearlyEvent}
                    border={false}
                    className={getStartedStyles}
                  >
                    {btnT}
                  </Button>
                </PricingInfo>
              </li>
            </ul>
            <PriceFeat
              yearly={yearly}
              title={t('plans-include')}
              details={t('pricing', null, { returnObjects: true })}
            >
              {pricingPage && selected ? (
                <div className='px-4 w-full'>
                  <MainButton
                    title={selected}
                    navigate={pricingPage}
                    yearly={yearly}
                    selectedIndex={selectedIndex}
                  />
                </div>
              ) : null}
            </PriceFeat>
          </div>
        </TabsContent>
        <TabsContent value='enterprise' className='py-4'>
          <div className='block place-items-center md:flex md:flex-col'>
            <PriceFeat
              yearly={yearly}
              title={t('enterprise-includes')}
              details={t('pricing-enterprise', null, { returnObjects: true })}
              enterprise
            >
              {pricingPage && selected ? (
                <div className='px-4 w-full'>
                  <MainButton
                    title={selected}
                    navigate={pricingPage}
                    yearly={yearly}
                    selectedIndex={selectedIndex}
                  />
                </div>
              ) : null}
            </PriceFeat>

            {process.env.NEXT_PUBLIC_SALES_EMAIL ? (
              <div className='py-6'>
                <Address
                  email={process.env.NEXT_PUBLIC_SALES_EMAIL}
                  className={twMerge(
                    blueLink,
                    'border-blue-600 capitalize no-underline border-2 p-3 rounded hover:bg-blue-100 dark:hover:bg-blue-900 hover:text-blue-800 flex place-content-between space-x-4 place-items-center'
                  )}
                  key={'c2'}
                >
                  <div>{t('get-in-touch')}</div>
                  <GrLinkNext className='grIcon' />
                </Address>
              </div>
            ) : null}
          </div>
        </TabsContent>
      </Tabs>
      <PricingNeedMore />
    </>
  )
}

export const Price = (props: any) => {
  return (
    <SectionContainer>
      <PaymentPlans {...props} />
    </SectionContainer>
  )
}
