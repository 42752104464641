import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { GET_WEBSITE_STATS } from '@app/queries'
import { SortMap } from './websites'

// get a single website data [NOT USED]
const useWebsite = (
  url: string,
  query: boolean = true,
  sortDirections?: SortMap
) => {
  const variables = useMemo(() => {
    // check if the url is in the sort directions table
    if (sortDirections?.has(url)) {
      const sortMap = sortDirections.get(url)
      return { url, sort: sortMap?.sortDirection, sortType: sortMap?.sortKey }
    }
    return { url }
  }, [sortDirections, url])

  const { data, loading, refetch, error } = useQuery(GET_WEBSITE_STATS, {
    variables: variables,
    skip: !query,
  })

  return {
    data,
    loading,
    refetch,
    error,
  }
}

export { useWebsite }
