import React, { memo, FC, useMemo, useCallback } from 'react'
import { FixedSizeList as List } from 'react-window'
import { IssueFeedCell } from '../general/cells'
import { getListHeight } from './utils'
import { PageIssue } from '@app/types'

interface RowProps {
  index: number
  style?: React.CSSProperties
}

// base feed props
interface LazyIssuesProps {
  fullScreen?: boolean
  issues: PageIssue[]
  pageMatchers?: Set<string>
  paging?: boolean
}

// List of issues rendered.
const LazyIssuesComponent: FC<LazyIssuesProps> = ({
  fullScreen,
  pageMatchers,
  paging,
  issues,
}) => {
  // allow feed adjustment at render for manual triggering
  const issueCount = issues ? issues.length : 0

  const { size, height } = useMemo(
    () => getListHeight({ fullScreen, issueCount, paging }),
    [fullScreen, issueCount, paging]
  )

  const Row = useCallback(
    ({ index, style }: RowProps) => {
      const item = issues[index]

      return (
        <IssueFeedCell
          item={item}
          style={style}
          completed={
            pageMatchers &&
            !!pageMatchers.size &&
            !pageMatchers.has(item.code + item.selector + item.context)
          }
        />
      )
    },
    [issues, pageMatchers]
  )

  if (fullScreen) {
    return (
      <div className={`bg-[rgba(172,182,192,0.06)] w-full h-full`}>
        <List
          height={height}
          itemCount={issueCount}
          itemSize={size}
          width={'100%'}
        >
          {Row}
        </List>
      </div>
    )
  }

  return (
    <div className={'overflow-x-hidden'}>
      <List
        height={height}
        itemCount={issueCount}
        itemSize={size}
        width={'100%'}
      >
        {Row}
      </List>
    </div>
  )
}

export const LazyIssues = memo(LazyIssuesComponent)
