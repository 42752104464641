import type { Rule } from 'kayle/build/rules'

export const importRules = async (
  locale:
    | 'ar'
    | 'fr'
    | 'es'
    | 'it'
    | 'ja'
    | 'nl'
    | 'pl'
    | 'ko'
    | 'zh_CN'
    | 'zh_TW'
    | 'da'
    | 'de'
    | 'eu'
    | 'he'
    | 'no_NB'
    | 'pt_BR'
    | 'en',
  runner: 'htmlcs' | 'axe'
): Promise<Rule[]> => {
  const rules = await import(
    `kayle/build/rules/${locale.replace('-', '_')}/${
      runner === 'htmlcs' ? 'htmlcs' : 'axe'
    }-rules`
  )

  return rules.axeRules || rules.htmlcsRules
}
