import {
  SyntheticEvent,
  memo,
  forwardRef,
  ForwardedRef,
  useCallback,
  useMemo,
} from 'react'
import { useTheme } from '@app/components/themes/theme'
import { classNames } from '@app/utils/classes'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { btnStyles, getActiveStyles } from '../cells/menu/styles'
import { GrMoon, GrPersonalComputer, GrSun } from 'react-icons/gr'

const ThemeSelectComponent = (
  props: {
    active?: boolean
    close?: () => void
  },
  ref: ForwardedRef<HTMLDivElement>
) => {
  const { t } = useTranslation('menu')
  const { theme, setTheme } = useTheme()
  const { active, close } = props

  const onThemeChange = useCallback(
    (e?: SyntheticEvent<HTMLSelectElement>) => {
      if (e && typeof e.preventDefault === 'function') {
        e?.preventDefault()
      }

      const themeS = e?.currentTarget.value ?? 'system'

      if (
        themeS &&
        typeof themeS === 'string' &&
        ['light', 'dark', 'system'].includes(themeS)
      ) {
        setTheme(themeS)
        close && close()
      }
    },
    [close, setTheme]
  )

  const ThemeIcon = useMemo(
    () =>
      theme === 'system'
        ? GrPersonalComputer
        : theme === 'light'
          ? GrSun
          : GrMoon,
    [theme]
  )

  return (
    <div
      ref={ref}
      className={
        close
          ? classNames(
              btnStyles,
              getActiveStyles(!!active),
              'gap-x-2 flex text-sm place-items-center place-content-between border-t dark:border-gray-800'
            )
          : undefined
      }
    >
      <label htmlFor='theme-select' className={close ? undefined : 'sr-only'}>
        {t('appearance')}
      </label>

      <div className='relative flex place-items-center hover:no-underline'>
        <span className='left-1 absolute pointer-events-none inline-flex'>
          <ThemeIcon className='grIcon' />
        </span>
        <select
          id='theme-select'
          className='text-xs py-0.5 px-6 appearance-none capitalize bg-transparent border rounded border-gray-300 dark:border-gray-700 hover:border-gray-800 dark:hover:border-gray-300'
          onChange={onThemeChange}
          value={theme}
        >
          <option value='system'>{t('auto')}</option>
          <option value='dark'>{t('dark')}</option>
          <option value='light'>{t('light')}</option>
        </select>
        <span className='right-1 absolute pointer-events-none inline-flex'>
          <svg
            fill='none'
            height='24'
            shapeRendering='geometricPrecision'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
            viewBox='0 0 24 24'
            width='24'
            className='w-[16px] h-[16px]'
          >
            <path d='M17 8.517L12 3 7 8.517M7 15.48l5 5.517 5-5.517'></path>
          </svg>
        </span>
      </div>
    </div>
  )
}

export const ThemeSelect = memo(forwardRef(ThemeSelectComponent))
