import { companyName } from '@app/configs'
import { FILTERED_MARKETING } from '@app/configs/app-config'

export const FixedCopyRight = () => {
  return (
    <div className={'text-xs'}>{`© ${new Date().getFullYear()} ${companyName}${
      !FILTERED_MARKETING ? `, LLC` : ''
    }`}</div>
  )
}
