import { useMemo } from 'react'
import { multiplier } from './get-usage-limits'

// todo: move to translation array
const feats = new Array(10).fill(0)

const hobbyPlan = [
  {
    title: 'C1',
    cost: 5,
    pageCount: multiplier(1000) / 3,
  },
]

const lPlans = [
  {
    title: 'L1',
    cost: 14,
    pageCount: multiplier(10000) / 3,
  },
  {
    title: 'L2',
    cost: 24,
    pageCount: multiplier(20000) / 3,
  },
  {
    title: 'L3',
    cost: 44,
    pageCount: multiplier(42000) / 3,
  },
  {
    title: 'L4',
    cost: 54,
    pageCount: multiplier(62000) / 3,
  },
  {
    title: 'L5',
    cost: 84,
    pageCount: multiplier(124000) / 3,
  },
]

const hPlans = [
  {
    title: 'H1',
    cost: 94,
    pageCount: multiplier(155000) / 3,
  },
  {
    title: 'H2',
    cost: 134,
    pageCount: multiplier(310000) / 3,
  },
  {
    title: 'H3',
    cost: 194,
    pageCount: multiplier(620000) / 3,
  },
  {
    title: 'H4',
    cost: 334,
    pageCount: multiplier(1085000) / 3,
  },
  {
    title: 'H5',
    cost: 544,
    pageCount: multiplier(1860000) / 3,
  },
]

export const priceConfig = {
  lPlans,
  hPlans,
  hobbyPlan,
  feats,
}

export const plans = [...priceConfig.lPlans, ...priceConfig.hPlans]

// Round the pricing plan to nearest place
export const roundPricePlan = (price: number) => {
  // round to 100,000,000
  if (price > 1000000) {
    return Math.round(price / 1000000) * 100000
  }
  // round to 100,000
  return Math.round(price / 100000) * 10000
}

type IntlPatch =
  | (Intl.NumberFormatOptions & { numberingSystem?: string })
  | undefined

export const buildFormatter = (
  lang?: string,
  noconf?: boolean,
  formatOptions?: IntlPatch
) => {
  const pConfig: IntlPatch = noconf
    ? undefined
    : {
        ...(formatOptions || {}),
        style: 'currency',
        currency: 'USD',
      }

  try {
    return new Intl.NumberFormat(lang === 'ar' ? 'en' : lang, pConfig)
  } catch (e) {
    return new Intl.NumberFormat('en', pConfig)
  }
}

export const formatMoney = (
  lang?: string,
  total?: number,
  formatOptions?: Intl.NumberFormatOptions
) => buildFormatter(lang, false, formatOptions).format(total ?? 0)

type FormatProps = {
  lang?: string
  cost: number
  yearly?: boolean
  formatter?: Intl.NumberFormat
}

export const getFormattedCost = ({ lang, cost, yearly }: FormatProps) => {
  const total = yearly ? cost * 10 : cost

  return {
    formatted: formatMoney(lang, total),
    total: total,
  }
}

// hook to format money and memo
export const useFormatCost = ({
  lang,
  cost,
  yearly,
  formatter,
}: FormatProps) => {
  const total = yearly ? cost * 10 : cost

  const formater = useMemo(
    () => formatter || buildFormatter(lang),
    [formatter, lang]
  )
  const formatted = useMemo(
    () => formater.format(total ?? 0),
    [formater, total]
  )

  return {
    formatted,
    total,
    formater,
  }
}
