import { Fragment } from 'react'
import { Skeleton } from './skeleton'
import { classNames } from '@app/utils/classes'

export const ListItemSkeleton = ({
  subTitle,
  smallCircle,
  avatar = true,
}: {
  subTitle?: string
  smallCircle?: boolean
  avatar?: boolean
}) => {
  return (
    <li
      className={classNames(
        'flex gap-x-2 px-2',
        subTitle ? 'h-[72px]' : 'h-[49px]'
      )}
    >
      {avatar ? (
        <div>
          <Skeleton
            className={smallCircle ? 'w-[20px] h-[20px]' : 'w-[40px] h-[40px]'}
          />
        </div>
      ) : null}

      <div className='flex-1'>
        <Skeleton className='h-[9.5px] w-[30%]' />
        {!subTitle ? (
          <div />
        ) : (
          <Fragment>
            <Skeleton className='mt-2 h-[9px] w-[20%]' />
            {!avatar ? <Skeleton className='mt-2 h-[9px] w-[20%]' /> : null}
          </Fragment>
        )}
      </div>
    </li>
  )
}

export function ListItemIssuesSkeleton({ subTitle }: any) {
  return (
    <li className={classNames('px-2', subTitle ? 'h-[109px]' : 'h-[72px]')}>
      <Skeleton className='h-[20px] w-[35%]' />
      {!subTitle ? (
        <div />
      ) : (
        <Fragment>
          <div className='h-2' />
          <Skeleton className='h-[26px] w-[75%]' />
          <div className='h-4' />
          <Skeleton className='h-[29px] w-full' />
        </Fragment>
      )}
    </li>
  )
}
