import { URL } from 'url'

const validTransport = (str: string) => {
  if (str.indexOf('http://') === 0 || str.indexOf('https://') === 0) {
    return true
  }
  return false
}

export function getHostName(url: string) {
  if (!url) {
    return ''
  }
  let targetUrl = url

  if (!validTransport(url)) {
    targetUrl = `https://${url}`
  }

  try {
    const { hostname } = new URL(targetUrl)

    return hostname
  } catch (e) {
    console.error(e)
    return ''
  }
}
