import { memo } from 'react'
import type { Issue } from '@app/types'
import { WithSyntax } from '@app/components/adhoc/with-syntax'
import { classNames } from '@app/utils/classes'
import { Recurrence } from './cells/reccurence'
import { IssueColor } from './cells/issue-color'
import { CodeLink } from './cells/code-link'
import { Selector } from './cells/selector'
import { IssueMessage } from './cells/message'
import Zoom from 'react-medium-image-zoom'
import dynamic from 'next/dynamic'

const ZoomStyle = dynamic(
  () => import('./zoom-style').then((mod) => mod.ZoomStyle),
  {
    loading: () => null,
  }
)

type CellIssue = Partial<Issue> & {
  hidden?: boolean //the entire section is hidden
  count?: number // if grouping display counter
}

// Display issue UI without events
export const FeedIssueCardComponent = ({
  message,
  code,
  context,
  type: issueType,
  selector,
  recurrence,
  runnerExtras,
  clipBase64,
  clip,
}: // runnerExtra
CellIssue) => {
  return (
    <div className='px-3 py-2 flex gap-y-0.5 flex-col place-content-between h-[inherit] overflow-hidden'>
      <div>
        <div className='flex gap-x-2 items-center rtl:flex-row-reverse'>
          <IssueColor issueType={issueType} />
          <Selector selector={selector} />
          <Recurrence recurrence={recurrence} />
        </div>
        <CodeLink code={code} helpUrl={runnerExtras?.helpUrl} />
        {clipBase64 ? (
          <>
            <ZoomStyle />
            <div className={'max-h-[3rem] overflow-clip'}>
              <Zoom>
                <img
                  src={`data:image/png;base64,${clipBase64}`}
                  width={clip?.width ?? 300}
                  height={clip?.height ?? 50}
                  alt={context}
                />
              </Zoom>
            </div>
          </>
        ) : null}
      </div>
      <IssueMessage message={message} clip={!!clipBase64} />
      {context ? <WithSyntax>{context.replaceAll('\n', '')}</WithSyntax> : null}
    </div>
  )
}

export const FeedIssueCard = memo(FeedIssueCardComponent)

// list item wrapper of issue cell
const FeedIssueComponent = ({
  message,
  code,
  context,
  type: issueType = 'warning',
  selector,
  recurrence,
  hidden,
  runnerExtras,
  clipBase64,
  clip,
}: CellIssue) => (
  <li className={classNames(!hidden ? 'visible' : 'hidden', `h-[inherit]`)}>
    <FeedIssueCard
      selector={selector}
      type={issueType}
      context={context}
      code={code}
      message={message}
      recurrence={recurrence}
      runnerExtras={runnerExtras}
      clipBase64={clipBase64}
      clip={clip}
    />
  </li>
)

// Generic issue UI display to re-use across application
export const FeedIssue = memo(FeedIssueComponent)
