import React from 'react'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { getSelectStyle } from './select-style'

export enum Theme {
  'system',
  'light',
  'dark',
}

// todo: remove type and reverse enum map instead
export type ThemeKeys = keyof typeof Theme | string

const themes = Object.values(Theme).filter((value) => typeof value === 'string')

export interface InputProps {
  onStandardChange(
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ): void
  theme?: ThemeKeys
  spacing?: boolean
  className?: string
  disabled?: boolean
  label?: string
  borderLess?: boolean
}

export const ThemeInput = ({
  onStandardChange,
  theme,
  spacing,
  className,
  disabled,
  label,
  borderLess,
}: InputProps) => {
  const { t } = useTranslation('dashboard-theme')
  const l = t('theme')
  const id = label ?? 'ext-select-outlined'

  return (
    <div>
      <label htmlFor={id} className='sr-only'>
        {l}
      </label>
      <select
        id={id}
        value={theme}
        onChange={onStandardChange}
        className={getSelectStyle({
          disabled,
          spacing,
          className,
          borderLess,
        })}
        disabled={disabled}
      >
        <optgroup label={l}>
          {themes.map((value: any) => (
            <option value={value} key={value} className={'text-sm'}>
              {`${t(value)} ${t('theme')}`}
            </option>
          ))}
        </optgroup>
      </select>
    </div>
  )
}
