import { computed, observable, action } from 'mobx'
import { create, persist } from 'mobx-persist'
import { isSameDay } from 'date-fns'
import { parseJwt } from '@app/lib/auth'
import { useStaticRendering as enableMobxStaticRendering } from 'mobx-react-lite'

enableMobxStaticRendering(typeof window === 'undefined')

const USER_DEFAULTS = {
  id: undefined,
  firstName: undefined,
  lastName: undefined,
  email: undefined,
  jwt: undefined,
  alertsEnabled: undefined,
  analyticsDisabled: true,
}

interface User {
  id?: number
  firstName?: string
  lastName?: string
  email?: string
  jwt?: string
  alertsEnabled?: boolean
  pdfEnabled?: boolean
  analyticsDisabled?: boolean
}

class UserManager {
  @persist('object')
  @observable
  user: User

  constructor() {
    this.user = USER_DEFAULTS
    this.hydrate()
  }

  @action
  hydrate = async () => {
    if (typeof window === 'undefined') {
      return
    }

    const pour = create({
      storage: window?.localStorage,
      jsonify: true,
    })

    try {
      await pour('user', this)
    } catch (e) {
      console.error(e)
    }
  }

  @action setUser = (user: User) => {
    this.user = user
  }

  // get the authenication token.
  @computed get token() {
    return this?.user?.jwt || ''
  }

  @computed get jwtParsed() {
    return this.token ? parseJwt(this.token) : null
  }

  // todo: faster parsing free account
  @computed get freeAccount() {
    return this.jwtParsed?.audience === 0
  }

  @computed get firstDay() {
    return isSameDay(this.jwtParsed?.iat, Date.now())
  }

  @action clearUser = () => {
    this.user = USER_DEFAULTS
  }

  @action setJwt = (jwt: string) => {
    if (this.user) {
      this.user.jwt = jwt
    }
  }

  @action setAlertsEnabled = (enabled: boolean) => {
    if (this.user) {
      this.user.alertsEnabled = enabled
    }
  }

  @action setPdfEnabled = (enabled: boolean) => {
    if (this.user) {
      this.user.pdfEnabled = enabled
    }
  }

  @action setAnalyticsVisibility = (enabled: boolean) => {
    if (this.user) {
      this.user.analyticsDisabled = enabled
    }
  }
}

const manager = new UserManager()

export { manager as UserManager }
