import { memo, useMemo } from 'react'
import { Link } from '@app/components/stateless/typo/link'
import { GrHome } from 'react-icons/gr'
import { Translate } from 'next-translate'
import { DOMAIN_NAME } from '@app/configs'

const determineRoot = (path: string, url: string) => {
  if (path === '/' && !url.endsWith('/')) {
    return true
  }
  return false
}

// the title heading of the issue feed inner websites
const IssueTitleComponent = ({
  pageUrl,
  hideSync,
  demo,
  t,
}: {
  pageUrl: string
  hideSync?: boolean
  demo?: boolean
  t: Translate
}) => {
  const [{ pathname, search }, encodedUrl] = useMemo(() => {
    let props = { pathname: '', search: '' }

    if (pageUrl) {
      try {
        props = new URL(pageUrl)
      } catch (e) {
        console.error(e)
      }
    }

    return [props, pageUrl ? encodeURIComponent(pageUrl) : '']
  }, [pageUrl])

  // Translate view link
  const viewSBoxLink = t('view-sandbox', { url: pageUrl })

  if (hideSync || demo) {
    return (
      <div title={viewSBoxLink} className={'px-0 text-lg'}>
        {determineRoot(pathname, pageUrl) ? (
          <>
            <span className='sr-only'>{viewSBoxLink}</span>
            <GrHome className='grIcon' />
          </>
        ) : (
          pathname
        )}
        {search}
      </div>
    )
  }

  return (
    <Link
      title={viewSBoxLink}
      href={`${DOMAIN_NAME}/website-details?url=${encodedUrl}`}
      className={'px-0 text-lg hover:text-blue-600'}
    >
      {determineRoot(pathname, pageUrl) ? (
        <>
          <span className='sr-only'>{viewSBoxLink}</span>
          <GrHome className='grIcon' />
        </>
      ) : (
        pathname
      )}
      {search}
    </Link>
  )
}

export const IssueTitle = memo(IssueTitleComponent)
