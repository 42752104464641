import React, { ChangeEventHandler, memo, useEffect, useState } from 'react'
import { useWebsiteContext } from '@app/components/providers/website'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { ProxyBoxInput } from '../../inputs/proxy-input'

type ProxyProps = {
  url: string
  proxy?: string
  activeSubscription?: boolean
  demo?: boolean
}

interface ProxyInputProps extends ProxyProps {
  onChangeProxy?: ChangeEventHandler<HTMLInputElement>
  text: string
}

const ProxyBoxContainer = ({
  proxy: defaultProxy,
  url,
  activeSubscription,
  text,
}: ProxyInputProps) => {
  const [proxy, setProxy] = useState<string>(defaultProxy || '')

  const { updateWebsite } = useWebsiteContext()

  const onChangeProxy = (e: React.ChangeEvent<any>) =>
    setProxy(e?.target?.value)

  useEffect(() => {
    if (
      defaultProxy === proxy ||
      proxy === '*************' ||
      !activeSubscription
    ) {
      return
    }
    const debounce = setTimeout(async () => {
      if (proxy !== defaultProxy) {
        try {
          await updateWebsite({
            variables: { url, proxy },
          })
        } catch (e) {
          console.error(e)
        }
      }
    }, 2300)

    return () => clearTimeout(debounce)
  }, [defaultProxy, proxy, url, updateWebsite, activeSubscription])

  return (
    <ProxyBoxInput
      onChangeProxy={onChangeProxy}
      value={proxy}
      activeSubscription={activeSubscription}
      url={url}
      text={text}
    />
  )
}

export const ProxyBoxWrapper = ({
  proxy,
  url,
  activeSubscription,
  translation,
}: ProxyProps & { translation?: string }) => {
  const { t } = useTranslation(translation || 'dashboard')
  const proxyText = t('proxy')

  return (
    <ProxyBoxContainer
      proxy={proxy}
      activeSubscription={activeSubscription}
      url={url}
      text={proxyText}
    />
  )
}

export const ProxyBox = memo(ProxyBoxWrapper)
