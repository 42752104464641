import React, { memo, useCallback, useEffect, useState } from 'react'
import { useWebsiteContext } from '@app/components/providers/website'
import { AgentInput } from '../../inputs/ua-input'

type AgentProps = {
  url: string
  ua?: string
  activeSubscription?: boolean
  translations?: string
}

export const AgentBox = ({
  ua: agent,
  url,
  activeSubscription,
}: AgentProps) => {
  const [ua, setUa] = useState<string>(agent || '')
  const { updateWebsite } = useWebsiteContext()

  const onChangeUA = (e: React.ChangeEvent<any>) => setUa(e?.target?.value)

  const mutateAgent = useCallback(async () => {
    if (agent !== ua) {
      try {
        await updateWebsite({
          variables: { url, ua },
        })
      } catch (e) {
        console.error(e)
      }
    }
  }, [ua, agent, updateWebsite, url])

  useEffect(() => {
    if (ua === agent) {
      return
    }
    const debounce = setTimeout(mutateAgent, 2000)

    return () => {
      clearTimeout(debounce)
      requestAnimationFrame(mutateAgent)
    }
  }, [ua, agent, url, mutateAgent, updateWebsite])

  return (
    <AgentInput
      activeSubscription={activeSubscription}
      onChangeUA={onChangeUA}
      ua={ua}
      url={url}
    />
  )
}

export const UserAgentBoxWrapper = ({
  ua: agent,
  url,
  activeSubscription,
}: AgentProps) => (
  <AgentBox activeSubscription={activeSubscription} ua={agent} url={url} />
)

export const UserAgentBox = memo(UserAgentBoxWrapper)
