export type WebhookMap = {
  website: {
    created: boolean
    updated: boolean
    deleted: boolean
  }
  crawl: {
    started: boolean
    finished: boolean
    shutdown: boolean
    canceled: boolean
  }
  account: {
    exceeded: boolean
  }
  endpoint: string
  all?: boolean // apply to all urls
  signature?: string
}

// UPDATE RECORD ON WEBSITE
export const webhookMap: WebhookMap = {
  website: {
    created: false,
    updated: false,
    deleted: false,
  },
  crawl: {
    started: false,
    finished: false,
    shutdown: false,
    canceled: false,
  },
  account: {
    exceeded: false,
  },
  endpoint: '',
  all: undefined,
}
