import { PropsWithChildren, FC } from 'react'
import { Menu } from '@headlessui/react'
import { classNames } from '@app/utils/classes'
import { GrMenu, GrMoreVertical } from 'react-icons/gr'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { twMerge } from 'tailwind-merge'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@app/@/components/ui/tooltip'

const btnClassName = 'grIcon h-5 w-5'

// Menu to display items in a virtual floating list todo: title More options
export const MenuList: FC<
  PropsWithChildren<{
    more?: boolean
    settings?: boolean
    dashboard?: boolean
    id?: string
  }>
> = ({ children, more, settings, dashboard }) => {
  const { t } = useTranslation('common')

  const moreText = t(more ? 'site' : 'more')

  return (
    <TooltipProvider>
      <Menu as='div' className='relative'>
        <Tooltip>
          <TooltipTrigger asChild>
            <Menu.Button
              type='button'
              className={twMerge(
                `justify-center rounded-full px-2 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75`,
                settings
                  ? 'border border-transparent bg-blue-700 text-white focus-visible:ring-gray-700 focus-visible:bg-blue-500 dark:text-black hover:bg-white dark:hover:bg-black hover:border-blue-700 hover:text-blue-700 dark:hover:text-blue-700'
                  : '',
                dashboard
                  ? 'hover:bg-gray-300 dark:hover:bg-gray-700 focus-visible:bg-gray-300 dark:focus-visible:bg-gray-600'
                  : '',
                more
                  ? 'hover:text-white hover:bg-blue-700 focus-visible:bg-blue-500 dark:focus-visible:bg-blue-600'
                  : ''
              )}
            >
              <>
                <span className='sr-only'>{moreText}</span>
                {more ? (
                  <GrMoreVertical className={btnClassName} />
                ) : (
                  <GrMenu className={btnClassName} />
                )}
              </>
            </Menu.Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>{t('more')}</p>
          </TooltipContent>
        </Tooltip>

        <Menu.Items
          className={classNames(
            more ? 'min-w-[15rem]' : 'min-w-[11rem]',
            'border border-gray-800 dark:border-gray-300 rounded overflow-hidden',
            `absolute ltr:right-0 rtl:left-0 mt-1 ltr:origin-top-right rtl:origin-top-left rounded-md bg-gray-50 dark:bg-black ring-1 z-20 ring-black ring-opacity-5 focus:outline-none`
          )}
        >
          {children}
        </Menu.Items>
      </Menu>
    </TooltipProvider>
  )
}
