import React, { useMemo } from 'react'
import { classNames } from '@app/utils/classes'
import { GrCheckmark } from 'react-icons/gr'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { buildFormatter, roundPricePlan } from '@app/lib/utils/price-config'
import { Chip } from '../chip'
import { PriceTotal } from '../payments/total'

// return styles of highlights when selected
const highLight = (
  highLightStyles: string,
  selected?: boolean,
  d: string = ''
) => (selected ? highLightStyles : d)

type PriceProps = {
  selected?: boolean
  onClick?(): void
  yearly?: boolean
  cost: number
  selectHighPlans?: boolean
  activePlan?: boolean
  pageCount: number
  index?: number
  __convertor?: number
}

export function PriceCell({
  selected,
  onClick,
  yearly,
  cost,
  selectHighPlans,
  activePlan,
  pageCount,
  index,
  __convertor,
}: PriceProps) {
  const { t, lang } = useTranslation('price')

  const formatter = useMemo(() => buildFormatter(lang, true), [lang])
  const pageCountFormatted = formatter.format(roundPricePlan(pageCount) || 0)
  const formatedCost = yearly ? cost * 10 : cost

  return (
    <button
      type={'button'}
      className={classNames(
        'relative w-full h-full rounded flex flex-1 place-items-center justify-between border border-[#2A2A2A]',
        `border-t-[4px] border-2`,
        onClick
          ? 'hover:border-blue-700 hover:opacity-95 active:opacity-90 active:border-[#2A2A2A]'
          : '',
        highLight(
          'bg-blue-700 border-blue-600 text-white',
          selected,
          selectHighPlans ? 'bg-transparent' : `bg-white dark:bg-transparent`
        )
      )}
      onClick={onClick}
    >
      <div
        className={classNames(
          'w-full',
          highLight('text-gray-50', selected, selectHighPlans ? '' : ''),
          'flex text-left w-full flex-col text-black dark:text-white px-3 md:px-4 py-4'
        )}
      >
        <div
          className={
            'flex place-items-center place-content-between gap-x-10 md:gap-x-4'
          }
        >
          <div>
            {index === 2 && !selectHighPlans ? (
              <Chip className='absolute -top-3 left-4 font-semibold border-blue-500 bg-white'>
                {t('price:popular')}
              </Chip>
            ) : null}
            {activePlan ? (
              <div className='text-xs font-semibold absolute -top-2.5 px-2 rounded-xl border bg-blue-600 text-white border-blue-500'>
                {t('active-p')}
              </div>
            ) : null}
            <div className={'flex gap-x-5 md:gap-x-3 place-items-center'}>
              <h3 className='font-semibold text-xl min-w-[4.55rem] md:min-w-[6rem] md:text-2xl lg:min-w-[7.813rem] lg:text-3xl'>
                <PriceTotal
                  price={
                    __convertor
                      ? __convertor * (formatedCost * 100)
                      : formatedCost * 100
                  }
                  noui
                  lang={__convertor ? lang : undefined}
                />
                {yearly ? `/y` : `/m`}
              </h3>
              <div>
                <p
                  className={classNames(
                    `text-base ${
                      selected ? '' : 'text-gray-800 dark:text-gray-100'
                    }`
                  )}
                >
                  {t('included', { usage: pageCountFormatted })}
                </p>
              </div>
            </div>
          </div>
          <div
            className={classNames(
              selected ? 'border-white' : 'border-black dark:border-white',
              'rounded-3xl p-2 border flex place-items-center place-content-center relative',
              'h-6 w-6'
            )}
          >
            <GrCheckmark
              className={classNames(
                'grIcon',
                selected ? 'visible absolute h-3 w-3' : 'hidden'
              )}
            />
          </div>
        </div>
      </div>
    </button>
  )
}
