import React from 'react'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { MainRoutes } from './routes'
import { Link } from '@app/components/stateless/typo/link'
import { routeParseAbsolute } from '@app/lib/router-handler'
import { twMerge } from 'tailwind-merge'
import {
  activeHover,
  secondaryStyles,
} from '@app/components/stateless/marketing/styles'

// determine if logged in for handling dashboard redirects TODO: remove nested button
const MarketingNavMenu = ({ authPage }: { authPage?: boolean }) => {
  const { t, lang } = useTranslation('common')

  if (authPage) {
    return null
  }

  return (
    <ul className={`flex justify-end place-items-center`}>
      {MainRoutes.map((d) => {
        const { name, href } = d

        if (!href) {
          return null
        }

        const firstClassName =
          href === '/register'
            ? twMerge(
                'ml-3 rounded hover:no-underline',
                secondaryStyles,
                activeHover
              )
            : ''

        const itemClassName =
          href !== '/register' && href !== '/login'
            ? `${firstClassName ? ' ' : ''} hidden md:flex`
            : ''

        return (
          <li key={name}>
            <Link
              href={routeParseAbsolute(href, lang)}
              shouldPrefetch={false}
              locale={lang}
              className={twMerge(
                firstClassName || itemClassName
                  ? `${firstClassName}${itemClassName}`
                  : '',
                `px-4 py-2 md:px-6 truncate max-w-[35vw] capitalize`,
                href === '/register'
                  ? ''
                  : 'hover:text-blue-600 hover:border-blue-600'
              )}
            >
              {t(href.substring(1))}
            </Link>
          </li>
        )
      })}
    </ul>
  )
}

export { MarketingNavMenu }
