import { useState, useTransition } from 'react'
import dynamic from 'next/dynamic'
import { ReportViewLeft } from './report-left'
import { ReportEmptyView } from '../placeholders/empty'
import type { Website } from '@app/types'
import { Translate } from 'next-translate'
import { PDFReport } from './pdf-report'

type ReportProps = {
  website: Website | PDFReport
  closeButton?: React.ReactElement
  disablePlayground?: boolean
  disableTabs?: boolean
  download?: boolean
  authenticated?: boolean
  activeSubscription?: boolean
}

type ReportInnerProps = {
  disablePlayground?: boolean
  website: Website | PDFReport
  disableTabs?: boolean
  viewMode?: 'playground' | 'list'
}

const TestViewRest = dynamic(
  () =>
    import('../general/test-view-rest').then((mod) => mod.TestViewRest) as any,
  {
    ssr: false,
    loading: () => <div className='p-4'>...</div>,
  }
) as any

const TestViewPdf = dynamic(
  () =>
    import('../general/test-view-pdf').then((mod) => mod.TestViewPdf) as any,
  {
    ssr: false,
    loading: () => <div className='p-4'>...</div>,
  }
) as any

const FeedList = dynamic(
  () => import('../feed/list').then((mod) => mod.FeedList) as any,
  {
    ssr: false,
    loading: () => <ReportEmptyView />,
  }
) as any

const ReportInner = ({
  website,
  viewMode,
  empty,
  partial,
  pdfReport,
}: ReportInnerProps & {
  empty?: boolean
  partial?: boolean
  pdfReport?: boolean
}) => {
  if (empty) {
    return <ReportEmptyView />
  }

  if (viewMode === 'playground') {
    if (pdfReport) {
      return <TestViewPdf pdf={website as PDFReport} full />
    }
    return (
      <TestViewRest
        url={(website as Website).url || ''}
        marketing
        posRelative
        website={website}
        pdfReport={pdfReport}
      />
    )
  }

  return (
    <FeedList
      issue={website}
      isHidden={false}
      fullScreen
      partial={partial}
      pdfReport={pdfReport}
    />
  )
}

export const ReportView = ({
  website,
  closeButton,
  disablePlayground,
  disableTabs,
  download,
  authenticated,
  activeSubscription,
  t,
  lang,
  pdfReport,
}: ReportProps & { t: Translate; lang: string; pdfReport?: boolean }) => {
  const [_, startTransition] = useTransition()
  const [leftViewMode, setViewMode] = useState<'list' | 'playground'>(
    'playground'
  )

  const onToggleViewModeEvent = () => {
    startTransition(() => {
      setViewMode((mode: string) =>
        mode === 'playground' ? 'list' : 'playground'
      )
    })
  }

  const empty = pdfReport ? false : !('domain' in website && 'url' in website)
  const partial = typeof window !== 'undefined' && window.innerWidth < 900

  return (
    <div className={`block sm:flex h-[100vh] overflow-hidden`}>
      <div className='md:border-r dark:border-gray-600'>
        <ReportViewLeft
          website={website}
          closeButton={closeButton}
          download={download}
          authenticated={authenticated}
          activeSubscription={activeSubscription}
          viewMode={leftViewMode}
          onToggleViewModeEvent={onToggleViewModeEvent}
          printable
          t={t}
          lang={lang}
          pdfReport={pdfReport}
          empty={empty}
        />
      </div>
      <div className='right-panel w-full h-full'>
        <ReportInner
          website={website}
          disablePlayground={disablePlayground}
          disableTabs={disableTabs}
          viewMode={leftViewMode === 'playground' ? 'list' : 'playground'}
          empty={empty}
          partial={partial}
          pdfReport={pdfReport}
        />
      </div>
    </div>
  )
}
