import { useRouter } from 'next/router'
import { NavItem } from './nav-item'
import { useTranslation } from '@app/lib/hooks/useTranslation'

type AuthMenuComponentProps = {
  authenticated?: boolean // user logged in
}

// auth menu on the right of the ui
export function NavbarMenu({ authenticated }: AuthMenuComponentProps) {
  const { pathname } = useRouter()
  const { t } = useTranslation('common')

  return (
    <ul className='flex list-none gap-x-3'>
      <li>
        <NavItem
          route={pathname}
          href={authenticated ? '/dashboard' : '/login'}
          name={t('login')}
          className='truncate'
        />
      </li>
      <li>
        <NavItem
          route={pathname}
          href={authenticated ? '/dashboard' : '/register'}
          name={t('register')}
          className={'px-3 py-2 border-2 rounded'}
        />
      </li>
    </ul>
  )
}
