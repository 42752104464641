export const OSSRoutes = [
  {
    href: 'https://github.com/a11ywatch/a11ywatch',
    name: 'a11ywatch',
    description: 'The web accessibility tool',
    fullName: 'a11ywatch/a11ywatch',
    stars: 0,
  },
  {
    href: 'https://github.com/spider-rs/spider',
    name: 'spider',
    description: 'The fastest web crawler and indexer',
    fullName: 'spider-rs/spider',
    stars: 0,
  },
  {
    href: 'https://github.com/a11ywatch/accessibility-rs',
    name: 'accessibility-rs',
    description: 'Web accessibility engine for Rust',
    fullName: 'a11ywatch/accessibility-rs',
    stars: 0,
  },
  {
    href: 'https://github.com/a11ywatch/crawler',
    name: 'crawler',
    description: 'A gRPC web indexer turbo charged for performance',
    fullName: 'a11ywatch/crawler',
    stars: 0,
  },
  {
    href: 'https://github.com/a11ywatch/rust-to-npm',
    name: 'rust-to-npm',
    description: 'Deploy a rust project to crates.io and npm in one command.',
    fullName: 'a11ywatch/rust-to-npm',
    stars: 0,
  },
  {
    href: 'https://github.com/a11ywatch/github-actions',
    name: 'github-actions',
    description: 'A11yWatch Github Action',
    fullName: 'a11ywatch/github-actions',
    stars: 0,
  },
  {
    href: 'https://github.com/a11ywatch/kayle',
    name: 'a11y',
    description: 'The Kayle Web Accessibility Engine',
    fullName: 'a11ywatch/kayle',
    stars: 0,
  },
]
