import { FILTERED_MARKETING } from './app-config'

export const Routes = [
  // {
  //   nav: false,
  //   href: '/consulting',
  //   name: 'Consulting',
  //   type: 'company',
  // },
  FILTERED_MARKETING
    ? {}
    : {
        nav: false,
        href: '/careers',
        name: 'Careers',
        type: 'company',
      },
  FILTERED_MARKETING
    ? {}
    : {
        nav: false,
        href: '/climate',
        name: 'Climate',
        type: 'company',
      },
  FILTERED_MARKETING
    ? {}
    : {
        nav: false,
        href: '/faq',
        name: 'FAQ',
        type: 'resources',
      },
  {
    nav: false,
    href: '/terms-of-service',
    name: 'Terms of Service',
    type: 'legal',
  },
  {
    nav: false,
    href: '/inactivity-policy',
    name: 'Inactivity Policy',
    type: 'legal',
  },
  {
    nav: false,
    href: '/changelog',
    name: 'Changelog',
    type: 'explore',
  },
  FILTERED_MARKETING
    ? {}
    : {
        nav: false,
        href: '/guides',
        name: 'Guides',
        type: 'resources',
      },
  FILTERED_MARKETING
    ? {}
    : {
        nav: false,
        href: '/blog',
        name: 'Blog',
        type: 'company',
      },
  FILTERED_MARKETING
    ? {}
    : {
        nav: false,
        href: 'https://discord.com/invite/tmCzndrmMm',
        name: 'Discord',
        type: 'company',
      },
  FILTERED_MARKETING
    ? {}
    : {
        nav: false,
        href: '/vercel-integration',
        name: 'Vercel Integration',
        type: 'explore',
      },
  {
    nav: false,
    href: '/privacy',
    name: 'Privacy Policy',
    type: 'legal',
  },
  {
    nav: false,
    href: '/contact',
    name: 'Contact',
    type: 'company',
  },
  {
    nav: false,
    href: '/about',
    name: 'About',
    type: 'company',
  },

  FILTERED_MARKETING
    ? {}
    : {
        nav: false,
        href: '/a11ywatchbot',
        name: 'A11ywatchbot',
        type: 'resources',
      },
  {
    nav: false,
    href: '/web-accessibility-services',
    name: 'Services',
    type: 'resources',
  },
  FILTERED_MARKETING
    ? {}
    : {
        nav: false,
        href: 'https://docs.a11ywatch.com/',
        name: 'Docs',
        mapper: 'docs',
        type: 'resources',
      },
  FILTERED_MARKETING
    ? {}
    : {
        nav: false,
        href: '/roadmap',
        name: 'Roadmap',
        type: 'company',
      },
  FILTERED_MARKETING
    ? {}
    : {
        nav: false,
        href: '/web-accessibility-calculator',
        mapper: 'web-accessibility-calculator',
        type: 'resources',
      },
  FILTERED_MARKETING
    ? {}
    : {
        nav: false,
        href: '/open-source',
        name: 'OSS',
        type: 'resources',
      },
  {
    nav: false,
    href: '/why-us',
    name: 'Why us?',
    type: 'explore',
  },
  {
    nav: true,
    href: '/features',
    name: 'Features',
    type: 'explore',
  },
  FILTERED_MARKETING
    ? {}
    : {
        nav: true,
        href: '/pricing',
        name: 'Pricing',
        type: 'explore',
      },
  {
    nav: false,
    href: '/api-info',
    name: 'API',
    type: 'explore',
  },
  FILTERED_MARKETING
    ? {}
    : {
        nav: false,
        href: '/website-accessibility-checker',
        name: 'Accessibility Scan',
        type: 'explore',
      },
  {
    nav: false,
    href: '/web-accessibility',
    name: 'Web Accessibility',
    type: 'resources',
  },
  FILTERED_MARKETING
    ? {}
    : {
        nav: false,
        href: '/status',
        name: 'Status',
        type: 'resources',
      },
  {
    nav: true,
    href: '/login',
    name: 'Login',
  },
  {
    nav: true,
    href: '/register',
    name: 'Register',
  },
]

export const LoggedInRoutes = [
  {
    nav: true,
    href: '/dashboard',
    name: 'Dashboard',
  },
  {
    nav: true,
    href: '/profile',
    name: 'Profile',
  },
  {
    nav: true,
    href: '/api-info',
    name: 'API',
  },
  {
    nav: false,
    href: '/pricing',
    name: 'Pricing',
  },
  {
    nav: false,
    href: '/register',
    name: 'Register',
  },
  {
    nav: false,
    href: '/login',
    name: 'Login',
  },
]

/*
 * Determine what routes make the auth profile menu appear by component name
 */
export const LOGGIN_ROUTES = [
  '/dashboard',
  '/profile',
  '/payments',
  '/settings',
  '/website-details',
  'payments/complete',
]
