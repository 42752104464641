import React from 'react'
import { Skeleton } from './skeleton'

export function DashboardCellLoader({ count = 2 }: { count?: number }) {
  return (
    <ul className='list-none w-full space-y-2'>
      {Array.from(Array(count).keys()).map((item: string | number) => (
        <li key={item} className={'list-none'}>
          <Skeleton
            className={'h-[40vh] md:h-[573.359px] w-full max-h-[40vh]'}
          />
        </li>
      ))}
    </ul>
  )
}
