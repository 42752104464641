import {
  useState,
  memo,
  useEffect,
  useCallback,
  type Dispatch,
  type SetStateAction,
} from 'react'
import { hiddenList, visibleList } from '@app/stylesheets/list.module.css'
import { ListCellAnalyticsHeader } from './analytics-header'
import { FetchIssue, WebsiteIssue } from './fetch-issue'
import { Skeleton } from '@app/components/placeholders/skeleton'
import { WebsiteAnalyticsTable } from '@app/types/websitelist'
import { Website, type PageIssue } from '@app/types'
import { Translate } from 'next-translate'
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
  ContextMenuLabel,
} from '@app/@/components/ui/context-menu'
import { useToast } from '@app/@/components/ui/use-toast'
import { useWasmContext } from '@app/components/providers'
import { useWebsiteContext } from '@app/components/providers/website'
import { GrView } from 'react-icons/gr'

type InnerAnalyticsProps = {
  loaded?: boolean
  issues?: PageIssue[]
  pageUrl: string
  // extra options
  onRemovePress?(x: string): Promise<void>
  onBlacklistEvent?(x: string): Promise<void>
  blacklist?: string[]
  setIssueVisible?: Dispatch<SetStateAction<boolean>>
  t: Translate
  setVisible?(x: any): void
}

const AnalyticsInnerW = ({ loaded, issues, pageUrl }: InnerAnalyticsProps) => {
  if (!loaded) {
    return <Skeleton className='w-full h-30' />
  }

  if (issues) {
    return <WebsiteIssue issues={issues} />
  }

  return <FetchIssue url={pageUrl} />
}

// the issues analytics with message
const AnalyticsInner = (props: InnerAnalyticsProps) => {
  return <AnalyticsInnerW {...props} />
}

function arePropsEqual(
  oldProps: InnerAnalyticsProps,
  newProps: InnerAnalyticsProps
) {
  return (
    oldProps.loaded === newProps.loaded &&
    oldProps.pageUrl === newProps.pageUrl &&
    oldProps.issues?.length === newProps.issues?.length
  )
}

const AnalyticsInnerMemo = memo(AnalyticsInner, arePropsEqual)

// return issues maped
const AnalyticsWrapper = ({
  errorCount,
  warningCount,
  totalIssues,
  domain,
  pageUrl,
  open: defaultOpen,
  totalErrors,
  extraActive,
  index,
  selectedIndex,
  issues,
  demo,
  blacklist,
  setSelectedIndex,
  onRemovePress,
  onBlacklistEvent,
  t,
  setIssueVisible,
}: WebsiteAnalyticsTable & {
  demo?: boolean
  onRemovePress?(x: string): Promise<void>
  onBlacklistEvent?(x: string): Promise<void>
  blacklist?: string[]
  setIssueVisible: Dispatch<SetStateAction<boolean>>
  t: Translate
}) => {
  const [visible, setVisible] = useState<boolean>(!!defaultOpen)
  const [loaded, setLoaded] = useState<boolean>(false) // keep the issue component mounted once fetched

  const { toast } = useToast()
  const { feed } = useWasmContext()
  const { singlePageScan } = useWebsiteContext()

  const [blacklisted, setBlacklisted] = useState<boolean>(
    (blacklist &&
      Array.isArray(blacklist) &&
      blacklist.some((l) => l === pageUrl || l.match(pageUrl))) ||
      false
  )

  useEffect(() => {
    if (
      blacklist &&
      Array.isArray(blacklist) &&
      blacklist.some((l) => l === pageUrl)
    ) {
      setBlacklisted(true)
    }
  }, [blacklist, pageUrl, setBlacklisted])

  const onScanEvent = useCallback(async () => {
    toast({
      title: t('dashboard:scan-progress'),
    })

    let webPage: Website | null = null
    const target = pageUrl
    const domain = new URL(target).hostname
    // the current item in the feed - wasm binds domain updates after
    const page = feed?.get_page(domain, target)

    try {
      webPage = await singlePageScan({
        variables: { url: target, disableSubscriptions: true },
      })
    } catch (e) {
      console.error(e)
    }

    const nextIssuesInfo = webPage?.issuesInfo
    const nextTotalIssues = nextIssuesInfo?.totalIssues || 0
    // const nextTotalErrorCount = nextIssuesInfo?.errorCount;

    // todo: check the prior issues found and compare
    // replace issue feed section with new value
    if (webPage && page) {
      if (totalErrors === nextTotalIssues) {
        return
      }

      const issueDif = Math.abs(totalErrors - nextTotalIssues)
      const issueMessage = nextTotalIssues > totalErrors ? t('more') : t('less')

      toast({
        // TODO: this need to be translated i18n
        title: `${issueDif} ${issueMessage} ${
          issueDif === 1 ? t('issue') : t('issues')
        } found`,
      })
    }
  }, [feed, singlePageScan, t, toast, totalErrors, pageUrl])

  const onRemove = async () => {
    if (onRemovePress) {
      try {
        await onRemovePress(pageUrl)
      } catch (e) {
        console.error(e)
      }
      if (setVisible) {
        setVisible(false)
      }
    }
  }

  const onBlacklist = async () => {
    if (onBlacklistEvent) {
      try {
        await onBlacklistEvent(pageUrl)
        setBlacklisted((x) => !x)
      } catch (e) {
        console.error(e)
      }
      if (setVisible) {
        setVisible(false)
      }
    }
  }

  useEffect(() => {
    if (visible && !loaded) {
      setLoaded(true)
    }
  }, [visible, loaded, setLoaded])

  // toggle the visibility
  const toggleVisibility = useCallback(() => {
    const x = !visible
    if (typeof setSelectedIndex === 'function' && typeof index === 'number') {
      setSelectedIndex(x ? index : undefined)
    }
    setVisible(x)
    setIssueVisible(x)
  }, [visible, setSelectedIndex, setIssueVisible, setVisible, index])

  return (
    <ContextMenu>
      <ContextMenuTrigger>
        <li>
          <ListCellAnalyticsHeader
            url={pageUrl}
            totalIssues={totalIssues}
            visible={visible}
            warningCount={warningCount}
            errorCount={errorCount}
            domain={domain as string}
            totalErrors={totalErrors}
            extraActive={extraActive}
            hidden={
              typeof selectedIndex === 'number' && selectedIndex !== index
            }
            selected={selectedIndex === index}
            demo={demo}
            blacklist={blacklist}
            onRemovePress={onRemovePress}
            setVisible={toggleVisibility}
            onBlacklistEvent={onBlacklistEvent}
            t={t}
          />
          {errorCount || warningCount ? (
            <div
              aria-hidden={!visible}
              className={
                visible ? `visible ${visibleList}` : `hidden ${hiddenList}`
              }
            >
              <AnalyticsInnerMemo
                loaded={loaded}
                issues={issues}
                pageUrl={pageUrl}
                onRemovePress={onRemovePress}
                onBlacklistEvent={onBlacklistEvent}
                setIssueVisible={setIssueVisible}
                setVisible={toggleVisibility}
                t={t}
              />
            </div>
          ) : null}
        </li>
      </ContextMenuTrigger>
      <ContextMenuContent className='w-64'>
        <ContextMenuLabel>{pageUrl}</ContextMenuLabel>
        <ContextMenuItem onClick={onScanEvent}>{t('scan')}</ContextMenuItem>
        <ContextMenuItem
          onClick={onBlacklist}
          className={blacklisted ? 'text-gray-600 dark:text-gray-300' : ''}
        >
          <div className='flex gap-3 place-items-center'>
            <span>{t('blacklist')}</span>
            {blacklisted ? (
              <GrView className='grIcon text-gray-600 dark:text-gray-300' />
            ) : null}
          </div>
        </ContextMenuItem>
        <ContextMenuItem onClick={onRemove} className='text-red-600'>
          {t('delete')}
        </ContextMenuItem>
      </ContextMenuContent>
    </ContextMenu>
  )
}

// memo expensive issues
export const AnalyticsList = memo(AnalyticsWrapper)
