import React from 'react'
import { getSelectStyle } from './select-style'
import { Translate } from 'next-translate'

// accessibility standards to test
export enum SiteMapOption {
  'Disabled',
  'Only',
  'Extend',
}

// todo: remove type and reverse enum map instead
export type SitemapKeys = keyof typeof SiteMapOption

const standards = Object.values(SiteMapOption).filter(
  (value) => typeof value === 'string'
)

export interface InputProps {
  onSitemapChange?(
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ): void
  sitemap?: SitemapKeys | number
  spacing?: boolean
  className?: string
  disabled?: boolean
  label?: string
  labelOptions?: string
  borderLess?: boolean
  id?: string
  t?: Translate
}

// TODO: translate options values with locale instead of template parsing
export const SitemapSelectInput = ({
  onSitemapChange,
  sitemap,
  spacing,
  className,
  disabled,
  label,
  labelOptions,
  id,
  borderLess,
  t,
}: InputProps) => {
  const sitemapId = id ?? 'ext-sitemap-outlined'
  const labelText = label ?? 'Sitemap'

  return (
    <div>
      <label htmlFor={sitemapId} className='sr-only'>
        {labelText}
      </label>
      <select
        id={sitemapId}
        defaultValue={sitemap}
        onChange={onSitemapChange}
        className={getSelectStyle({ disabled, spacing, className, borderLess })}
        disabled={disabled}
      >
        <optgroup label={labelOptions ?? 'Sitemap Options'}>
          {standards.map((value: any) => (
            <option value={value} key={value} className={'text-sm'}>
              {value &&
                `${labelText} ${
                  t ? t(String(value).toLowerCase()) : String(value)
                }`}
            </option>
          ))}
        </optgroup>
      </select>
    </div>
  )
}
