import React from 'react'
import { GrClose } from 'react-icons/gr'
import { ToolTip } from './tool-tip'
import { IframeManager } from '@app/managers'
import { Annotations } from './annotations'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@app/@/components/ui/popover'

type AnnotationContainerProps = {
  contrastRatio?: string
  source: string
  portalID: number
  elementParent: string
  message: string
  code: string
  context: string
  recurrence: string
  errorType: 'warning' | 'error'
}

// todo: inject tw styles for modal
export function AnnotationContainer({
  contrastRatio,
  source,
  // errorType,
  portalID,
  elementParent,
  message,
  code,
  context,
  recurrence,
  errorType,
}: AnnotationContainerProps) {
  const modalTitle = `${portalID || context + code}-rec-modal-title`
  const modalDescription = `${portalID || context + code}-rec-modal-description`

  return (
    <>
      <Popover aria-labelledby={modalTitle} aria-describedby={modalDescription}>
        <PopoverContent className='relative'>
          <div className='popover-wrapper'>
            <div
              style={{
                display: 'flex',
                placeItems: 'center',
                placeContent: 'space-between',
                overflow: 'auto',
              }}
            >
              <p
                style={{
                  flex: 1,
                  fontSize: '0.77rem',
                  fontWeight: 600,
                  margin: 0,
                  paddingRight: '0.4rem',
                  maxWidth: '13rem',
                  width: '100%',
                  overflow: 'hidden',
                }}
              >
                {code}
              </p>
              <PopoverTrigger
                aria-label='close modal'
                className={'popover-closebtn'}
              >
                <GrClose className='grIcon' />
              </PopoverTrigger>
            </div>
            <div className='popover-container'>
              <code className='popover-code-block' id={modalTitle}>
                {context}
              </code>
              {recurrence ? (
                <p
                  style={{
                    fontWeight: 500,
                    fontSize: '0.7rem',
                    margin: 0,
                  }}
                >
                  Recurred: {recurrence} times
                </p>
              ) : null}
              <p
                style={{ fontSize: '0.9rem', margin: 0 }}
                id={modalDescription}
              >
                {message}
              </p>
            </div>
            {String(message)?.includes('contrast ratio') ? (
              <ToolTip
                visible={IframeManager.activeAnnotation}
                source={source}
                portalID={portalID}
                elementParent={elementParent}
                contrastRatio={contrastRatio}
                message={message}
                code={code}
                context={context}
              />
            ) : null}
          </div>
        </PopoverContent>
        <PopoverTrigger>
          <Annotations
            contrastRatio={contrastRatio}
            errorType={errorType as 'warning' | 'error'}
            open
          />
        </PopoverTrigger>
      </Popover>
    </>
  )
}
