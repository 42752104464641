import gql from 'graphql-tag'

export const REMOVE_PAGE = gql`
  mutation RemovePage($url: String, $domain: String) {
    removePage(url: $url, domain: $domain) {
      code
      success
      message
    }
  }
`
