import React from 'react'
import { useRouter } from 'next/router'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { RestWebsiteProviderWrapper } from '@app/components/providers/rest/rest-website'
import { AuthProvider } from './providers/auth'
import { ThemeProvider } from './themes/theme'
import { LayoutHead } from './meta/layout-head'
import type { InnerApp } from '@app/types/page'
import { Analytics } from '@vercel/analytics/react'

// load the application with providers depending on component
const LayoutWrapper = ({ Component, pageProps, pathName }: InnerApp) => {
  const { wasm, gql, rest } = Component

  return (
    <ThemeProvider attribute='class' enableSystem>
      <AuthProvider load={wasm || gql || rest || pathName === '/api-info'}>
        <RestWebsiteProviderWrapper rest={rest}>
          <Component {...pageProps} />
        </RestWebsiteProviderWrapper>
      </AuthProvider>
    </ThemeProvider>
  )
}

// App wrapping the main marketing pages and application
export function Layout({ Component, pageProps }: InnerApp) {
  const { description, title, translation } = Component.meta ?? {}
  const { t, lang } = useTranslation(translation)
  const router = useRouter()

  const pathName = router.pathname

  const metaTitle = translation ? t(`${translation}:title`) : title
  const metaDescription = translation
    ? t(`${translation}:description`)
    : description

  return (
    <>
      <LayoutHead
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        pathName={pathName}
        lang={lang}
      />
      <LayoutWrapper
        Component={Component}
        pageProps={pageProps}
        pathName={pathName}
      />
      {process.env.NEXT_PUBLIC_ANALYTICS ? <Analytics /> : null}
    </>
  )
}
