const twitter = process.env.NEXT_PUBLIC_TWITTER_SITE

// get the url for the twitter with locales
const twitterBuilder = (lang: string) => {
  if (lang === 'ja') {
    return process.env.NEXT_PUBLIC_TWITTER_SITE_JP || twitter
  }
  if (lang === 'es') {
    return process.env.NEXT_PUBLIC_TWITTER_SITE_ES || twitter
  }
  return process.env.NEXT_PUBLIC_TWITTER_SITE
}

export { twitterBuilder }
