import { API_ENDPOINT, DOMAIN_NAME } from '@app/configs'
import { AppManager, UserManager } from '@app/managers'

const referer = new URL(DOMAIN_NAME).hostname

// app fetch wrapper todo: merge params
export const fetcher = async (
  url: string,
  body?: Record<string, any> | null,
  method?: 'GET' | 'POST' | 'DELETE' | 'PUT',
  rp?: boolean | string
) => {
  const headers = new Headers()
  let data = { data: null as any, message: '', code: 200 }
  let source = null

  if (UserManager.token) {
    headers.append('Authorization', UserManager.token)
  }

  headers.append('Referer', referer)

  // todo: make optional content-tye
  if ((method === 'POST' && body) || method !== 'POST') {
    headers.append('Content-Type', 'application/json')
  }

  if (!rp) {
    headers.append('Connection', 'keep-alive')
  }

  const ep = rp ? API_ENDPOINT.slice(0, -4) : API_ENDPOINT

  try {
    source = await fetch(
      typeof rp === 'string' && rp === 'force' ? url : ep + url,
      {
        headers,
        method: method || 'GET',
        body: body ? JSON.stringify(body) : undefined,
      }
    )
  } catch (e) {
    let message = 'Unknown Error'
    if (e instanceof Error) {
      message = e.message
    }
    AppManager.toggleSnack(true, message, 'error')
  }

  // attempt to parse content to json if success
  if (source && source.ok) {
    try {
      data = await source.json()
    } catch (e) {
      let message = 'Parse Error'
      if (e instanceof Error) {
        message = e.message
      }
      AppManager.toggleSnack(true, message, 'error')
    }
  }

  return data
}
