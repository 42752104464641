import { observable, action } from 'mobx'
import { useStaticRendering as enableMobxStaticRendering } from 'mobx-react-lite'

enableMobxStaticRendering(typeof window === 'undefined')

const defaultSnackBar = {
  title: '',
  type: 'message',
  open: false,
  autoClose: false,
  custom: {
    href: '',
    title: '',
  } as { href?: string; title?: string } | undefined,
}

type TMessage = string | React.ReactElement | Error | unknown

const getMsg = (msg: TMessage) => {
  // user the error message
  if (msg instanceof Error) {
    return msg.message
  }
  if (Array.isArray(msg) && msg.length) {
    return msg[0].message ?? 'Error'
  }
  return msg
}

class AppManager {
  @observable
  overlay = false

  @observable
  snackbar = defaultSnackBar

  @observable
  modalActive = false

  // @observable
  // portals: string[] = []
  readonly portals = observable<any>([])

  dismissAnnotation = () => {}

  clearTimer: NodeJS.Timeout | null = null

  @action
  toggleOverlay = (method?: any, open?: boolean) => {
    if (this.overlay) {
      this.dismissAnnotation()
    }
    this.dismissAnnotation = method
    this.overlay = typeof open !== 'undefined' ? open : !this.overlay
  }

  @action clearPortals = () => {
    this.portals.clear()
  }

  @action
  setPortals = (portals: any) => {
    this.portals.push(portals)
  }

  @action
  removePortal = (index: number) => {
    // todo pop it off array
    const dIndex = this.portals.indexOf(index)
    if (dIndex > -1) {
      this.portals.splice(dIndex, 1)
    }
    // this.portals[index] = null
  }

  @action resetSnackbar = () => {
    this.snackbar = { ...defaultSnackBar }
  }

  @action closeSnack = () => {
    this.snackbar.open = false
  }

  @action setModalActive = (active: boolean) => {
    this.modalActive = active
  }

  @action
  toggleSnack = (
    open: boolean,
    tmessage: string | React.ReactElement | Error | unknown,
    type: 'message' | 'success' | 'error' = 'message',
    autoClose: boolean = true,
    _?: boolean,
    custom?: { href?: string; title?: string }
  ): void => {
    const msg = getMsg(tmessage)

    this.snackbar = {
      title:
        typeof msg === 'string' && msg.startsWith('GraphQL error:')
          ? msg.replace('GraphQL error:', '')
          : msg,
      type,
      open,
      autoClose: autoClose || false,
      custom,
    }

    this.clearTimer && clearTimeout(this.clearTimer)

    if (autoClose) {
      this.clearTimer = setTimeout(() => {
        this.resetSnackbar()
      }, 4000)
    }
  }

  // memory mapped function
  clickOpen = (_: any, __: any, ___: any, ____: any) => {}
}

const manager = new AppManager()

export { manager as AppManager }
