import {
  DetailedHTMLProps,
  FC,
  HTMLAttributes,
  PropsWithChildren,
  ReactElement,
} from 'react'
import { classNames } from '@app/utils/classes'

interface ChipProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  avatar?: ReactElement | string | number
  className?: string
  label?: ReactElement | string | number
  style?: React.CSSProperties
  borderLess?: boolean
}

export const Chip: FC<PropsWithChildren<ChipProps>> = ({
  children,
  avatar,
  className,
  label,
  style,
  borderLess,
  ...extra
}) => {
  return (
    <div
      className={classNames(
        'py-0.5 px-1.5 bg-gray-200 dark:bg-[rgb(52,52,52)] text-gray-800 dark:text-gray-200 rounded-xl flex gap-x-1 place-items-center',
        'text-xs max-w-[12.5rem] truncate min-w-[3rem] md:min-w-[4rem]',
        className || '',
        borderLess ? '' : 'border dark:border-gray-600 px-1'
      )}
      style={style}
      {...extra}
    >
      <div>{children || avatar}</div>
      {label ? label : null}
    </div>
  )
}
