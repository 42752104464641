import { memo, useState } from 'react'
import { useWebsiteContext } from '@app/components/providers/website'
import { RobotsInput } from '../../inputs/robots-input'

export const RobotsBoxWrapper = ({
  robots,
  url,
  activeSubscription,
  domain,
}: {
  robots?: boolean
  url?: string
  domain?: string
  activeSubscription?: boolean
}) => {
  const [robotsEnabled, setRobots] = useState<boolean>(!!robots)
  const { updateWebsite } = useWebsiteContext()

  const onChangeEvent = async () => {
    let nextValue = !robotsEnabled
    setRobots(nextValue)

    try {
      await updateWebsite({
        variables: { url, robots: nextValue },
      })
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <RobotsInput
      activeSubscription={activeSubscription}
      robots={robotsEnabled}
      onChange={onChangeEvent}
      domain={domain}
      url={url}
    />
  )
}

export const RobotsBox = memo(RobotsBoxWrapper)
