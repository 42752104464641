import React, { memo, useState } from 'react'
import { API_ENDPOINT } from '@app/configs'
import { GrCopy } from 'react-icons/gr'
import { classNames } from '@app/utils/classes'
import { Paragraph } from '@app/components/stateless/typo/paragraph'
import { methodColor } from './cell-utils'
import { twMerge } from 'tailwind-merge'

type ApiCellProps = {
  route?: any
  defaultOpen?: boolean
  keyVisible?: boolean
  token?: string // api token to prefill curls,
  id?: string // id to use for dom,
  routeTitle?: string
  routeDescriptions?: string
  copyText: string // copy to clipboard
  routeParams?: Record<string, unknown>
  requiredText: string // Required translated string
}

// API doc collaspe cell with copy curl
const ApiCellComponent = ({
  route,
  keyVisible,
  token,
  id,
  defaultOpen,
  routeTitle,
  routeDescriptions,
  copyText,
  routeParams,
  requiredText,
}: ApiCellProps) => {
  const [sectionVisible, setSectionVisible] = useState<boolean>(!!defaultOpen)
  const onToggleSection = () => setSectionVisible((e) => !e)

  const rp = routeParams ?? route?.params
  const params = rp ? Object.keys(rp) : null

  const curlCommand = `curl --location --request ${
    route.method ?? 'POST'
  } '${API_ENDPOINT}/${route.pathName}' \
--header 'Authorization: ${keyVisible ? token : '$A11YWATCH_TOKEN'}' \
${
  route.method === 'POST' ? `--header 'Content-Type: application/json' ` : ''
} \ ${route.encodedParams}`

  const onCopyClickEvent = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    e?.preventDefault()
    if (navigator.clipboard) {
      navigator.clipboard.writeText(curlCommand)
    }
  }

  const targetCopyID = id + 'copy-text'

  return (
    <li>
      <button
        type={'button'}
        onClick={onToggleSection}
        className={
          'text-base border-2 rounded w-full text-left p-3 m-0 border-gray-700 hover:text-blue-600 hover:border-blue-600'
        }
      >
        <>
          <Paragraph className='text-lg md:text-2xl font-extrabold'>
            {routeTitle}
          </Paragraph>
          <Paragraph>{routeDescriptions}</Paragraph>
        </>
      </button>
      <div
        className={classNames(
          sectionVisible
            ? 'visible bg-gray-100 dark:bg-gray-900 rounded-b'
            : 'hidden',
          'px-3 py-2'
        )}
        aria-hidden={!sectionVisible}
      >
        <div className='py-2 space-y-2 overflow-hidden'>
          <div className='flex place-content-between place-items-center p-3 rounded-sm'>
            <pre className='text-lg font-medium'>
              {API_ENDPOINT}/{route.pathName}
            </pre>
            <span
              className={twMerge(
                `border border-t-2 border-r-4 rounded`,
                methodColor(route.method, 'border'),
                `px-3 py-1 min-w-[60px] inline-block text-center dark:bg-white`
              )}
            >
              <span
                aria-label={`HTTP request method type ${route.method}`}
                className={`font-bold ${methodColor(route.method, 'color')}`}
              >
                {route.method}
              </span>
            </span>
          </div>
        </div>
        {params ? (
          <div className='space-y-2 pb-2'>
            {params?.map((item: any, i) => {
              const { desc, type, optional } = rp[item] ?? {
                desc: '',
                type: '',
                optional: false,
              }

              return (
                <span
                  key={`params-${i}`}
                  className={
                    'block border rounded py-1.5 px-3 dark:border-gray-700 bg-white dark:bg-black'
                  }
                >
                  <span className='block text-lg font-medium'>
                    {item}{' '}
                    <span className='text-sky-700 font-normal dark:text-sky-500 text-base'>
                      {type}
                    </span>
                  </span>
                  <span className='text-base block text-gray-700 dark:text-gray-100'>
                    {desc}
                  </span>
                  {!optional ? (
                    <span
                      className={`text-sm block text-red-700 dark:text-red-400 font-bold`}
                    >
                      {requiredText}
                    </span>
                  ) : null}
                </span>
              )
            })}
          </div>
        ) : null}
        <div className='border px-3 py-1 rounded bg-white dark:bg-black text-base relative flex place-items-center gap-x-3 dark:border-gray-700'>
          <code className='flex-1 overflow-auto py-2'>{curlCommand}</code>
          <button
            className={classNames(
              'py-2 px-2 rounded-full border-2 dark:border-gray-700 dark:text-gray-300',
              sectionVisible ? 'hover:bg-blue-800 hover:text-white' : ''
            )}
            aria-labelledby={targetCopyID}
            title={copyText}
            onClick={onCopyClickEvent}
            disabled={!sectionVisible}
            type={'button'}
          >
            <>
              <span id={targetCopyID} className='sr-only'>
                {copyText}
              </span>
              <GrCopy title={copyText} className='grIcon' />
            </>
          </button>
        </div>
      </div>
    </li>
  )
}

export const ApiCell = memo(ApiCellComponent)
