import {
  FC,
  PropsWithChildren,
  useCallback,
  useRef,
  useEffect,
  useState,
} from 'react'
import { InnerWrapper } from '../general/lists/list-wrapper'
import { useInView, defaultFallbackInView } from 'react-intersection-observer'
import { classNames } from '@app/utils/classes'

defaultFallbackInView(true)

export const LazyMount: FC<
  PropsWithChildren<{
    className?: string
    full?: boolean
    single?: boolean
    minTopValue?: number
    children: any
    threshold?: number
    onlyInnerClass?: boolean
  }>
> = ({ children, className, full, single, threshold, onlyInnerClass }) => {
  const [rootMargin, setRootMargin] = useState('0px 0px 0px 0px')
  const ref = useRef(null)

  const { ref: inViewRef, inView } = useInView({
    threshold: threshold ?? 0.3,
    triggerOnce: true,
    rootMargin,
  })

  useEffect(() => {
    const viewHeight = window.innerHeight
    // @ts-ignore
    const boxHeight = ref.current ? ref.current?.offsetHeight : 10
    const overflow = boxHeight - viewHeight

    if (overflow > 0) {
      setRootMargin(`${overflow / 2 + 10}px 0px ${overflow / 2 + 10}px 0px`)
    }
  }, [ref])

  const setRefs = useCallback(
    (node: any) => {
      ref.current = node
      inViewRef(node)
    },
    [inViewRef]
  )

  return (
    <div ref={setRefs} className={onlyInnerClass ? undefined : className}>
      {inView ? (
        children
      ) : (
        <div className={classNames(className || '', 'min-h-[20px]')}>
          <InnerWrapper loading small full={full} single={single} />
        </div>
      )}
    </div>
  )
}
