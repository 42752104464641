import { memo, useState } from 'react'
import { useWebsiteContext } from '@app/components/providers/website'
import { WarningsEnabledInput } from '../../inputs/warnings-input'

export const WarningsEnabledWrapper = ({
  enabled,
  url,
  activeSubscription,
  domain,
}: {
  enabled?: boolean
  url?: string
  activeSubscription?: boolean
  domain?: string
}) => {
  const [warningsEnabled, setWarnings] = useState<boolean>(
    typeof enabled === 'undefined' ? true : !!enabled
  )
  const { updateWebsite } = useWebsiteContext()

  const onWarningsEvent = async () => {
    let nextValue = !warningsEnabled

    setWarnings(nextValue)

    try {
      await updateWebsite({
        variables: { url, warningsEnabled: nextValue },
      })
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <WarningsEnabledInput
      checked={warningsEnabled}
      activeSubscription={activeSubscription}
      onChange={onWarningsEvent}
      domain={domain}
    />
  )
}

export const WarningsEnabledBox = memo(WarningsEnabledWrapper)
