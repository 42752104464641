import { companyName } from '@app/configs'
import { Translate } from 'next-translate'
import { FC } from 'react'

interface Logo {
  width?: number
  height?: number
  id?: string
  className?: string
  hover?: boolean
  t?: Translate
  style?: React.CSSProperties
}

const NEXT_PUBLIC_LOGO = process.env.NEXT_PUBLIC_LOGO

// change this to App Logo - todo: translate logo text
export const Logo: FC<Logo> = ({
  width,
  height,
  className,
  hover,
  t,
  style,
}) => {
  if (NEXT_PUBLIC_LOGO) {
    return (
      <svg
        width={width || 34}
        height={height || 34}
        className={className}
        viewBox='0 0 34 34'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        dangerouslySetInnerHTML={{
          __html: `<title>${
            t ? t('common:logo') : companyName
          }</title>${NEXT_PUBLIC_LOGO}`,
        }}
        style={style}
      />
    )
  }
  return (
    <svg
      width={width || 34}
      height={height || 34}
      className={className}
      viewBox='0 0 29 29'
      fill='none'
      style={style}
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>{t ? t('common:logo') : companyName}</title>
      <ellipse
        cx='14.3802'
        cy='14.5'
        rx='11.9835'
        ry='11.8852'
        className={`fill-black dark:fill-white ${
          hover ? 'hover:fill-gray-800 dark:hover:fill-gray-200' : ''
        }`}
      />
      <rect
        x='15.1291'
        y='17.4713'
        width='7.48967'
        height='7.42828'
        className='fill-white dark:fill-black'
      />
    </svg>
  )
}
