import gql from 'graphql-tag'

export const REFRESH = gql`
  mutation Refresh {
    refresh {
      email
      jwt
      id
      role
    }
  }
`
