import { companyName } from '@app/configs'
import useNextTranslation from 'next-translate/useTranslation'
import type { I18n, Translate } from 'next-translate'

// custom translation with pre-defined locales.
export const useTranslation = (defaultNS?: string): I18n => {
  const c = useNextTranslation(defaultNS)

  // default translation handling for the app
  const t = (...p: Parameters<Translate>): ReturnType<Translate> =>
    c.t(p[0], { companyName, ...p[1] }, p[2])

  return {
    ...c,
    t: t as Translate,
  }
}
