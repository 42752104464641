import { memo, useMemo } from 'react'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import {
  GrAction,
  GrChannel,
  GrConfigure,
  GrSans,
  GrStop,
  GrSync,
  GrTechnology,
} from 'react-icons/gr'
import { classNames } from '@app/utils/classes'
import { Link } from '@app/components/stateless/typo/link'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@app/@/components/ui/tooltip'

import type { InteractiveProps } from '@app/types/websitelist'
import { ModalOptions } from './enums'
import { WebhookMap } from './blocks/webhooks/webhooks'
import { PopoverTrigger } from '@app/@/components/ui/popover'

const statusRowClass = 'p-2 rounded text-sm lg:text-lg'
const statusRowClassHover =
  'transition duration-500 hover:scale-125 motion-reduce:transition-none motion-reduce:hover:transform-none hover:text-blue-700 dark:hover:text-blue-400'

// generic all btns enabled
const statusClasses = classNames(statusRowClass, statusRowClassHover)

// TODO: translate components
const WebsiteInteractiveBlockComponent = ({
  url,
  onWebsiteCrawl,
  linkUrl,
  handleMainClick,
  activeSubscription,
  actions,
  open,
  storageless,
  pageHeaders,
  scanning,
  activeCrawl,
  onStopWebsiteCrawl,
  demo,
  webhooks,
}: InteractiveProps & {
  storageless?: boolean
  scanning?: boolean
  activeCrawl?: boolean
  demo?: boolean
  webhooks?: WebhookMap
}) => {
  const { t } = useTranslation('dash/website-cell')

  const {
    syncText,
    syncStopText,
    viewSandboxText,
    editActionText,
    editHeadersText,
    editText,
    webhooksText,
  } = useMemo(
    () => ({
      syncText: t('sync', { url }),
      syncStopText: t('sync-stop', { url }),
      viewSandboxText: t('view-sandbox', { url }),
      editHeadersText: t('edit-http', { url }),
      editActionText: t('edit-actions', { url }),
      editText: t('edit-website', { url }),
      webhooksText: t('edit-webhooks', { url }),
    }),
    [url, t]
  )

  const onActionEvent = handleMainClick(
    actions,
    ModalOptions.Actions,
    false,
    url
  )

  const onHeadersEvent = handleMainClick(
    pageHeaders,
    ModalOptions.Headers,
    false,
    url
  )

  const onWebhooksEvent = handleMainClick(
    webhooks,
    ModalOptions.Webhooks,
    false,
    url
  )

  return (
    <TooltipProvider>
      <div className='flex place-items-center px-2 gap-x-3 py-2'>
        <Tooltip>
          <TooltipTrigger asChild>
            {activeCrawl || scanning ? (
              <button
                className={statusClasses}
                onClick={onStopWebsiteCrawl}
                type='button'
              >
                <>
                  <span className='sr-only'>{syncStopText}</span>
                  <GrStop className='grIcon' />
                </>
              </button>
            ) : (
              <button
                type='button'
                className={statusClasses}
                onClick={onWebsiteCrawl}
                disabled={activeCrawl || scanning}
              >
                <>
                  <span className='sr-only'>{syncText}</span>
                  <GrSync className='grIcon' />
                </>
              </button>
            )}
          </TooltipTrigger>

          <TooltipContent>
            <p>{activeCrawl || scanning ? syncStopText : syncText}</p>
          </TooltipContent>
        </Tooltip>

        {storageless ? null : (
          <>
            <Tooltip>
              <TooltipTrigger asChild>
                {demo ? (
                  <button className={statusClasses} type={'button'} disabled>
                    <>
                      <span className='sr-only'>{viewSandboxText}</span>
                      <GrChannel className='grIcon' />
                    </>
                  </button>
                ) : (
                  <Link href={linkUrl} className={statusClasses}>
                    <>
                      <span className='sr-only'>{viewSandboxText}</span>
                      <GrChannel className='grIcon' />
                    </>
                  </Link>
                )}
              </TooltipTrigger>
              <TooltipContent>
                <p>{viewSandboxText}</p>
              </TooltipContent>
            </Tooltip>
            <PopoverTrigger
              title={editText}
              className={classNames(statusClasses, open ? 'text-blue-600' : '')}
              type='button'
            >
              <>
                <span className='sr-only'>{editText}</span>
                <GrConfigure className='grIcon' />
              </>
            </PopoverTrigger>

            <Tooltip>
              <TooltipTrigger asChild>
                <button
                  className={
                    activeSubscription
                      ? statusClasses
                      : classNames(statusRowClass, 'opacity-70')
                  }
                  onClick={onActionEvent}
                  disabled={!activeSubscription}
                  type='button'
                >
                  <>
                    <span className='sr-only'>{editActionText}</span>
                    <GrAction className='grIcon' />
                  </>
                </button>
              </TooltipTrigger>
              <TooltipContent>
                <p>{editActionText}</p>
              </TooltipContent>
            </Tooltip>

            <Tooltip>
              <TooltipTrigger asChild>
                <button
                  className={
                    activeSubscription
                      ? statusClasses
                      : classNames(statusRowClass, 'opacity-70')
                  }
                  onClick={onWebhooksEvent}
                  disabled={!activeSubscription}
                  type='button'
                >
                  <>
                    <span className='sr-only'>{webhooksText}</span>
                    <GrTechnology className='grIcon' />
                  </>
                </button>
              </TooltipTrigger>
              <TooltipContent>
                <p>{webhooksText}</p>
              </TooltipContent>
            </Tooltip>

            <Tooltip>
              <TooltipTrigger asChild>
                <button
                  className={
                    activeSubscription
                      ? statusClasses
                      : classNames(statusRowClass, 'opacity-70')
                  }
                  onClick={onHeadersEvent}
                  disabled={!activeSubscription}
                  type='button'
                >
                  <>
                    <span className='sr-only'>{editHeadersText}</span>
                    <GrSans className='grIcon' />
                  </>
                </button>
              </TooltipTrigger>

              <TooltipContent>
                <p>{editHeadersText}</p>
              </TooltipContent>
            </Tooltip>
          </>
        )}
      </div>
    </TooltipProvider>
  )
}

export const WebsiteInteractiveBlock = memo(WebsiteInteractiveBlockComponent)
