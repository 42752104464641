import { memo, useCallback, useState } from 'react'
import {
  GrFlag,
  GrCompliance,
  GrDown,
  GrUp,
  GrPowerCycle,
} from 'react-icons/gr'
import { IssueTitle } from './title'
import type { FeedComponentProps } from './interface'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { twMerge } from 'tailwind-merge'
import { LoaderIcon } from 'lucide-react'

type FeedHeadingProps = Partial<FeedComponentProps> & {
  onToggleSection(x: (x: boolean) => boolean): void
  sectionHidden?: boolean
  pageUrl: string
  totalIssues: number
  highLight?: boolean
  hideSync?: boolean
  translation?: string
  demo?: boolean
  rendering?: boolean
}

// heading block for feed entry displaying website title that can toggle parent list.
const FeedHeadingComponent = ({
  onToggleSection,
  onScanEvent,
  pageUrl,
  sectionHidden,
  totalIssues,
  highLight,
  hideSync,
  translation,
  demo,
}: FeedHeadingProps) => {
  const { t } = useTranslation(translation || 'dashboard')
  const [isScanLoading, setScanLoading] = useState<boolean>(false)

  // scan event
  const onScan = useCallback(async () => {
    if (typeof onScanEvent === 'function') {
      setScanLoading(true)
      if (!demo) {
        try {
          await onScanEvent(pageUrl)
        } catch (e) {
          console.error(e)
        }
        setScanLoading(false)
      } else {
        setTimeout(() => {
          setScanLoading(false)
        }, 500)
      }
    }
  }, [pageUrl, onScanEvent, setScanLoading, demo])

  // toggle section visibility TODO: move to parent
  const onToggle = useCallback(
    () => onToggleSection((v: boolean) => !v),
    [onToggleSection]
  )

  const sectionVisibleText = t(
    sectionHidden ? 'toggle-issues-v' : 'toggle-issues-h',
    {
      url: pageUrl,
    }
  )

  const syncUrl = t('sync', { url: pageUrl })

  return (
    <div className={'flex gap-x-1 h-15'}>
      <div className='flex-1 px-4 py-2 truncate gap-y-1'>
        <IssueTitle pageUrl={pageUrl} hideSync={hideSync} demo={demo} t={t} />
        <div className='flex gap-x-1 place-items-center text-sm'>
          <div
            className={
              highLight
                ? "after:content-['_*_'] after:relative after:top-0.5"
                : undefined
            }
          >
            {totalIssues} {t(totalIssues === 1 ? 'issues' : 'issue')}
          </div>
          {totalIssues === 0 ? (
            <GrCompliance
              className={'grIcon text-xs'}
              title={t('passes-all')}
            />
          ) : null}

          {highLight ? (
            <GrFlag className={'grIcon text-xs'} title={t('contains-errors')} />
          ) : null}
        </div>
      </div>
      <div className='flex'>
        {onScanEvent && !hideSync ? (
          <button
            onClick={onScan}
            title={syncUrl}
            className='px-5 py-1 hover:text-green-600'
            type={'button'}
            disabled={isScanLoading}
          >
            <>
              {isScanLoading ? (
                <LoaderIcon
                  className={twMerge(
                    'text-base grIcon motion-safe:animate-spin motion-safe:spin-slow'
                  )}
                  size={'1rem'}
                />
              ) : (
                <GrPowerCycle className={'text-base grIcon'} />
              )}
              <span className='sr-only'>{syncUrl}</span>
            </>
          </button>
        ) : null}
        {totalIssues ? (
          <button
            onClick={onToggle}
            type={'button'}
            className='px-5 py-1 hover:text-indigo-600'
            title={sectionVisibleText}
          >
            <span className='sr-only'>{sectionVisibleText}</span>
            {sectionHidden ? (
              <GrDown className={'text-base grIcon'} />
            ) : (
              <GrUp className={'text-base grIcon'} />
            )}
          </button>
        ) : null}
      </div>
    </div>
  )
}

export const FeedHeading = memo(FeedHeadingComponent)
