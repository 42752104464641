import type { Translate } from 'next-translate'
import React from 'react'

type Props = {
  className?: string
  width?: number
  height?: number
  t: Translate
}

// w3c logo
export const W3CLogo = ({ className, width, height, t }: Props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width={width || 69}
      height={height || 46}
      className={className}
      version='1.1'
      viewBox='0 0 69 46'
      xmlSpace='preserve'
    >
      <title>{t('common:logo', { companyName: 'W3C' })}</title>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g className='fill-black dark:fill-white' fillRule='nonzero'>
          <g>
            <polygon points='12.142 40.655 9.481 37.581 7.683 37.581 7.683 45.868 9.748 45.868 9.748 40.691 11.984 43.292 12.3 43.292 14.536 40.691 14.536 45.868 16.602 45.868 16.602 37.581 14.804 37.581' />
            <polygon points='24.572 44.349 20.525 44.349 20.525 42.356 24.304 42.356 24.304 40.837 20.525 40.837 20.525 39.1 24.487 39.1 24.487 37.581 18.459 37.581 18.459 45.868 24.572 45.868' />
            <polygon points='30.609 40.655 27.947 37.581 26.149 37.581 26.149 45.868 28.215 45.868 28.215 40.691 30.451 43.292 30.767 43.292 33.003 40.691 33.003 45.868 35.068 45.868 35.068 37.581 33.27 37.581' />
            <path d='M36.901,37.581 L36.901,45.869 L40.936,45.869 C43.354,45.869 43.865,44.387 43.865,43.439 C43.865,42.467 43.282,41.616 42.103,41.312 C42.796,40.972 43.233,40.352 43.233,39.659 C43.233,38.736 42.443,37.581 40.487,37.581 L36.901,37.581 L36.901,37.581 Z M38.967,39.1 L40.049,39.1 C40.802,39.1 41.106,39.549 41.106,39.975 C41.106,40.412 40.802,40.874 40.049,40.874 L38.967,40.874 L38.967,39.1 Z M38.967,42.393 L40.413,42.393 C41.142,42.393 41.677,42.721 41.677,43.329 C41.677,44.046 41.276,44.35 40.134,44.35 L38.967,44.35 L38.967,42.393 Z' />
            <polygon points='51.517 44.349 47.47 44.349 47.47 42.356 51.25 42.356 51.25 40.837 47.47 40.837 47.47 39.1 51.432 39.1 51.432 37.581 45.404 37.581 45.404 45.868 51.517 45.868' />
            <path d='M53.094,37.581 L53.094,45.869 L55.16,45.869 L55.16,42.503 L55.755,42.503 C56.873,42.503 57.201,44.095 58.088,45.869 L60.36,45.869 C59.193,43.439 58.939,42.418 58.088,41.993 C59.036,41.555 59.51,40.875 59.51,39.964 C59.51,38.724 58.502,37.582 56.8,37.582 L53.094,37.582 L53.094,37.581 Z M55.16,39.1 L56.205,39.1 C56.982,39.1 57.384,39.416 57.384,40.036 C57.384,40.741 56.825,40.984 56.254,40.984 L55.16,40.984 L55.16,39.1 Z' />
            <path d='M17.024,1.065 L22.783,20.645 L28.542,1.065 L32.712,1.065 L44.156,1.065 L44.156,3.011 L38.278,13.138 C40.343,13.801 41.906,15.006 42.965,16.753 C44.024,18.5 44.554,20.552 44.554,22.908 C44.554,25.821 43.78,28.27 42.231,30.256 C40.682,32.241 38.676,33.234 36.214,33.234 C34.36,33.234 32.745,32.645 31.368,31.467 C29.991,30.289 28.972,28.694 28.31,26.681 L31.567,25.331 C32.044,26.549 32.673,27.509 33.454,28.21 C34.235,28.912 35.155,29.262 36.214,29.262 C37.326,29.262 38.266,28.64 39.034,27.396 C39.802,26.152 40.186,24.656 40.186,22.908 C40.186,20.975 39.775,19.479 38.955,18.42 C38.001,17.176 36.505,16.553 34.467,16.553 L32.879,16.553 L32.879,14.647 L38.44,5.035 L31.728,5.035 L31.346,5.685 L23.18,33.235 L22.783,33.235 L16.825,13.298 L10.867,33.235 L10.47,33.235 L0.939,1.065 L5.109,1.065 L10.868,20.645 L14.76,7.46 L12.854,1.065 L17.024,1.065 Z' />
            <path d='M65.827,1.065 C65.008,1.065 64.274,1.36 63.717,1.926 C63.126,2.525 62.797,3.302 62.797,4.104 C62.797,4.906 63.11,5.649 63.684,6.232 C64.267,6.823 65.018,7.144 65.828,7.144 C66.622,7.144 67.391,6.823 67.99,6.241 C68.564,5.684 68.877,4.941 68.877,4.105 C68.877,3.295 68.556,2.535 67.999,1.969 C67.415,1.378 66.655,1.065 65.827,1.065 Z M68.47,4.13 C68.47,4.83 68.2,5.481 67.702,5.962 C67.179,6.468 66.529,6.738 65.811,6.738 C65.136,6.738 64.469,6.46 63.971,5.953 C63.472,5.447 63.194,4.797 63.194,4.104 C63.194,3.412 63.481,2.736 63.996,2.213 C64.477,1.724 65.127,1.462 65.836,1.462 C66.562,1.462 67.212,1.732 67.719,2.247 C68.208,2.737 68.47,3.396 68.47,4.13 Z M65.912,2.323 L64.612,2.323 L64.612,5.768 L65.262,5.768 L65.262,4.299 L65.904,4.299 L66.605,5.768 L67.331,5.768 L66.563,4.198 C67.061,4.096 67.348,3.759 67.348,3.269 C67.347,2.644 66.875,2.323 65.912,2.323 Z M65.793,2.746 C66.401,2.746 66.68,2.915 66.68,3.337 C66.68,3.742 66.401,3.886 65.81,3.886 L65.261,3.886 L65.261,2.746 L65.793,2.746 L65.793,2.746 Z' />
          </g>
          <g transform='translate(45.033000, 0.884000)'>
            <path d='M15.682,-3.33066907e-16 L16.358,4.107 L13.966,8.682 C13.966,8.682 13.048,6.741 11.523,5.667 C10.238,4.762 9.401,4.565 8.092,4.835 C6.411,5.182 4.505,7.192 3.673,9.67 C2.677,12.635 2.668,14.07 2.633,15.388 C2.578,17.502 2.91,18.75 2.91,18.75 C2.91,18.75 1.458,16.064 1.47189941,12.131 C1.481,9.323 1.923,6.777 3.222,4.263 C4.365,2.054 6.064,0.728 7.572,0.572 C9.131,0.411 10.363,1.162 11.315,1.976 C12.315,2.83 13.325,4.697 13.325,4.697 L15.682,-3.33066907e-16 Z' />
            <path d='M15.976,23.238 C15.976,23.238 14.919,25.127 14.261,25.855 C13.602,26.583 12.424,27.865 10.969,28.506 C9.514,29.147 8.751,29.268 7.313,29.13 C5.876,28.992 4.54,28.16 4.072,27.813 C3.604,27.467 2.408,26.444 1.733,25.491 C1.058,24.538 0,22.632 0,22.632 C0,22.632 0.589,24.543 0.958,25.353 C1.17,25.82 1.822,27.247 2.747,28.489 C3.61,29.648 5.286,31.643 7.833,32.093 C10.38,32.543 12.131,31.4 12.564,31.123 C12.997,30.846 13.91,30.081 14.487,29.464 C15.09,28.818 15.661,27.995 15.977,27.501 C16.208,27.141 16.583,26.41 16.583,26.41 L15.976,23.238 Z' />
          </g>
        </g>
      </g>
    </svg>
  )
}
