import { classNames } from '@app/utils/classes'

// secondary styles
export const secondaryStyles =
  'bg-primary text-gray-50 dark:bg-white dark:text-gray-900'
// primary styles
export const primaryStyles =
  'bg-secondary text-gray-900 dark:bg-gray-700 dark:text-gray-100'

const baseSpaceStyles = `px-6 py-2 rounded border-2 border-transparent tracking-wider inline-flex place-content-center w-full md:w-auto md:min-w-[16.8rem] font-medium`

export const activeHover =
  'hover:text-white hover:bg-blue-800 hover:border-blue-500 hover:no-underline dark:hover:border-blue-600 dark:hover:text-white dark:hover:bg-blue-800'

export const getStartedStyles = classNames(
  baseSpaceStyles,
  secondaryStyles,
  activeHover
)

export const liveDemoStyles = classNames(
  baseSpaceStyles,
  primaryStyles,
  activeHover
)
