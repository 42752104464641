import { useWebsiteContext } from '@app/components/providers/website'
import { useMemo } from 'react'

/// get the sort direction variables of a page.
export const useSortDirection = (pageUrl?: string) => {
  const { sortDirections } = useWebsiteContext()

  return useMemo(() => {
    if (
      pageUrl &&
      sortDirections &&
      typeof sortDirections?.has === 'function' &&
      sortDirections?.has(pageUrl)
    ) {
      const sortMap = sortDirections.get(pageUrl)
      return {
        url: pageUrl,
        sort: sortMap?.sortDirection,
        sortType: sortMap?.sortKey,
      }
    }
    return { url: pageUrl }
  }, [sortDirections, pageUrl])
}
