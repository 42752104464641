import React from 'react'
import { FeedIssueCard } from '../feed/issue'
import { PageIssue } from '@app/types'
import { observer } from 'mobx-react-lite'
import { IssueFeedCellContainer } from './issue-cell-container'

type IssueFeedCellProps = {
  item: PageIssue
  hideSelector?: boolean
  style?: any
  completed?: boolean
}

// Issue Cell used on Feed after authentication.
const IssueFeedCellComponent = ({
  item,
  style,
  completed,
}: IssueFeedCellProps) => (
  <IssueFeedCellContainer item={item} style={style} completed={completed}>
    <FeedIssueCard
      message={item.message}
      code={item.code}
      context={item.context}
      type={item.type}
      selector={item.selector}
      recurrence={item.recurrence}
      runnerExtras={item.runnerExtras}
      clipBase64={item.clipBase64}
      clip={item.clip}
    />
  </IssueFeedCellContainer>
)

export const IssueFeedCell = observer(IssueFeedCellComponent)
