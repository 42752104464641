import gql from 'graphql-tag'

// single page website scan
const SCAN_WEBSITE = gql`
  mutation ScanWebsite($url: String, $disableSubscriptions: Boolean) {
    scanWebsite(url: $url, disableSubscriptions: $disableSubscriptions) {
      code
      success
      message
      website {
        _id
        url
        domain
        userId
        lastScanDate
        timestamp
        online
        mobile
        pageLoadTime {
          duration
        }
        pageHeaders {
          key
          value
        }
        issuesInfo {
          accessScore
          totalIssues
          errorCount
          warningCount
        }
        issue {
          code
          type
          message
          context
          selector
          runner
        }
      }
    }
  }
`

export { SCAN_WEBSITE }
