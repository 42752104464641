const prefixMap = {
  C: 'css',
  F: 'failures',
  G: 'general',
  H: 'html',
  T: 'text',
  SL: 'silverlight',
  SM: 'smil',
  ARIA: 'aria',
  SCR: 'client-side-script',
  PDF: 'pdf',
  SVR: 'server-side-script',
  FLASH: 'flash',
}

export function getHtmlcsInfo(code: string) {
  const msgCodeParts = code?.split('.', 5)

  if (msgCodeParts?.length <= 4) {
    return ''
  }

  const techniques = msgCodeParts[4].split(',')

  const technical = new Array(techniques.length)

  const getPrefix = (prefix: string) => {
    const iterator = prefix[Symbol.iterator]()
    let c = null
    let b = ''

    while ((c = iterator.next())) {
      b += c.value
      if (b in prefixMap) {
        return (prefixMap as any)[b] + '/'
      }
      if (c.done || b.length <= 4) {
        break
      }
    }

    return ''
  }

  let j = 0

  // TODO: pre-allocate
  const retval = []

  for (const _tech of techniques) {
    const _techniques = _tech.split('.')
    const ft = _techniques[0]

    if (ft !== '') {
      technical[j] = [
        `https://www.w3.org/WAI/WCAG21/Techniques/${getPrefix(ft)}${ft}`,
        ft,
      ]
      j++
    }
  }

  technical.length = j

  if (technical.length > 0) {
    retval.push(technical)
  }

  return retval
}
