// dynamic list height for rendering
type ListHeight = {
  fullScreen?: boolean
  partial?: boolean
  issueCount?: number
  paging?: boolean
}

// get dynamic list height statically one time
export const getListHeight = ({
  fullScreen,
  partial,
  issueCount = 0,
  paging,
}: ListHeight) => {
  // full height of screen for window
  const list = typeof window !== 'undefined' ? window.innerHeight : 420
  const size = Math.max(list / 6, 210) // try to fit 6 items per screen viewport

  let height = 0

  if (fullScreen) {
    height = list
  } else if (partial) {
    height = Math.max(600, list / 1.15)
  } else if (issueCount === 1) {
    height = 210
  } else if (issueCount === 2) {
    height = 420
  } else {
    height = paging ? 420 : Math.max(420, list / 6)
  }

  return { size, height }
}

export const getListHeightSmall = ({
  fullScreen,
  issueCount = 0,
}: {
  fullScreen?: boolean
  issueCount: number
}) => {
  // full height of screen for window
  const list = typeof window !== 'undefined' ? window.innerHeight : 300
  const size = Math.max(list / 25, 35) // try to fit 6 items per screen viewport

  let height = 0

  if (fullScreen) {
    height = list
  } else {
    height = Math.min(size * issueCount, list)
  }

  return { size, height }
}
