import gql from 'graphql-tag'

// exact page issue fragment
export const pageIssuesFragments = gql`
  fragment PageIssuesParts on PageIssue {
    code
    type
    selector
    message
    context
    recurrence
    runner
    runnerExtras {
      description
      impact
      helpUrl
    }
    clipBase64
    clip {
      x
      y
      width
      height
    }
  }
`

export const issueFragments = gql`
  ${pageIssuesFragments}
  fragment IssueParts on Issue {
    _id
    pageUrl
    issues {
      ...PageIssuesParts
    }
  }
`

export const pagesFragments = gql`
  fragment PagesParts on Pages {
    _id
    domain
    url
    online
    pageLoadTime {
      duration
    }
    issuesInfo {
      accessScore
      totalIssues
      errorCount
      warningCount
      noticeCount
    }
  }
`

// todo: rename pagesSlim and use fragment
export const pagesSlimFragments = gql`
  fragment PagesSlimParts on Pages {
    _id
    domain
    url
    online
    pageLoadTime {
      duration
    }
  }
`

export const webhookFragment = gql`
  fragment WebhookParts on Website {
    webhooks {
      website {
        created
        updated
        deleted
      }
      crawl {
        started
        finished
        shutdown
        canceled
      }
      account {
        exceeded
      }
      endpoint
    }
  }
`

export const websiteFragments = gql`
  ${webhookFragment}
  fragment WebsiteParts on Website {
    _id
    url
    domain
    lastScanDate
    online
    mobile
    standard
    verified
    monitoringEnabled
    crawlDelay
    rootElement
    hideElements
    warningsEnabled
    crawlLimit
    blacklist
    manualAuditPrompted
    scanning
    crawlDuration
    ua
    actionsEnabled
    robots
    subdomains
    tld
    shutdown
    ignore
    rules
    runners
    proxy
    sitemap
    theme
    profileVisible
    label
    ...WebhookParts
    issuesInfo {
      accessScoreAverage
      totalIssues
      errorCount
      warningCount
      noticeCount
      pageCount
    }
    pageHeaders {
      key
      value
    }
    pageLoadTime {
      duration
    }
    actions {
      _id
      path
      domain
      events
    }
  }
`

export const analyticsFragments = gql`
  fragment AnalyticParts on Analytic {
    _id
    domain
    pageUrl
    accessScore
    totalIssues
    errorCount
    warningCount
    noticeCount
  }
`
