import { memo, useEffect, useMemo, useState } from 'react'
import {
  GrCalendar,
  GrCircleAlert,
  GrConfigure,
  GrPowerShutdown,
  GrTag,
} from 'react-icons/gr'
import { Chip } from '@app/components/general/chip'
import { Website } from '@app/types'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { getDateLocale } from '@app/utils/date-locale'
import { buildFormatter } from '@app/lib/utils/price-config'

export const WebsiteSecondaryComponent = ({
  pageIssueCount = 0, // TODO: remove for issues info
  lastScanDate,
  issuesInfo,
  pageHeaders,
  online,
  borderLess,
  children,
  label,
}: Website & {
  pageIssueCount?: number
  dashboard?: boolean
  online?: boolean
  score?: number
  borderLess?: boolean
  children?: any
}) => {
  const { t, lang } = useTranslation('dashboard')
  const [lastScan, setScanDate] = useState<string>('')

  const tags: Record<string, string> = label
    ? t('leaderboards-categories:tags', undefined, {
        returnObjects: true,
      })
    : {}

  useEffect(() => {
    // format client side date - mismatch hydrated data
    if (!lastScan && lastScanDate) {
      setScanDate(getDateLocale(new Date(lastScanDate), lang))
    }
  }, [lastScan, lastScanDate, setScanDate, lang])

  const { totalIssues = 0 } = issuesInfo ?? {}

  const { headers, headingJson } = useMemo(() => {
    const heads =
      pageHeaders && pageHeaders.length
        ? pageHeaders
            .filter((item: any) => item.key)
            .map((item: any) => ({ [item.key]: item?.value }))
        : []
    let stringHeads = ''

    if (heads && heads.length) {
      try {
        stringHeads = JSON.stringify(heads)
      } catch (e) {
        console.error(e)
      }
    }

    return { headers: heads, headingJson: stringHeads }
  }, [pageHeaders])

  const numberFormatter = useMemo(() => buildFormatter(lang, true), [lang])

  const formattedIssues = useMemo(() => {
    return pageIssueCount && totalIssues
      ? numberFormatter.format(totalIssues)
      : ''
  }, [pageIssueCount, totalIssues, numberFormatter])

  const _label = label && label.toLowerCase()

  return (
    <div className={`flex gap-x-1 overflow-x-auto max-w-[75vw]`}>
      {children}
      {typeof online !== 'undefined' && !online ? (
        <Chip
          avatar={<GrPowerShutdown className={'grIcon'} />}
          label={t('offline')}
          title={t('offline-p')}
          borderLess={borderLess}
        />
      ) : null}
      {headers && headers.length ? (
        <Chip
          avatar={<GrConfigure className={'grIcon'} />}
          label={`${t('headers').toLowerCase()}: ${headers.length}`}
          borderLess={borderLess}
          title={`${t('headers')}: ${headingJson}`}
        />
      ) : null}
      {formattedIssues ? (
        <Chip
          avatar={<GrCircleAlert className={'grIcon'} />}
          label={formattedIssues}
          title={t(
            totalIssues === 1 ? 'total-errors_one' : 'total-errors_other',
            { total: formattedIssues }
          )}
          className='min-w-[3.4rem] md:min-w-[4.1rem]'
          borderLess={borderLess}
        />
      ) : null}
      {lastScan ? (
        <Chip
          avatar={<GrCalendar className={'grIcon'} />}
          label={<time dateTime={lastScanDate}>{lastScan}</time>}
          borderLess={borderLess}
        />
      ) : null}
      {_label ? (
        <Chip
          avatar={<GrTag className={'grIcon'} />}
          label={
            <p className='text-xs'>{_label in tags ? tags[_label] : label}</p>
          }
          borderLess={borderLess}
        />
      ) : null}
    </div>
  )
}

export const WebsiteSecondary = memo(WebsiteSecondaryComponent)
