import React from 'react'
import { classNames } from '@app/utils/classes'

// the CSS selector to use
export const Selector = ({
  selector,
  largeBody,
}: {
  selector?: string
  largeBody?: boolean
}) => {
  return (
    <p
      className={classNames(
        'truncate text-sm font-medium max-w-[90%] flex-1',
        largeBody ? '' : ''
      )}
    >
      {selector}
    </p>
  )
}
