import React from 'react'
import { classNames } from '@app/utils/classes'
import { GrChapterAdd } from 'react-icons/gr'
import { Button } from './buttons'

export const EmptyDialogButton = ({
  buttonTitle = 'Subscribe',
  icon,
  buttonStyles,
  iconButton,
}: {
  icon?: boolean
  buttonTitle?: string | JSX.Element
  buttonStyles?: string
  iconButton?: boolean
}) => {
  return (
    <Button
      border
      disabled
      className={classNames(
        buttonStyles || '',
        !iconButton ? 'px-8 lg:px-12' : ''
      )}
      iconButton={iconButton}
    >
      <>
        {buttonTitle}
        {icon ? <GrChapterAdd className='grIcon' /> : null}
      </>
    </Button>
  )
}
