import { useQuery } from '@apollo/client'
import { useWasmContext } from '@app/components/providers'
import { useAuthContext } from '@app/components/providers/auth'
import { useSearchQuery } from '@app/data/local/useSearchQuery'
import { AppManager } from '@app/managers'
import {
  GET_WEBSITE_PAGES_PAGINATED,
  GET_WEBSITE_PAGES_SLIM_PAGINATED,
} from '@app/queries/websites'

// get pages pagined by website
export const usePagesData = (url?: string | string[], slim?: boolean) => {
  const { search, visible, onSearchEvent, onSearchOpenEvent } = useSearchQuery()
  const { feed } = useWasmContext()
  const { account } = useAuthContext()

  const variables = { url, limit: 10, offset: 0, search }

  const skip = account.role === 11

  const {
    data,
    loading,
    refetch,
    error,
    fetchMore: fetchMorePages,
  } = useQuery(
    slim ? GET_WEBSITE_PAGES_SLIM_PAGINATED : GET_WEBSITE_PAGES_PAGINATED,
    {
      variables,
      ssr: false,
      skip,
    }
  )

  const updateQuery = (prev: any, { fetchMoreResult }: any) => {
    if (!fetchMoreResult) {
      return prev
    }

    const nextPages = fetchMoreResult?.website?.pages

    if (!nextPages?.length) {
      AppManager.toggleSnack(true, 'No more pages exist.')
      return prev
    }

    if (nextPages) {
      for (const item of nextPages) {
        const w = feed.get_page(item.domain, item.url || item.pageUrl) ?? {}

        feed.insert_website({
          ...w,
          domain: item.domain,
          pageUrl: item.url,
          online: item.online,
          pageLoadTime: {
            duration: item.pageLoadTime.duration ?? w?.pageLoadTime?.duration,
          },
        })
      }
    }

    const pages = [...prev?.website?.pages, ...fetchMoreResult?.website?.pages]

    return {
      ...prev,
      website: {
        ...prev?.website,
        pages,
      },
    }
  }

  const pages = data?.website?.pages

  // pages page pagination
  const onLoadMorePages = async () => {
    try {
      await fetchMorePages({
        query: GET_WEBSITE_PAGES_PAGINATED,
        variables: {
          ...variables,
          offset: Number(pages.length || 0),
          search:
            search && search?.length > 400 ? search.substring(0, 400) : search,
        },
        updateQuery,
      })
    } catch (e) {
      console.error(e)
    }
  }

  return {
    data: pages,
    loading: loading,
    refetch,
    error,
    onLoadMorePages: onLoadMorePages,
    searchVisible: visible,
    onSearchEvent,
    onSearchOpenEvent,
    search,
  }
}
