import React from 'react'
import { Button } from './button'
import { useTranslation } from '@app/lib/hooks/useTranslation'

// load more content if exist
export const LoadMoreButton: React.FC<{
  onLoadMoreEvent?(x: any): Promise<void>
  visible?: boolean
  title?: string | React.ReactElement
  loading?: boolean
  translation?: string
}> = ({ onLoadMoreEvent, visible, loading, title, translation }) => {
  const { t } = useTranslation(translation || 'dashboard')

  return (
    <div
      className={visible ? 'flex place-content-center py-8' : 'hidden'}
      aria-busy={loading ? 'true' : 'false'}
    >
      <Button
        onClick={onLoadMoreEvent}
        disabled={!onLoadMoreEvent}
        className={
          'w-40 hover:bg-black hover:text-white dark:hover:bg-white dark:hover:text-black'
        }
        border={false}
      >
        {loading ? t('loading-w') : title || t('more')}
      </Button>
    </div>
  )
}
