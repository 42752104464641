import React, { memo, useCallback, useEffect, useState } from 'react'
import { useWebsiteContext } from '@app/components/providers/website'
import { CrawlDelayBoxInput } from '../../inputs/crawl-delay-input'

export const CrawlDelayBoxWrapper = ({
  crawlDelay: delay,
  url,
  activeSubscription,
}: {
  url: string
  crawlDelay?: number
  activeSubscription?: boolean
}) => {
  const [crawlDelay, setDelay] = useState<number | undefined>(delay)
  const { updateWebsite } = useWebsiteContext()

  const onChangeUA = (e: React.ChangeEvent<any>) =>
    setDelay(Math.min(Number(e?.target?.value), 2000))

  const performMutation = useCallback(async () => {
    if (crawlDelay !== delay) {
      try {
        await updateWebsite({
          variables: {
            url,
            crawlDelay,
          },
        })
      } catch (e) {
        console.error(e)
      }
    }
  }, [crawlDelay, delay, updateWebsite, url])

  useEffect(() => {
    if (crawlDelay === delay) {
      return
    }

    const debounce = setTimeout(performMutation, 2300)

    return () => clearTimeout(debounce)
  }, [crawlDelay, delay, performMutation])

  return (
    <CrawlDelayBoxInput
      onChange={onChangeUA}
      value={crawlDelay}
      activeSubscription={activeSubscription}
      url={url}
    />
  )
}

export const CrawlDelayBox = memo(CrawlDelayBoxWrapper)
