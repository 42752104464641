import { FC } from 'react'
import { classNames } from '@app/utils/classes'

export const IssueMessage: FC<{ message?: string; clip?: boolean }> = ({
  message,
  clip,
}) => {
  const m = message || ''
  const mLength = m.length

  const largeBody = mLength < 65
  const medBody = mLength > 160 || clip

  return (
    <div
      className={classNames(
        medBody ? 'leading-tight' : '',
        mLength > 100 || clip ? 'text-sm' : '',
        largeBody ? 'text-base' : '',
        clip ? 'line-clamp-4 md:line-clamp-3' : 'line-clamp-5 md:line-clamp-4'
      )}
    >
      {m}
    </div>
  )
}
