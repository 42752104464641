import React, { FC, PropsWithChildren } from 'react'
import { classNames } from '@app/utils/classes'
import { useTranslation } from '@app/lib/hooks/useTranslation'

// determine the price renew block
interface PriceInfo {
  onTogglePlans?(): any
  selectHighPlans?: boolean
}

// TODO: add translations for text in one go
export const PricingInfo: FC<PropsWithChildren<PriceInfo>> = ({
  onTogglePlans,
  selectHighPlans,
  children,
}) => {
  const { t } = useTranslation('price')

  return (
    <div className='flex py-2 gap-2 place-items-center flex-wrap-reverse'>
      {children}
      <button
        onClick={onTogglePlans}
        className={classNames(
          `border border-gray-800 dark:border-gray-400 dark:text-gray-300 dark:hover:text-blue-600 px-2 rounded-3xl py-2 text-sm whitespace-nowrap sm:px-8`,
          'focus:outline-none focus:ring-1 focus:ring-gray-500',
          'hover:bg-gradient-radial dark:hover:border-blue-600',
          'min-w-[11rem] md:min-w-[13rem] flex place-items-center place-content-center'
        )}
        type={'button'}
      >
        {t('view-plans', { selected: t(selectHighPlans ? 'normal' : 'large') })}
      </button>
    </div>
  )
}
