import React from 'react'
import { Skeleton } from './skeleton'
import { classNames } from '@app/utils/classes'

export function SimpleListItemSkeleton({ full }: { full?: boolean }) {
  return (
    <li className='flex flex-col gap-y-2'>
      <Skeleton
        className={classNames(full ? 'h-40 w-[100%]' : 'w-[30%] h-8')}
      />
      <Skeleton
        className={classNames(full ? 'h-40 w-[100%]' : 'w-[40%] h-8')}
      />
    </li>
  )
}
