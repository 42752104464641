import { FC, ReactNode } from 'react'

import { Logo } from './logo'
import { Link } from '@app/components/stateless/typo/link'

import { companyName } from '@app/configs'
import { useTranslation } from '@app/lib/hooks/useTranslation'

interface NavBarTitleProps {
  title?: string | string[]
  children?: ReactNode | string
  flex?: boolean
  marketing?: boolean
  ismobile?: boolean
  notitle?: boolean
  href?: string
  hidelogo?: boolean
  hideCompanyName?: boolean
}

const NavBarTitle: FC<NavBarTitleProps> = ({
  title,
  children,
  flex,
  marketing,
  ismobile,
  notitle,
  hidelogo,
  href,
  hideCompanyName,
  ...props
}) => {
  const { t } = useTranslation('common')

  if (notitle) {
    return null
  }

  if (marketing) {
    return (
      <Link
        href={href ?? '/'}
        className={'hover:no-underline hover:ring-4 rounded'}
        title={t('home')}
      >
        <span className={`flex place-items-center gap-x-1.5`}>
          {hidelogo ? null : <Logo t={t} />}
          {hideCompanyName ? null : (
            <span
              className={`text-lg md:text-xl tracking-normal font-bold hidden md:block`}
            >
              {companyName}
            </span>
          )}
        </span>
      </Link>
    )
  }

  return (
    <span
      className={`text-lg md:text-xl tracking-normal font-medium flex flex-1 line-clamp-1`}
      {...props}
    >
      {children || title}
    </span>
  )
}

export { NavBarTitle }
