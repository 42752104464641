import { useMemo, useCallback, useDeferredValue, useState } from 'react'
import { MoreOptions } from '@app/components/general/cells/menu/more'
import { Link } from '@app/components/stateless/typo/link'

import { WebsiteSecondary } from './render'
import { StandardBox, SitemapBox, MonitoringEnabledBox } from './blocks'
import { MobileBox } from './blocks/mobile'
import type { Pages } from '@app/types'
import { Timer } from '../timer'
import { UserAgentBox } from './blocks/user-agent'
import { CrawlDelayBox } from './blocks/crawl-delay'
import { useWasmContext } from '@app/components/providers'
import { useAuthContext } from '@app/components/providers/auth'
import { fetcher } from '@app/utils/fetcher'
import { HomeManager } from '@app/managers'
import { useInteractiveContext } from '@app/components/providers/interactive'
import { TLDBox } from './blocks/tld'
import { SubDomainsBox } from './blocks/subdomains'
import { RobotsBox } from './blocks/robots'
import { useWebsiteLiveData } from '@app/data/formatters/use-live-data'
import { RunnersBox } from './blocks/runner'
import { ProxyBox } from './blocks/proxy'
import { Score } from '../blocks/score'
import { WebsiteInteractiveBlock } from './website-cell-interactive'
import { RootSelectorBox } from './blocks/root-selector'
import { HideElementsBox } from './blocks/hide-elements'
import { classNames } from '@app/utils/classes'
import { TopBlock } from './blocks/top-block'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import Trans from 'next-translate/Trans'
import { routeParseAbsolute } from '@app/lib/router-handler'
import { ThemeBox } from './blocks/theme'
import { RenderAnalytics } from './website-analytics'
import { WebsiteDashboardCellProps } from '@app/types/websitelist'
import { WebsiteTable } from './website-table'
import { styles } from '@app/styles/dashboard-styles'
import { useDomainLinks } from '@app/data/formatters/use-domain-links'
import { useTransDashboard } from '@app/data/translations/use-trans-dashboard'
import { WarningsEnabledBox } from './blocks/warnings'
import { P } from '@app/components/stateless/typo/p'
import { CrawlLimitBox } from './blocks/crawl-limit'
import { BlacklistBox } from './blocks/blacklist'
import { ModalOptions } from './enums'
import { ModalType } from '@app/data/enums'
import { buildFormatter } from '@app/lib/utils/price-config'

import { Popover, PopoverContent } from '@app/@/components/ui/popover'
import { IgnoreRulesBox } from './blocks/ignore-rules'
import { Label } from '@app/@/components/ui/label'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@app/@/components/ui/tooltip'
import { useWebsiteContext } from '@app/components/providers/website'
import { useToast } from '@app/@/components/ui/use-toast'
import { Switch } from '@app/@/components/ui/switch'
import { twMerge } from 'tailwind-merge'

const gridCellStyle = 'p-0.5'
const cellStyle = classNames(styles.grid, gridCellStyle)

// main dashboard cell with details and paginated views DO-NOT MEMO
export const WebsiteCellDashboard = ({
  url,
  pages,
  issues: currentIssues,
  issuesInfo,
  pageLoadTime,
  lastScanDate,
  pageHeaders,
  index,
  domain,
  online,
  mobile,
  standard,
  activeCrawl,
  crawlDuration,
  ua,
  actions,
  robots,
  subdomains,
  tld,
  shutdown,
  verified,
  runners,
  proxy,
  sitemap,
  monitoringEnabled,
  crawlDelay,
  rootselector,
  hideElements,
  theme,
  onlyLive,
  warningsEnabled,
  storageless,
  analyticsEnabled,
  crawlLimit,
  blacklist,
  manualAuditPrompted,
  scanning,
  ignore: websiteIgnore,
  removeActiveCrawl,
  removePress,
  handleClickOpen,
  crawlWebsite,
  webhooks,
  onSortEvent,
  profileVisible,
  label,
}: WebsiteDashboardCellProps) => {
  const { t, lang } = useTranslation('dashboard')
  const { feed } = useWasmContext()
  const { account } = useAuthContext()
  const { setSelectedWebsite, selectedWebsite, setModal } =
    useInteractiveContext()
  // const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false)
  const { toast } = useToast()
  const { linkUrl, domainHost } = useDomainLinks({ url })
  const { updateWebsite } = useWebsiteContext()
  const {
    // transIssue,
    // transIssues,
    transError,
    transErrors,
    transPage,
    transPages,
    transWarning,
    transWarnings,
  } = useTransDashboard(t)

  const [isManualAudit, setManualAudit] = useState<boolean>(
    manualAuditPrompted ?? false
  )

  // defer feed data items
  const items: Pages[] = useDeferredValue(
    feed?.get_data_item(domain, !!(tld || subdomains)) ?? []
  )

  const issues = (items.length ? items : currentIssues) as Pages[]

  // only use live data
  const isLiveData = activeCrawl || scanning || storageless

  // format the issues with the live data
  const { errorCount, warningCount, totalIssues, liveData } =
    useWebsiteLiveData({
      issues,
      issuesInfo,
      url,
    })

  const confirmRemove = t('website-remove')
  const confirmRemoveD = t('website-remove-d', { url })

  const onRemovePress = useCallback(async () => {
    setModal({
      open: true,
      modalType: ModalType.cancel,
      url: url,
      data: null,
      title: confirmRemove,
      description: confirmRemoveD,
      submit: async () => {
        try {
          await removePress({
            variables: {
              url,
            },
          })

          feed.remove_website(domain)

          if (url === selectedWebsite) {
            HomeManager.setDashboardView('')
            setSelectedWebsite('')
          }
        } catch (e) {
          console.error(e)
        }
      },
    })
  }, [
    setModal,
    url,
    removePress,
    selectedWebsite,
    setSelectedWebsite,
    confirmRemove,
    confirmRemoveD,
    domain,
    feed,
  ])

  const handleMainClick =
    (
      eventData?: any,
      title?: string | ModalOptions,
      _mini?: boolean,
      url?: string
    ) =>
    async () => {
      // block modal on only live examples
      // mini player open - small modal with dynamic content
      let eventDS = eventData

      if (title === ModalOptions.Manual && url) {
        eventDS = await fetcher('/prompt-manual-audit', { url }, 'POST')
        setManualAudit(true)
        toast({ title: t('r-audit-c') })
        return
      }

      if (title === ModalOptions.VerifyDns && url) {
        eventDS = await fetcher(
          '/website/dns',
          { domain: new URL(url).hostname },
          'POST'
        )
      }

      if (title === ModalOptions.Analytics) {
        if (!analyticsEnabled) {
          toast({
            // @ts-ignore
            title: (
              <Trans
                i18nKey={'dashboard:analytics-disabled'}
                components={[
                  <P key={'errors-g'} />,
                  <Link
                    href={'/settings'}
                    key={'errors-p'}
                    className={'text-blue-600 hover:text-blue-500'}
                  />,
                ]}
              />
            ),
          })
          return
        }

        eventDS = await fetcher(
          url
            ? `/list/analytics?limit=1000&domain=${new URL(url).hostname}`
            : '/list/website?limit=50',
          null
        )
      }

      handleClickOpen && handleClickOpen(eventDS, title, url, null)
    }

  // prevent active crawl
  const onStopWebsiteCrawl = async () => {
    if (activeCrawl || scanning) {
      const hostname = new URL(url).hostname
      const res = await fetcher(
        '/scanning/website',
        { domain: hostname },
        'DELETE'
      )

      // remove active crawl
      if (res?.data?.shutdown && removeActiveCrawl) {
        removeActiveCrawl(hostname)
      }
    }
  }

  const pagecount = issuesInfo?.pageCount || 0
  const issueTotal = issuesInfo?.totalIssues || 0
  const pct = issues && Array.isArray(issues) ? issues.length : pagecount
  const pageIssueCount = isLiveData && pct > pagecount ? pct : pagecount

  const { accessScoreAverage: accessScore } = issuesInfo ?? {}

  const scanProgressText = t('scan-progress')

  const onWebsiteCrawl = useCallback(async () => {
    if (typeof crawlWebsite === 'function' && url) {
      toast({
        title: scanProgressText,
      })
      try {
        await crawlWebsite({
          variables: {
            url,
          },
        })
      } catch (e) {}
    }
  }, [url, crawlWebsite, scanProgressText, toast])

  const onChangeWebsiteVisiblity = async () => {
    await updateWebsite({
      variables: { url, profileVisible: !profileVisible, filter: '' },
    })
  }

  const formatter = useMemo(() => buildFormatter(lang, true), [lang])

  // const togglePopOverEvent = () => {
  //   setIsPopoverOpen((x) => !x)
  // }

  return (
    <TooltipProvider>
      <li>
        <Popover>
          <>
            <div className='flex gap-x-1 place-items-center place-content-between ltr:pr-3 rtl:pl-3'>
              <div className='flex flex-wrap gap-x-1'>
                <div
                  className={twMerge(
                    'bg-[rgb(234,234,234)] dark:bg-gray-900 place-items-center flex',
                    shutdown
                      ? 'bg-red-100 text-red-700 dark:bg-gray-900 dark:text-red-800'
                      : ''
                  )}
                >
                  <div className={'text-center px-3.5'}>
                    <Score score={accessScore} />
                  </div>
                </div>
                <div
                  className={`flex place-items-center truncate flex-wrap px-2.5 gap-4`}
                >
                  <div className='space-y-1 ltr:pr-1 rtl:pl-1 py-1'>
                    <Link
                      href={url}
                      target='_blank'
                      className={classNames(
                        styles.title,
                        'hover:text-blue-600 p-0 flex place-items-center gap-x-1 max-w-[60vw] md:max-w-[75vw] font-semibold'
                      )}
                    >
                      {domainHost}
                    </Link>
                    <WebsiteSecondary
                      domain={domain}
                      issuesInfo={{
                        ...issuesInfo,
                        totalIssues: totalIssues || issueTotal,
                      }}
                      pageIssueCount={pageIssueCount}
                      pageLoadTime={pageLoadTime}
                      lastScanDate={lastScanDate}
                      score={accessScore}
                      pageHeaders={pageHeaders}
                      robots={robots}
                      subdomains={subdomains}
                      tld={tld}
                      dashboard
                      online={online}
                      borderLess
                      label={label}
                    >
                      <Timer
                        noui
                        stop={!activeCrawl}
                        duration={crawlDuration}
                        shutdown={shutdown}
                        lang={lang}
                        t={t}
                      />
                    </WebsiteSecondary>
                  </div>
                </div>
              </div>
              <MoreOptions
                url={url}
                crawlWebsite={onWebsiteCrawl}
                handleMainClick={handleMainClick}
                removePress={onRemovePress}
                pages={pages}
                pageHeaders={pageHeaders}
                index={index}
                subdomains={subdomains}
                tld={tld}
                shutdown={shutdown}
                verified={verified}
                storageless={storageless}
                manualAuditPrompted={isManualAudit}
                // togglePopOverEvent={togglePopOverEvent}
              />
            </div>
            <WebsiteInteractiveBlock
              url={url}
              linkUrl={linkUrl}
              activeCrawl={activeCrawl}
              crawlDuration={crawlDuration}
              pageHeaders={pageHeaders}
              actions={actions}
              activeSubscription={account.activeSubscription}
              storageless={storageless}
              scanning={scanning}
              onWebsiteCrawl={onWebsiteCrawl}
              handleMainClick={handleMainClick}
              onStopWebsiteCrawl={onStopWebsiteCrawl}
              webhooks={webhooks}
            />
            <PopoverContent className='w-screen md:w-full border-2 ltr:md:ml-4 rtl:md:mr-4'>
              {account.activeSubscription ? null : (
                <div className='px-3 py-3 font-semibold border-b dark:border-gray-700'>
                  <Trans
                    i18nKey='dashboard:edit-upgrade'
                    components={[
                      <p key={1} />,
                      <Link
                        className='text-blue-600 hover:text-blue-400 hover:underline'
                        key={2}
                        href={routeParseAbsolute(`/payments`, lang)}
                      />,
                    ]}
                  />
                </div>
              )}
              <div className={cellStyle}>
                <UserAgentBox
                  ua={ua}
                  url={url}
                  activeSubscription={account.activeSubscription}
                />
                <ProxyBox
                  proxy={proxy}
                  url={url}
                  activeSubscription={account.activeSubscription}
                />
                <CrawlDelayBox
                  crawlDelay={crawlDelay}
                  url={url}
                  activeSubscription={account.activeSubscription}
                />
                <RootSelectorBox
                  url={url}
                  rootElement={rootselector}
                  activeSubscription={account.activeSubscription}
                />
                <HideElementsBox
                  url={url}
                  hideElements={hideElements}
                  activeSubscription={account.activeSubscription}
                />
                <CrawlLimitBox
                  url={url}
                  crawlLimit={crawlLimit}
                  activeSubscription={account.activeSubscription}
                />
                <BlacklistBox
                  blacklist={blacklist}
                  url={url}
                  activeSubscription={account.activeSubscription}
                />
              </div>
              <div className={cellStyle}>
                <MonitoringEnabledBox
                  enabled={monitoringEnabled}
                  url={url}
                  domain={domain}
                  activeSubscription={account.activeSubscription}
                />
                <WarningsEnabledBox
                  enabled={warningsEnabled}
                  url={url}
                  domain={domain}
                  activeSubscription={account.activeSubscription}
                />
                <RobotsBox
                  robots={robots}
                  url={url}
                  activeSubscription={account.activeSubscription}
                  domain={domain}
                />
                <MobileBox
                  mobile={mobile}
                  url={url}
                  activeSubscription={account.activeSubscription}
                  domain={domain}
                />
                <SubDomainsBox
                  subdomains={subdomains}
                  url={url}
                  domain={domain}
                  activeSubscription={account.activeSubscription}
                />
                <TLDBox
                  tld={tld}
                  url={url}
                  activeSubscription={account.activeSubscription}
                  domain={domain}
                />
              </div>
              <div className={cellStyle}>
                <StandardBox
                  standard={standard}
                  url={url}
                  domain={domain}
                  activeSubscription={account.activeSubscription}
                />
                <SitemapBox
                  sitemap={sitemap}
                  url={url}
                  domain={domain}
                  activeSubscription={account.activeSubscription}
                  t={t}
                />
                <RunnersBox
                  url={url}
                  runners={runners}
                  activeSubscription={account.activeSubscription}
                />
                <ThemeBox
                  url={url}
                  domain={domain}
                  theme={theme}
                  activeSubscription={account.activeSubscription}
                />
                <IgnoreRulesBox
                  url={url}
                  domain={domain}
                  websiteIgnore={websiteIgnore}
                  activeSubscription={account.activeSubscription}
                />
              </div>
            </PopoverContent>
          </>
        </Popover>

        <div className='flex place-items-center place-content-between'>
          <div className={'flex overflow-x-auto gap-x-1'}>
            <TopBlock
              count={pageIssueCount || pct}
              label={pageIssueCount === 1 ? transPage : transPages}
              formatter={formatter}
            />
            <TopBlock
              count={errorCount}
              label={errorCount === 1 ? transError : transErrors}
              formatter={formatter}
            />
            <TopBlock
              count={warningCount}
              label={warningCount === 1 ? transWarning : transWarnings}
              formatter={formatter}
            />
          </div>
          <Tooltip>
            <TooltipTrigger asChild>
              <div className='flex place-items-center gap-2 px-4'>
                <Label
                  htmlFor={`${domain}-profile-visible`}
                  className='uppercase'
                >
                  {t('public-visible')}
                </Label>
                <Switch
                  id={`${domain}-profile-visible`}
                  checked={profileVisible}
                  onClick={onChangeWebsiteVisiblity}
                />
              </div>
            </TooltipTrigger>
            <TooltipContent>
              <p>{t('public-details')}</p>
            </TooltipContent>
          </Tooltip>
        </div>

        <RenderAnalytics
          issuesInfo={issuesInfo}
          account={account}
          liveData={liveData}
          domain={domain}
          pageUrl={url}
          onlyLive={onlyLive}
          analyticsEnabled={analyticsEnabled}
        />

        <WebsiteTable
          issuesInfo={issuesInfo}
          liveData={liveData}
          activeSubscription={account.activeSubscription}
          url={url}
          handleMainClick={handleMainClick}
          onSortEvent={onSortEvent}
          storageless={storageless}
          domain={domain}
          blacklist={blacklist}
        />
      </li>
    </TooltipProvider>
  )
}
