import React, { SyntheticEvent, memo } from 'react'
import { printElement } from '@app/utils'
import type { PageIssue, Website } from '@app/types'
import { Link } from '@app/components/stateless/typo/link'
import { apiRoute } from '@app/configs/api-route'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { downloadFile } from '@app/lib/download-csv'
// import { FilterDropdown } from '../feed/filters'

interface InfoBarComponent {
  printable?: boolean
  website: Website
  download?: boolean // display the download button
  onToggleViewModeEvent?: (a?: any) => void
  pdfView?: boolean
}

const btnStyles =
  'border px-3 py-1.5 place-items-center flex text-sm rounded no-underline hover:border-blue-700 hover:text-blue-600 dark:border-gray-600'

export function InfoBarComponent({
  printable,
  website,
  download = false,
  onToggleViewModeEvent,
  pdfView,
}: InfoBarComponent) {
  const { t } = useTranslation('report-extra')
  // display in diff langauges

  if (!printable) {
    return null
  }

  const onPrint = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e?.preventDefault()
    printElement('headlessui-portal-root', website)
  }

  const onCSVClick = async (e?: SyntheticEvent<HTMLButtonElement>) => {
    e?.preventDefault()
    const data = website?.issues as PageIssue[] | undefined

    if (!data) {
      return
    }

    const parseData = new Array(data?.length || 0)

    let i = 0

    // TODO: streaming
    const cb =
      typeof queueMicrotask === 'function' ? queueMicrotask : setTimeout

    if (data?.length) {
      for (const value of data) {
        cb(() => {
          parseData[i] = {
            TYPE: value.typeCode === 1 ? 'Error' : 'Warning',
            MESSAGE: value.message,
            CODE: value.code,
            CONTEXT: value.context,
            SELECTOR: value.selector,
            RECURRENCE: value.recurrence,
          }

          i++
        })
      }
    }

    cb(async () => {
      try {
        const { unparse } = await import('papaparse')
        const d = unparse(parseData)

        downloadFile(d, `${website.url}-issues.csv`)
      } catch (e) {
        console.error(e)
      }
    })
  }

  return (
    <div className='flex py-2 space-x-2 border dark:border-gray-600 border-l-0 border-r-0 px-2'>
      <button className={btnStyles} onClick={onPrint} type={'button'}>
        {t('print')}
      </button>
      {download && website.url ? (
        <Link
          className={btnStyles}
          href={`${apiRoute}/report?q=${website.url || ''}&download=true`}
        >
          {t('download')}
        </Link>
      ) : null}
      {!pdfView ? (
        <button className={btnStyles} onClick={onCSVClick} type={'button'}>
          CSV
        </button>
      ) : null}
      {onToggleViewModeEvent ? (
        <button
          className={btnStyles}
          onClick={onToggleViewModeEvent}
          type={'button'}
        >
          {t('swap-views')}
        </button>
      ) : null}
      {/* <FilterDropdown open right /> */}
    </div>
  )
}

export const InfoBar = memo(InfoBarComponent)
