import { useMemo } from 'react'
import type { Translate } from 'next-translate'

// return strings possibly to be used in the dashboard
export const useTransDashboard = (t: Translate) => {
  return useMemo(() => {
    return {
      transIssue: t('issue'),
      transIssues: t('issues'),
      transError: t('error'),
      transErrors: t('errors'),
      transWarning: t('warning'),
      transWarnings: t('warnings'),
      transPage: t('page'),
      transPages: t('pages'),
    }
  }, [t])
}
