import gql from 'graphql-tag'

const CRAWL_COMPLETE_SUBSCRIPTION = gql`
  subscription crawlComplete {
    crawlComplete {
      domain
      accessScoreAverage
      shutdown
      duration
      issuesInfo {
        errorCount
        warningCount
        pageCount
        totalIssues
        noticeCount
      }
    }
  }
`

export { CRAWL_COMPLETE_SUBSCRIPTION }
