import { useTranslation } from '@app/lib/hooks/useTranslation'
import React from 'react'

export const SkipContent = () => {
  const { t } = useTranslation()

  return (
    <a
      className={
        '-top-10 left-[18%] px-4 py-2 rounded hidden absolute font-medium md:block focus:top-2 focus:bg-white focus:ring focus:z-10 dark:text-black'
      }
      href={'#main-content'}
    >
      {t('common:skip-nav')}
    </a>
  )
}
