import React from 'react'
import dynamic from 'next/dynamic'
import { NavbarMenu } from './navbar-menu'
import { MarketingNavMenu } from './marketing-nav-menu'

const AuthMenu = dynamic(
  () => import('../auth-menu').then((mod) => mod.AuthMenu),
  {
    loading: () => <p>...</p>,
  }
)

type NavbarInnerProps = {
  loading?: boolean
  authenticated?: boolean
  marketingLinks?: boolean
  title?: string
  authPage?: boolean
}

// the inner content of the navbar
export const NavbarInner = ({
  loading,
  authenticated,
  marketingLinks,
  authPage,
}: NavbarInnerProps) => {
  // loading content
  if (loading) {
    return <div className='h-15 w-15 bg-gray-300 rounded'></div>
  }

  // marketing links
  if (marketingLinks) {
    return <MarketingNavMenu authPage={authPage} />
  }

  // graphql required authentication menu
  if (authenticated && !marketingLinks) {
    return <AuthMenu authenticated={authenticated} />
  }

  return <NavbarMenu authenticated={authenticated} />
}
