import { getAPIRoute } from '@app/configs'

// get the dockerized endpoint in development for SSR since localhost does not work.
const baseUrl = getAPIRoute('api', true)

export const slugRoutes = (
  slug?: string[] | string,
  pageTo?: number,
  pageLimit?: number,
  pageSearch?: string
) => {
  const oneSlug = slug && slug.length >= 1
  const scoresUrl = new URL(`${baseUrl}/list/scores`)
  const categoriesUrl = new URL(`${baseUrl}/scores-categories`)
  const allCategories = oneSlug && Array.isArray(slug) && slug[0] === 'all'

  // remove the all route
  if (allCategories) {
    categoriesUrl.href = `${baseUrl}/list/scores-categories`
    categoriesUrl.searchParams.append('limit', '0')
  } else {
    if (oneSlug) {
      const s = !Number.isNaN(parseInt(slug[0], 10))
      scoresUrl.searchParams.append(s ? 'date' : 'label', slug[0])
      if (s) {
        categoriesUrl.searchParams.append('date', slug[0])
      }
    }

    const twoSlug = slug && slug.length >= 2

    if (twoSlug) {
      const s = !Number.isNaN(parseInt(slug[1], 10))
      scoresUrl.searchParams.append(s ? 'date' : 'label', slug[1])
      if (s) {
        categoriesUrl.searchParams.append('date', slug[1])
      }
    }
  }

  if (pageTo) {
    scoresUrl.searchParams.append('offset', String(pageTo))
    categoriesUrl.searchParams.append('offset', String(pageTo))
  }

  if (pageLimit) {
    scoresUrl.searchParams.append('limit', String(pageLimit))
    categoriesUrl.searchParams.append('limit', String(pageLimit))
  }

  if (pageSearch) {
    scoresUrl.searchParams.append('search', String(pageSearch))
    categoriesUrl.searchParams.append('search', String(pageSearch))
  }

  return {
    scoresUrl,
    categoriesUrl,
    allCategories,
  }
}
