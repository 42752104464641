import { memo } from 'react'
import { Menu } from '@headlessui/react'
import { Link } from '@app/components/stateless/typo/link'
import { classNames } from '@app/utils/classes'
import { btnStyles, getMoreMenuStyle } from './styles'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { useAuthContext } from '@app/components/providers/auth'
import { GrLock } from 'react-icons/gr'
import type { MoreOptionsProps } from '@app/types/website-more-options'
import { useMoreMenu } from './use-more-base'
import { ModalOptions } from '../enums'
import { MenuList } from '../../menu'
import { TooltipProvider } from '@app/@/components/ui/tooltip'

// Base of more options ...
const MoreOptionsBaseComponent = ({
  pageUrl,
  url,
  index,
  children,
  storageless,
  handleMainClick,
  // events
  // handleClose,
}: MoreOptionsProps) => {
  const { t } = useTranslation('dashboard')
  const { account } = useAuthContext()

  const [href, reportHref, downloadLink] = useMoreMenu(pageUrl ?? url)

  return (
    <MenuList id={`menu-appbar${index}`} more>
      <TooltipProvider>
        {storageless ? null : (
          <>
            <Menu.Item>
              {({ active }) => (
                <Link
                  href={href}
                  className={getMoreMenuStyle(active, true)}
                  shouldPrefetch={false}
                >
                  {t('sandbox-btn')}
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  href={reportHref}
                  className={getMoreMenuStyle(active, true)}
                >
                  {t('report-btn')}
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  className={getMoreMenuStyle(
                    active,
                    account.activeSubscription
                  )}
                  type='button'
                  disabled={!account.activeSubscription}
                  onClick={handleMainClick(
                    true,
                    ModalOptions.Analytics,
                    false,
                    url as string
                  )}
                >
                  {t('analytics-btn')}
                  {!account.activeSubscription ? (
                    <GrLock className='grIcon' />
                  ) : null}
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) =>
                !account.activeSubscription ? (
                  <Link
                    href={'/payments'}
                    className={classNames(
                      btnStyles,
                      'flex place-items-center gap-x-2 pointer-events-none'
                    )}
                  >
                    {t('export-data')}
                    <GrLock className='grIcon' />
                  </Link>
                ) : (
                  <Link
                    href={downloadLink}
                    className={getMoreMenuStyle(active, true)}
                  >
                    {t('export-data')}
                  </Link>
                )
              }
            </Menu.Item>
          </>
        )}
        {children}
      </TooltipProvider>
    </MenuList>
  )
}

export const MoreOptionsBase = memo(MoreOptionsBaseComponent)
