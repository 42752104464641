import { FC, PropsWithChildren } from 'react'
import { classNames } from '@app/utils/classes'

type MailProps = PropsWithChildren<{
  email: string
  subject?: string
  body?: string
  children?: any
  className?: any
}>

const Address: FC<PropsWithChildren<MailProps>> = ({
  email,
  subject,
  body,
  className,
  children,
}) => {
  return (
    <a
      className={classNames(
        `underline text-blue-600 dark:text-blue-500 hover:text-blue-400 dark:hover:text-blue-300`,
        className
      )}
      href={`mailto:${email}?subject=${
        subject ? encodeURIComponent(subject) : ''
      }&body=${body ? encodeURIComponent(body) : ''}`}
    >
      {children}
    </a>
  )
}

const Mailto = (props: MailProps) => (
  <address>
    <Address {...props}>{props.children}</Address>
  </address>
)

export { Mailto, Address }
