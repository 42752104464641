import gql from 'graphql-tag'

const ROLE_ADJUSTED_SUBSCRIPTION = gql`
  subscription roleAdjusted {
    roleAdjusted {
      id
      email
      loggedIn
      alertEnabled
      role
      emailConfirmed
      emailFilteredDates
      passwordRequired
      storageless
      aiDisabled
      imagesEnabled
      scanInfo {
        lastScanDate
        totalUptime
        creditedUptime
        lastCreditedUptime
      }
      usageAnchorDate
      analyticsEnabled
      reloadRequired
    }
  }
`

export { ROLE_ADJUSTED_SUBSCRIPTION }
