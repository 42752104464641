import React, { forwardRef, ForwardedRef } from 'react'
import { classNames } from '@app/utils/classes'
import { apiRoute } from '@app/configs/api-route'
import { NextComposed, BaseLink } from '@app/components/general/link'

export const MNLink = ({
  activeClassName = 'active',
  innerRef,
  className = '',
  as: asValue,
  href,
  shouldPrefetch,
  ...extra
}: BaseLink & { blue?: boolean }) => {
  const cname = classNames('text-base hover:underline', className || '')

  if (href && href.startsWith(apiRoute)) {
    return <a {...extra} className={cname} href={href} ref={innerRef} />
  }

  return (
    <NextComposed
      {...extra}
      className={cname}
      ref={innerRef}
      prefetch={shouldPrefetch !== false}
      as={href}
      href={href}
    />
  )
}

export const Link = forwardRef(function Link(
  props: BaseLink & { blue?: boolean },
  ref: ForwardedRef<HTMLAnchorElement>
) {
  return <MNLink {...props} innerRef={ref} />
})

export const LinkPrefetch = forwardRef(function Link(
  props: BaseLink & { blue?: boolean },
  ref: ForwardedRef<HTMLAnchorElement>
) {
  return <MNLink {...props} innerRef={ref} shouldPrefetch />
})
