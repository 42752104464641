import { useReducer } from 'react'
import { webhookMap, WebhookMap } from './webhooks'

enum EventsActionKind {
  UPDATE,
  RESET,
}

interface EventActions {
  type: EventsActionKind
  payload?: Partial<WebhookMap>
}

const reducer = (state: WebhookMap, action: EventActions) => {
  switch (action.type) {
    case EventsActionKind.UPDATE: {
      if (typeof action.payload === 'object') {
        return {
          ...state,
          ...action.payload,
          website: {
            __typename: undefined,
            ...state.website,
            ...action.payload.website,
          },
          crawl: {
            __typename: undefined,
            ...state.crawl,
            ...action.payload.crawl,
          },
          account: {
            __typename: undefined,
            ...state.account,
            ...action.payload.account,
          },
          signature: state.signature ?? action?.payload?.signature,
        }
      }
      return state
    }
    case EventsActionKind.RESET: {
      return webhookMap
    }
    default: {
      return state
    }
  }
}

// hook to bind the webhook data
export const useWebhooks = ({ webhooks }: { webhooks?: WebhookMap }) => {
  const [events, dispatch] = useReducer(reducer, webhooks ?? webhookMap)

  const handleUpdate = (payload: EventActions['payload']) =>
    dispatch({ type: EventsActionKind.UPDATE, payload })

  const handleReset = () => {
    dispatch({ type: EventsActionKind.RESET })
  }

  return {
    handleUpdate,
    handleReset,
    events,
  }
}
