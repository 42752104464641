import { memo } from 'react'
import { labelStyle, selectStyle } from '../cells/blocks/input-style'
import { InfoBlock } from '../cells'
import { FormControl } from '../form-control'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { inputContainerStyle } from './css'

const TLDInputWrapper = ({
  tld,
  url,
  domain,
  activeSubscription,
  onChange,
  translation,
}: {
  tld?: boolean
  url?: string
  domain?: string
  activeSubscription?: boolean
  onChange?(x: any): any
  translation?: string
}) => {
  const { t } = useTranslation(translation || 'dashboard')
  const labelId = `${domain || url}-tld-form`

  const textBase = t('tlds')

  return (
    <InfoBlock tipID={'tld-id'} toolTip={t('dashboard-inputs:tlds-tip')}>
      <div className={inputContainerStyle}>
        <FormControl
          htmlFor={labelId}
          visible
          disabled={!activeSubscription}
          className={labelStyle}
        >
          {textBase}
        </FormControl>
        <input
          checked={tld}
          type='checkbox'
          id={labelId}
          onChange={onChange}
          name={'tlds'}
          disabled={!activeSubscription}
          className={selectStyle(activeSubscription)}
        ></input>
      </div>
    </InfoBlock>
  )
}

export const TLDInput = memo(TLDInputWrapper)
