import React, {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useState,
} from 'react'
import Papa from 'papaparse'
import { Input } from '@app/@/components/ui/input'
import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
} from '@app/@/components/ui/drawer'
import { toast } from '@app/@/components/ui/use-toast'
import { Button } from '@app/@/components/ui/button'
import { Checkbox } from '@app/@/components/ui/checkbox'

import { Edit2Icon, Trash2Icon } from 'lucide-react'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@app/@/components/ui/tooltip'
import { useTranslation } from '@app/lib/hooks/useTranslation'
// import { scrollBarStyles } from '@/styles/scrollbar';

// default issue data from issues CSV
const defaultIssues = {
  DOC_TITLE: '',
  DOMAIN: '',
  URL: '',
  ISSUES: '',
  url: '',
  pageUrl: '',
  domain: '',
  uri: '',
}

// upload website lisdt
export const WebsitesUpload = ({
  websiteList,
  setWebsiteList,
}: {
  websiteList?: string[]
  setWebsiteList: Dispatch<SetStateAction<string[]>>
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedItems, setSelectedItems] = useState<string[]>([])
  const { t } = useTranslation('data-parse')

  const onParseCSV = useCallback(
    async (e: React.SyntheticEvent<HTMLInputElement>) => {
      e.preventDefault()
      const target = e.nativeEvent.target as HTMLInputElement

      const files = target.files // Directly accessing the files

      if (!files || (files && !files.length)) {
        return toast({
          title: 'Invalid CSV',
          variant: 'destructive',
        })
      }

      const feed: string[] = []

      for (let i = 0; i < files.length; i++) {
        const file: File = files[i]

        if (!file) {
          continue
        }

        // prevent excess 1k items
        if (feed?.length >= 1000) {
          break
        }

        // csv
        const isCSV = file.type === 'text/csv'
        // json
        const isJson = !isCSV && file.type === 'application/json'

        const fileNotSupport = !isCSV && !isJson

        // TODO: alert file not supported in snackbar
        if (fileNotSupport) {
          continue
        }

        // CSV FROM A11YWATCH SAFE
        if (isCSV) {
          setLoading(true)

          Papa.parse(file, {
            worker: true,
            header: true,
            step: function (row) {
              const {
                DOMAIN,
                URL: _URL,
                ISSUES,
                url,
                pageUrl,
                domain,
                uri,
              } = (row.data as typeof defaultIssues) ?? defaultIssues

              if (DOMAIN) {
                let issues = null

                if (ISSUES) {
                  try {
                    issues = JSON.parse(ISSUES)
                    issues = 'data' in issues ? issues.data : issues
                  } catch (e) {
                    console.error(e)
                  }
                }
              }

              let websiteUrl = _URL || DOMAIN || url || pageUrl || domain || uri

              if (
                !websiteUrl &&
                row.data &&
                typeof row.data === 'object' &&
                !Array.isArray(row.data)
              ) {
                for (const vals of Object.values(row.data)) {
                  if (
                    vals &&
                    (vals.startsWith('http://') || vals.startsWith('https://'))
                  ) {
                    websiteUrl = vals
                  } else if (vals && !['false', 'true'].includes(vals)) {
                    try {
                      const _u = new URL(`https://${vals}` as string)

                      if (_u.href) {
                        websiteUrl = _u.href
                      }
                    } catch (e) {}
                  }
                }
              }

              if (websiteUrl && feed?.length <= 1000) {
                feed.push(websiteUrl)
              }
            },
            complete: function () {
              setLoading(false)
              setWebsiteList(feed)

              return toast({
                title: 'Upload complete',
              })
            },
          })
        }

        // todo: file reader stream chunks into WORKER
        else if (isJson) {
          // if the file is small process as chunks
          const smallFile = file.size < 4459588
          setLoading(true)
          const decoder = new TextDecoder('utf-8')

          // if the file under 10mb process instantly
          if (smallFile) {
            const reader = new FileReader()

            reader.addEventListener('load', (event) => {
              const result = event.target && event.target.result

              if (result) {
                let data = null

                try {
                  data = JSON.parse(
                    decoder.decode(new Uint8Array(result as ArrayBuffer))
                  )
                } catch (e) {
                  console.error(e)
                }

                if (data) {
                  const chunkItem = 'data' in data ? data.data : data

                  if (chunkItem && Array.isArray(chunkItem)) {
                    for (const item of chunkItem) {
                      if (feed.length >= 1000) {
                        break
                      }
                      const urlTarget =
                        item.pageUrl ||
                        item.url ||
                        item.domain ||
                        item.URL ||
                        item.DOMAIN ||
                        item.uri

                      if (urlTarget) {
                        feed.push(urlTarget)
                      }
                    }
                  }
                }
              }
            })

            reader.readAsArrayBuffer(file)
          } else {
            // stream to worker todo
            const readableStream = file.stream().getReader()

            let jsonText = ''
            let previousChunkIncomplete = false

            while (true) {
              const { value, done } = await readableStream.read()

              // exit the loop
              if (done) {
                break
              }

              const chunkText = decoder.decode(value, { stream: true })

              // chunk did not complete
              if (previousChunkIncomplete) {
                jsonText += chunkText
                let nextObject

                try {
                  nextObject = JSON.parse(jsonText)
                  nextObject =
                    'data' in nextObject ? nextObject.data : nextObject

                  for (const item of nextObject) {
                    if (feed.length >= 1000) {
                      break
                    }

                    const urlTarget =
                      item.pageUrl ||
                      item.url ||
                      item.domain ||
                      item.URL ||
                      item.DOMAIN ||
                      item.uri

                    if (urlTarget) {
                      feed.push(urlTarget)
                    }
                  }

                  jsonText = ''
                  previousChunkIncomplete = false
                } catch (e) {
                  continue
                }
              } else {
                try {
                  const chunkJson = JSON.parse(chunkText)
                  const chunkItem =
                    'data' in chunkJson ? chunkJson.data : chunkJson

                  for (const item of chunkItem) {
                    if (!item.pageUrl && item.url) {
                      item.pageUrl = item.url
                    }
                    const urlTarget =
                      item.pageUrl ||
                      item.domain ||
                      item.URL ||
                      item.DOMAIN ||
                      item.uri

                    if (urlTarget && feed.length <= 1000) {
                      feed.push(urlTarget)
                    }
                  }
                } catch (e) {
                  previousChunkIncomplete = true
                  jsonText = chunkText
                  continue
                }
              }
            }
          }

          setLoading(false)
          setWebsiteList(feed)
        }
      }
    },
    [setLoading, setWebsiteList]
  )

  const removeItemEvent = (item: string) =>
    setWebsiteList((x) => x.filter((text) => text !== item))

  const removeSelectedItems = () => {
    setWebsiteList((list) =>
      list.filter((item) => !selectedItems.includes(item))
    )
    setSelectedItems([])
  }

  const toggleSelection = (item: string) => {
    setSelectedItems((prevSelected) =>
      prevSelected.includes(item)
        ? prevSelected.filter((i) => i !== item)
        : [...prevSelected, item]
    )
  }

  const selectAll = (isChecked: boolean) => {
    if (isChecked) {
      setSelectedItems(websiteList || [])
    } else {
      setSelectedItems([])
    }
  }

  const onSelectAll = (checked: boolean) => selectAll(checked)

  return (
    <Drawer>
      <div className='flex items-center gap-3 py-2 w-full'>
        <label className='text-xs font-mono' htmlFor='website-upload'>
          CSV | JSON
        </label>
        <div className='flex-1 h-[2.15rem] py-4 flex place-items-center place-content-center relative'>
          <Input
            type='file'
            id='website-upload'
            multiple
            accept='.json, .csv'
            disabled={loading}
            onChange={onParseCSV}
          />
        </div>
      </div>
      <Tooltip>
        <TooltipTrigger asChild>
          <DrawerTrigger asChild>
            <Button type='button' variant={'ghost'}>
              <Edit2Icon className='text-sm w-4 -h-4' />
              <span className='sr-only'>{t('dashboard:view')}</span>
            </Button>
          </DrawerTrigger>
        </TooltipTrigger>
        <TooltipContent>
          <p>{t('dashboard:edit-import-list')}</p>
        </TooltipContent>
      </Tooltip>
      <DrawerContent className='px-4'>
        <div className={`max-h-[92vh] overflow-auto`}>
          {websiteList && websiteList?.length ? (
            <>
              <div>
                <div>
                  <h3>URLs: {websiteList.length}</h3>
                </div>
                <div className='flex items-center pt-3 pb-2 border-b pr-2'>
                  <label className='flex items-center gap-3'>
                    <Checkbox onCheckedChange={onSelectAll as any} />
                    {t('select-all')}
                  </label>
                  <Button
                    variant={'ghost'}
                    size={'sm'}
                    className={`ml-auto ${selectedItems.length > 0 ? 'visible' : 'invisible'}`}
                    onClick={removeSelectedItems}
                    type='button'
                  >
                    {t('remove-selected')}
                  </Button>
                </div>
              </div>

              {websiteList?.length ? (
                <ul className='text-sm divide-y truncate'>
                  {websiteList.map((item) => (
                    <li
                      key={item}
                      className='flex place-items-center gap-3 place-content-between'
                    >
                      <div className='flex gap-3 items-center'>
                        <label className='flex items-center gap-3'>
                          <Checkbox
                            checked={selectedItems.includes(item)}
                            onClick={() => toggleSelection(item)}
                            title={t('select-item-title')}
                          />
                          {item}
                        </label>
                      </div>
                      <Button
                        variant={'ghost'}
                        className='text-xs text-red-600'
                        size={'icon'}
                        type='button'
                        onClick={() => removeItemEvent(item)}
                      >
                        <Trash2Icon className='text-sm w-4 h-4' />
                        <span className='sr-only'>{t('dashboard:delete')}</span>
                      </Button>
                    </li>
                  ))}
                </ul>
              ) : null}
            </>
          ) : (
            <div className='pb-5'>{t('dashboard:empty-analytics')}</div>
          )}
        </div>
      </DrawerContent>
    </Drawer>
  )
}
