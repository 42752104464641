import {
  TooltipProvider,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@app/@/components/ui/tooltip'
import { classNames } from '@app/utils'
import { Translate } from 'next-translate'

// live data button
export const LiveDataButton = ({
  onToggleLiveData,
  blockLive,
  activeSubscription,
  t,
}: {
  t: Translate
  onToggleLiveData: any
  blockLive?: any
  activeSubscription?: boolean
}) => {
  const text = t(blockLive ? 'live-switch-b' : 'all-switch')

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger
          className={classNames(
            'h-2 w-2 rounded-full p-1.5 ring',
            !blockLive
              ? 'bg-green-600 hover:bg-green-700'
              : 'bg-gray-500 hover:bg-green-400'
          )}
          onClick={onToggleLiveData}
          type={'button'}
          disabled={!activeSubscription}
        >
          <span className='sr-only'>{text}</span>
        </TooltipTrigger>
        <TooltipContent>
          <p>{text}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
