export const multiplier = (n: number, x: number = 30) => n * x

// $0.5 dollars
export const BASE = multiplier(10000, 3)

// HOBBY CREDITS
export const BASE_C1 = BASE * 20

// PRO LOW
export const BASE_L1 = multiplier(150000)
export const BASE_L2 = multiplier(300000)
export const BASE_L3 = multiplier(700000)
export const BASE_L4 = multiplier(1000000)
export const BASE_L5 = multiplier(2000000)
// PRO HIGH
export const BASE_H1 = multiplier(2500000)
export const BASE_H2 = multiplier(5000000)
export const BASE_H3 = multiplier(10000000)
export const BASE_H4 = multiplier(17500000)
export const BASE_H5 = multiplier(30000000)
// YEARLY
export const BASE_L1_Y = BASE_L1 * 12
export const BASE_L2_Y = BASE_L2 * 12
export const BASE_L3_Y = BASE_L3 * 12
export const BASE_L4_Y = BASE_L4 * 12
export const BASE_L5_Y = BASE_L5 * 12
export const BASE_H1_Y = BASE_H1 * 12
export const BASE_H2_Y = BASE_H2 * 12
export const BASE_H3_Y = BASE_H3 * 12
export const BASE_H4_Y = BASE_H4 * 12
export const BASE_H5_Y = BASE_H5 * 12
export const BASE_C1_Y = BASE_C1 * 12

// determine plan usage by role limits to ms, used across multiple services. The values are static for transparency
export const getUsageLimitsMs = (role: number, yearly?: boolean): number => {
  let usage = 0

  switch (role) {
    case 0: {
      usage = BASE
      break
    }
    case 11: {
      usage = yearly ? BASE_C1_Y : BASE_C1
      break
    }
    // normal plans
    case 1: {
      usage = yearly ? BASE_L1_Y : BASE_L1
      break
    }
    case 2: {
      usage = yearly ? BASE_L2_Y : BASE_L2
      break
    }
    case 3: {
      usage = yearly ? BASE_L3_Y : BASE_L3
      break
    }
    case 4: {
      usage = yearly ? BASE_L4_Y : BASE_L4
      break
    }
    case 5: {
      usage = yearly ? BASE_L5_Y : BASE_L5
      break
    }
    // high tier plans
    case 6: {
      usage = yearly ? BASE_H1_Y : BASE_H1
      break
    }
    case 7: {
      usage = yearly ? BASE_H2_Y : BASE_H2
      break
    }
    case 8: {
      usage = yearly ? BASE_H3_Y : BASE_H3
      break
    }
    case 9: {
      usage = yearly ? BASE_H4_Y : BASE_H4
      break
    }
    case 10: {
      usage = yearly ? BASE_H5_Y : BASE_H5
      break
    }
    // account blocked!
    case 11: {
      usage = 0
      break
    }
    default: {
      usage = BASE
      break
    }
  }

  return usage
}
