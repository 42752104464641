import React, { useState } from 'react'
import { FormControl } from '../form-control'
import { TextField } from '../text-field'
import { Select } from './select'
import { WAIT_OPTIONS, Standard } from './action-enums'

export type StandardKeys = keyof typeof Standard

const standards = Object.values(Standard).filter(
  (value) => typeof value === 'string'
)

const waitOptions = Object.values(WAIT_OPTIONS).filter(
  (value) => typeof value === 'string'
)

export type WaitOptionKeys = keyof typeof WAIT_OPTIONS

export interface InputProps {
  onStandardChange(event: any): void
  standard?: StandardKeys
  path: string
  // private - recreate defaults
  defaultSelector?: string
  defaultAction?: string
  defaultWaitfor?: string
  defaultValue?: string
}

// determine if the element select form should appear
const elementSelectForm = (value: string) => {
  let canSelect = false

  switch (value) {
    case Standard[0]: {
      canSelect = true
      break
    }
    case Standard[1]: {
      canSelect = true
      break
    }
    case Standard[2]: {
      canSelect = true
      break
    }
    case Standard[3]: {
      canSelect = true
      break
    }
    case Standard[4]: {
      canSelect = true
      break
    }
    case Standard[5]: {
      canSelect = true
      break
    }
    default: {
      canSelect = false
      break
    }
  }

  return canSelect
}

// actions input to display
export const ActionsSelectInput = ({
  onStandardChange,
  path,
  defaultSelector,
  defaultAction,
  defaultWaitfor,
  defaultValue,
}: InputProps) => {
  const [action, setAction] = useState<StandardKeys>(
    (defaultAction || standards[0]) as StandardKeys
  )
  const [waitFor, setWaitFor] = useState<WaitOptionKeys>(
    (defaultWaitfor || waitOptions[0]) as WaitOptionKeys
  )

  const [selector, setSelector] = useState<string>(defaultSelector || '')
  const [selectorValue, setSelectorValue] = useState<string>(defaultValue || '')

  const constructAction = ({
    nextValue,
    nextAction,
    nextWaitFor,
    nextSelector,
  }: {
    nextSelector?: string
    nextValue?: string
    nextAction?: string
    nextWaitFor?: string
  }) => {
    // current values
    const _value = nextValue ?? selectorValue
    const _action = nextAction ?? action
    const _waitFor = nextWaitFor ?? waitFor
    const _nextSelect = nextSelector ?? selector

    const actionName = _action.replace(/\_/g, ' ').toLowerCase()

    const val =
      _action === Standard[2] || _action === Standard[0] ? ` ${_value}` : ''

    const waitVal =
      _action === Standard[0]
        ? ` ${_waitFor && _waitFor.replace(/\_/g, ' ').toLowerCase()}`
        : ''

    const sel = _nextSelect ? ` ${_nextSelect}` : ''
    const newValue = `${actionName}${sel}${val}${waitVal}`

    onStandardChange(newValue)
  }

  const onActionSelect = (e: React.ChangeEvent<any>) => {
    setAction(e.target.value)
    constructAction({ nextAction: e.target.value })
  }

  const onElementChangeEvent = (e: React.ChangeEvent<any>) => {
    setSelector(e.target.value)
    constructAction({ nextSelector: e.target.value })
  }

  const onWaitForChangeEvent = (e: React.ChangeEvent<any>) => {
    setWaitFor(e.target.value)
    constructAction({ nextWaitFor: e.target.value })
  }

  const onSelectorValueEvent = (e: React.ChangeEvent<any>) => {
    setSelectorValue(e.target.value)
    constructAction({ nextValue: e.target.value })
  }

  const actionId = `${path}-action-select-outlined-label`
  const waitForId = `${path}-wait-action-select-outlined-label`

  return (
    <div className={'flex gap-3 flex-wrap place-items-center max-h-[50vh]'}>
      <>
        <FormControl htmlFor={actionId}>Action</FormControl>
        <Select
          id={actionId}
          value={action}
          onChange={onActionSelect}
          data={standards}
          className='border px-2 min-h-[40px] hover:border-blue-600'
        />
      </>
      {elementSelectForm(action) ? (
        <>
          <FormControl htmlFor='element_selector'>Selector</FormControl>
          <TextField
            underline
            id='element_selector'
            value={selector}
            placeholder={'Element ex: #some_id'}
            onChange={onElementChangeEvent}
            required
          />
        </>
      ) : null}
      {action === Standard[2] ? (
        <>
          <FormControl htmlFor='element_value'>Value</FormControl>
          <TextField
            color='secondary'
            value={selectorValue}
            placeholder={'Element value'}
            onChange={onSelectorValueEvent}
            underline
            required
          />
        </>
      ) : null}
      {action === Standard[0] ? (
        <>
          <FormControl htmlFor={waitForId}>Options</FormControl>
          <Select
            id={waitForId}
            value={waitFor}
            onChange={onWaitForChangeEvent}
            data={waitOptions}
            className='border px-2 min-h-[40px] hover:border-blue-600'
          />
        </>
      ) : null}
    </div>
  )
}
