import React, { memo, useCallback, useEffect, useState } from 'react'
import { useWebsiteContext } from '@app/components/providers/website'
import { CrawlLimitBoxInput } from '../../inputs/crawl-limit-input'

export const CrawlLimitBoxWrapper = ({
  crawlLimit: delay,
  url,
  activeSubscription,
}: {
  url: string
  crawlLimit?: number
  activeSubscription?: boolean
}) => {
  const [crawlLimit, setDelay] = useState<number | undefined>(delay)
  const { updateWebsite } = useWebsiteContext()

  const onChangeCrawlLimit = (e: React.ChangeEvent<any>) =>
    setDelay(Number(e?.target?.value))

  const performMutation = useCallback(async () => {
    if (crawlLimit !== delay) {
      try {
        await updateWebsite({
          variables: {
            url,
            crawlLimit,
          },
        })
      } catch (e) {
        console.error(e)
      }
    }
  }, [crawlLimit, delay, updateWebsite, url])

  useEffect(() => {
    if (crawlLimit === delay) {
      return
    }

    const debounce = setTimeout(performMutation, 2000)

    return () => {
      clearTimeout(debounce)
      if (crawlLimit === delay) {
        return
      }
      requestAnimationFrame(performMutation)
    }
  }, [crawlLimit, delay, performMutation])

  return (
    <CrawlLimitBoxInput
      onChange={onChangeCrawlLimit}
      value={crawlLimit}
      activeSubscription={activeSubscription}
      url={url}
    />
  )
}

export const CrawlLimitBox = memo(CrawlLimitBoxWrapper)
