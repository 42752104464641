import { classNames } from '@app/utils/classes'

type NavStyles = { position?: string; className?: string }

export const getNavbarStyles = ({ position, className }: NavStyles) => {
  return classNames(
    position === 'static' ? '' : 'bg-[inherit]',
    className ?? '',
    'flex-1 space-x-2',
    position === 'fixed' ? 'fixed left-[13%] md:left-[250px] right-0' : ''
  )
}
