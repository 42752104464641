import { FC, memo } from 'react'
import { useIssue } from '@app/data/external/issues/issue'
import { InnerWrapper } from '../../list-wrapper'
import { LazyIssues } from '@app/components/feed/lazy-issues'
import { PageIssue } from '@app/types'

// [NETWORK] issues over network with lazy rendering issues
const FetchIssueWrapper: FC<{ url?: string }> = (props) => {
  const { issue, loading } = useIssue(props.url)
  const issueSource = issue?.issues ?? []

  return (
    <InnerWrapper {...props} data={issueSource.length} loading={loading}>
      <LazyIssues issues={issueSource} paging />
    </InnerWrapper>
  )
}

const WebsiteIssuePure: FC<{ issues?: PageIssue[] }> = (props) => {
  const issueSource = props?.issues ?? []

  return (
    <InnerWrapper data={issueSource.length} loading={false}>
      <LazyIssues issues={issueSource} paging />
    </InnerWrapper>
  )
}

export const FetchIssue = memo(FetchIssueWrapper)
export const WebsiteIssue = memo(WebsiteIssuePure)
