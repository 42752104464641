import { ApolloError } from '@apollo/client'
import type { QueryOptions } from '@apollo/client'
import { SortDirection } from '@app/components/general/dashboard-website-list'
import { SortMap } from '@app/data/external/website/websites'

// default provider data for application.
export const sharedWebsiteDefaults = {
  feedOpen: false,
  loading: false,
  mutatationLoading: false as boolean,
  data: [],
  error: undefined as ApolloError | undefined,
  subscriptionData: {
    issueSubData: null,
  },
  activeCrawls: {},
  // issues only TODO: MOVE TO SEPERATE PROVIDER
  actionsData: [],
  actionsDataLoading: false,
  // visuals
  // status
  refetch: (_: any): Promise<any> => {
    return _
  },
  addWebsite: (_: any, _showToast?: boolean): Promise<any> => {
    return _
  },
  removeWebsite: (_: any): Promise<any> => {
    return _
  },
  removePage: (_: any): Promise<any> => {
    return _
  },
  crawlWebsite: (_: any): Promise<any> => {
    return _
  },
  updateWebsite: (_: any): Promise<any> => {
    return _
  },
  setIssueFeedContent: (_open: boolean): void => {
    return
  },
  singlePageScan: (_: any): Promise<any> => {
    return _
  },
  onLoadMoreWebsites: (_: any): Promise<any> => {
    return _
  },
  onLoadMoreActions: (_: any): Promise<any> => {
    return _
  },
  forceUpdate: (): void => {
    return
  },
  removeActiveCrawl: (_: string): void => {
    return
  },
  sortDirections: new Map() as SortMap,
  onSortEvent: (_: string, __?: SortDirection, _sortKey?: string): void => {
    return
  },
  getWebsites: (
    _options?:
      | QueryOptions<{
          filter: string
          customHeaders: any
          url: string
          limit: number
          offset: number
        }>
      | undefined
  ) => {
    return
  },
}
