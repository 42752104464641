import { forwardRef, Ref } from 'react'
import { Button } from '@app/components/general/buttons'
import { classNames } from '@app/utils/classes'
import {
  GrClose,
  GrFormNextLink,
  GrFormPreviousLink,
  GrSearch,
} from 'react-icons/gr'
import type { Translate } from 'next-translate'

// paging search props
type PagingProps = {
  searchVisible?: boolean
  source: any[]
  blocked: boolean
  loading: boolean
  issueIndex: number
  pages?: boolean
  onLoadEvent(x: any): any
  onPrevSelect(x: any): any
  onSearchSubmit(x: any): any
  onSearchEvent(x: any): any
  t: Translate
  visiblity?: boolean
}

// TODO: translate Prev, Next
const PagingSearch = forwardRef(
  (props: PagingProps, searchRef: Ref<HTMLInputElement>) => {
    const {
      searchVisible,
      source,
      blocked,
      issueIndex,
      loading,
      onLoadEvent,
      onPrevSelect,
      onSearchSubmit,
      onSearchEvent,
      pages,
      t,
      visiblity = true,
    } = props

    const { id, searchName, labelName } = pages
      ? {
          id: 'pages-search',
          searchName: 'q-pages',
          labelName: `${t('search')} ${t('pages').toLowerCase()}`,
        }
      : {
          id: 'issue-search',
          searchName: 'q-issues',
          labelName: `${t('search')} ${t('issues').toLowerCase()}`,
        }

    return (
      <div
        className={classNames(
          'flex place-items-center place-content-between py-2',
          visiblity ? '' : 'hidden'
        )}
      >
        <div className={'flex place-items-center'}>
          <form
            className={'px-2 flex place-items-center'}
            onSubmit={onSearchSubmit}
            noValidate
          >
            <span className='relative place-items-center flex'>
              <span className='absolute pointer-events-none ltr:ml-3 rtl:mr-3 text-sm text-gray-600 dark:text-gray-300'>
                {searchVisible ? (
                  <GrClose className='grIcon' />
                ) : (
                  <GrSearch className='grIcon' />
                )}
              </span>
            </span>
            <label htmlFor={id} className='sr-only'>
              {labelName}
            </label>
            <input
              type={'search'}
              placeholder={labelName}
              id={id}
              name={searchName}
              aria-hidden={!visiblity ? true : undefined}
              maxLength={300}
              className={
                'text-lg ltr:pl-8 rtl:pr-8 px-3 py-1 border rounded-full max-w-[64vw] md:max-w-auto md:max-w-auto dark:border-gray-700 bg-white dark:bg-black'
              }
              onChange={onSearchEvent}
              ref={searchRef}
            />
          </form>
        </div>
        <div
          className={classNames(
            source && source.length >= 1 ? '' : 'invisible',
            `flex place-items-center place-content-end px-1`
          )}
          aria-hidden={!source || (source && !source?.length)}
        >
          <Button
            iconButton
            onClick={onPrevSelect}
            className={classNames(
              'hover:text-blue-700',
              issueIndex ? 'visible' : 'invisible'
            )}
            border={false}
            aria-hidden={!issueIndex}
          >
            <>
              <span className='sr-only'>{t('prev-page')}</span>
              <GrFormPreviousLink className='grIcon rtl:rotate-180' />
            </>
          </Button>
          <Button
            iconButton
            disabled={loading}
            border={false}
            onClick={onLoadEvent}
            className={classNames(
              'hover:text-blue-700',
              blocked ? 'invisible' : 'visible'
            )}
            aria-hidden={blocked}
          >
            <>
              <span className='sr-only'>{t('next-page')}</span>
              <GrFormNextLink className='grIcon rtl:rotate-180' />
            </>
          </Button>
        </div>
      </div>
    )
  }
)

PagingSearch.displayName = 'PagingSearch'

export { PagingSearch }
