import { AppManager, UserManager } from '@app/managers'
import { getAPIRoute } from '@app/configs'

const apiRoute = getAPIRoute('api')

// DEMO endpoints
const scanEndpoint = `${apiRoute}/scan-simple`
const scanEndpointPDF = `${apiRoute}/scan-simple-pdf`
// AUTHED
const scanAuthedEndpoint = `${apiRoute}/scan`
const scanEndpointPDFAuthed = `${apiRoute}/scan-pdf`

// SCOPE WEBSITE DATA PER ROUTE (ALL, ISSUES, PAGES)
export const scanWebsite = async (
  websiteUrl: string,
  authed?: boolean,
  html?: string,
  standard?: string,
  language?: string,
  runners?: string[] | ['axe', 'htmlcs']
) => {
  let request

  try {
    request = await fetch(authed ? scanAuthedEndpoint : scanEndpoint, {
      method: 'POST',
      body: JSON.stringify({
        websiteUrl: websiteUrl
          ? encodeURIComponent(websiteUrl.replaceAll(' ', ''))
          : undefined,
        html,
        standard,
        language,
        runners,
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: UserManager.token,
      },
    })
  } catch (e) {
    let message = 'Unknown Error'
    if (e instanceof Error) {
      message = e.message
    }
    return AppManager.toggleSnack(true, message, 'error')
  }

  // rate limit custom message on scan
  if (request && request.status === 429) {
    return AppManager.toggleSnack(
      true,
      'RATE EXCEEDED: Try again later or upgrade your account.',
      'error',
      false,
      true
    )
  }

  if (request && request?.ok) {
    try {
      return await request.json()
    } catch (e) {
      console.error(e)
      AppManager.toggleSnack(true, e, 'error')
    }
  }
}

// PDF SCANNING
export const scanPDF = async (formData: FormData) => {
  let request

  try {
    request = await fetch(
      !!UserManager.token ? scanEndpointPDFAuthed : scanEndpointPDF,
      {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: UserManager.token,
        },
      }
    )
  } catch (e) {
    let message = 'Unknown Error'
    if (e instanceof Error) {
      message = e.message
    }
    return AppManager.toggleSnack(true, message, 'error')
  }

  // rate limit custom message on scan
  if (request && request.status === 429) {
    return AppManager.toggleSnack(
      true,
      'RATE EXCEEDED: Try again later or upgrade your account.',
      'error',
      false,
      true
    )
  }

  if (request && request?.ok) {
    try {
      return await request.json()
    } catch (e) {
      console.error(e)
      AppManager.toggleSnack(true, e, 'error')
    }
  }
}
