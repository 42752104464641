import {
  forwardRef,
  memo,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react'
import { useAnalyticsData } from '@app/data/external/analytics/analytics'
import { InnerWrapper } from '../../list-wrapper'
import { AnalyticsList } from './analytics-list'
import type { Analytic } from '@app/types'
import { PagingSearch } from './paging-search'
import {
  dataListStyle,
  pagingListStyle,
} from '@app/components/general/cells/card/head-style'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { useWebsiteContext } from '@app/components/providers/website'
import { useWasmContext } from '@app/components/providers'
import { usePaging } from '@app/data/external/analytics/use-paging'
import { useSortDirection } from './use-sort-direction'

type AnalyticsPagingProps = {
  pageUrl?: string
  liveData?: Analytic[]
  open?: boolean
  extraActive?: boolean | string
  domain?: string
  blacklist?: string[]
  filterActive?: 'errors' | 'warnings'
  blockLive?: boolean
}

const RenderInnerAnalyticsWrapper = (props: AnalyticsPagingProps, ref: any) => {
  const {
    liveData,
    pageUrl,
    open: defaultOpen,
    extraActive,
    domain,
    blacklist,
    filterActive,
    blockLive,
  } = props

  const { removePage, updateWebsite } = useWebsiteContext()
  const { feed } = useWasmContext()
  const { t } = useTranslation('dashboard')
  const [issueVisible, setIssueVisible] = useState<boolean>(!!defaultOpen)

  const variables = useSortDirection(pageUrl)

  const {
    data,
    search,
    loading,
    searchVisible,
    onLoadMore,
    onSearchEvent,
    refetch,
  } = useAnalyticsData(pageUrl, false, false, filterActive, variables)

  const {
    issueSource,
    searchRef,
    issueIndex,
    issueList,
    onPrevSelect,
    stats,
    onLoadEvent,
    blocked,
    onSearchSubmit,
    setSelectedIndex,
    selectedIndex,
    offsetTracking,
    setIndex,
  } = usePaging({
    liveData,
    search,
    filterActive,
    data,
    onLoadMore,
    blockLive,
    pageUrl,
  })

  const emptyData = !!data?.length

  useImperativeHandle(
    ref,
    () => {
      return {
        setSelectedIndex,
        setIndex,
        refetch: async () => {
          if (!emptyData) {
            try {
              await refetch()
            } catch (e) {
              console.error(e)
            }
          }
        },
      }
    },
    [setSelectedIndex, setIndex, refetch, emptyData]
  )

  // remove page item
  const onRemovePress = useCallback(
    async (u: string) => {
      if (domain) {
        feed && feed.remove_page && feed.remove_page(domain, u)

        removePage &&
          (await removePage({
            variables: {
              domain: domain,
              url: u,
            },
          }))
        refetch &&
          (await refetch({
            url: pageUrl,
            offset: offsetTracking.current.last,
          }))
      }
    },
    [refetch, domain, pageUrl, feed, offsetTracking, removePage]
  )

  // remove page item
  const onBlacklistEvent = useCallback(
    async (u: string) => {
      let pname = u

      try {
        pname = new URL(u).pathname
      } catch (e) {
        console.error(e)
      }
      // path does not exist
      if (!pname) {
        return
      }
      const blist = blacklist ?? []
      const inBlackList = blist?.some((item) => item === pname)
      const nextList = !inBlackList
        ? [...blist, pname]
        : blist?.filter((item) => item !== pname)

      if (domain) {
        updateWebsite &&
          (await updateWebsite({
            variables: {
              url: pageUrl,
              blacklist: nextList,
            },
          }))
        refetch &&
          (await refetch({
            url: pageUrl,
            offset: offsetTracking.current.last,
          }))
      }
    },
    [updateWebsite, refetch, pageUrl, offsetTracking, blacklist, domain]
  )

  return (
    <div className='flex flex-col place-content-around'>
      <div className={dataListStyle}>
        <InnerWrapper data={issueSource.length} loading={loading}>
          <ul className={issueVisible ? 'py-0.5' : pagingListStyle}>
            {issueList.map((page, index) => (
              <AnalyticsList
                key={page?._id || page.pageUrl}
                index={index}
                open={defaultOpen}
                totalErrors={stats.errorCount}
                extraActive={extraActive}
                selectedIndex={selectedIndex}
                blacklist={blacklist}
                setSelectedIndex={setSelectedIndex}
                onBlacklistEvent={onBlacklistEvent}
                onRemovePress={onRemovePress}
                setIssueVisible={setIssueVisible}
                t={t}
                {...page}
              />
            ))}
          </ul>
        </InnerWrapper>
      </div>
      <PagingSearch
        searchVisible={searchVisible}
        onLoadEvent={onLoadEvent}
        onPrevSelect={onPrevSelect}
        onSearchEvent={onSearchEvent}
        onSearchSubmit={onSearchSubmit}
        loading={loading}
        blocked={blocked}
        ref={searchRef}
        issueIndex={issueIndex}
        source={issueSource}
        visiblity={!issueVisible}
        t={t}
      />
    </div>
  )
}

export const RenderInnerAnalyticsPaging = memo(
  forwardRef(RenderInnerAnalyticsWrapper)
)
