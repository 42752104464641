// get a color for the method type
export const methodColor = (method: string, t: 'color' | 'border') => {
  let c = ''

  switch (method) {
    case 'POST':
      {
        c = t === 'color' ? 'text-green-700' : 'border-green-700'
      }
      break
    case 'GET':
      {
        c = t === 'color' ? 'text-blue-700' : 'border-blue-700'
      }
      break
    case 'PUT':
      {
        c = t === 'color' ? 'text-yellow-700' : 'border-yellow-700'
      }
      break
    case 'DELETE':
      {
        c = t === 'color' ? 'text-red-700' : 'border-red-700'
      }
      break
    default:
      {
        c = t === 'color' ? 'text-blue-700' : 'border-blue-700'
      }
      break
  }

  return c
}
