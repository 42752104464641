import '@app/stylesheets/main.css'
import '@app/stylesheets/tailwind.css'
// this font is dynamically generated at build time.
import '@app/stylesheets/dynamic/index.css'

import { Layout } from '@app/components/layout'
import appWithI18n from 'next-translate/appWithI18n'
import i18nConfig from '../../i18n'

const App = ({ Component, pageProps }: any) => {
  const AppLayout = Component.getLayout ?? Layout
  return <AppLayout Component={Component} pageProps={pageProps} />
}

export default appWithI18n(App, {
  ...i18nConfig,
  skipInitialProps: true,
})
