const dev = process.env.NODE_ENV !== 'production'
const API_ENDPOINT = process.env.API || process.env.NEXT_PUBLIC_API

// the graphql API base endpoint. The env var API should include /graphql.
const _baseURL = API_ENDPOINT?.endsWith('/graphql')
  ? API_ENDPOINT
  : API_ENDPOINT
    ? `${API_ENDPOINT}${API_ENDPOINT.endsWith('/') ? '' : '/'}graphql`
    : ''
const graphQLUrl = _baseURL || `http://127.0.0.1:3280/graphql`

const webSocketUrl = process.env.WEB_SOCKET_URL
  ? process.env.WEB_SOCKET_URL
  : graphQLUrl.startsWith('http:')
    ? graphQLUrl.replace('http:', 'ws:')
    : graphQLUrl.replace('https:', 'wss:')

const AppConfig = {
  graphQLUrl,
  webSocketUrl,
}

// GOOGLE AUTH
// STRIPE
const STRIPE_KEY = process.env.STRIPE_KEY
// the front-end domain name
const DOMAIN_NAME =
  process.env.NEXT_PUBLIC_DOMAIN_NAME || process.env.DOMAIN_NAME || ''

// alias to domain name - no process env map
const companyName =
  process.env.COMPANY_NAME || process.env.NEXT_PUBLIC_COMPANY_NAME || ''
const twitterSite =
  process.env.TWITTER_SITE || process.env.NEXT_PUBLIC_TWITTER_SITE || ''

// the status page.
const STATUS_URL = AppConfig?.graphQLUrl?.replace('/graphql', '/status')
// the rest api endpoint
const REST_API = graphQLUrl.replace('/graphql', '')

// app level local storage keys to re-use.
export enum LocalStorageKeys {
  ReloadRequired = 'reload-required',
}

// client facing support outreach email
const SUPPORT_EMAIL = process.env.NEXT_PUBLIC_SUPPORT_EMAIL || ''
const SALES_EMAIL = process.env.NEXT_PUBLIC_SALES_EMAIL || ''

// filter marketing sites that only make sense to launch with a11ywatch.com due to criteria matching.
const FILTERED_MARKETING = process.env.NEXT_PUBLIC_FILTERED_PLATFORM

export {
  REST_API,
  STATUS_URL,
  DOMAIN_NAME,
  dev,
  AppConfig,
  STRIPE_KEY,
  companyName,
  twitterSite,
  SUPPORT_EMAIL,
  SALES_EMAIL,
  FILTERED_MARKETING,
}
