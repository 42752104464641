import { twMerge } from 'tailwind-merge'

const baseStyles =
  'inline-flex items-center focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 rendering-legibility'

export const getTranslateStyle = (noicon?: boolean) =>
  twMerge(
    `text-sm ${baseStyles} dark:hover:bg-gray-400`,
    noicon
      ? 'min-w-[8rem] px-2 py-1 rounded place-content-center place-items-center bg-black text-gray-100 md:min-w-[10rem] md:px-5 dark:bg-white dark:text-gray-800 dark:border-gray-600 hover:bg-gray-700 dark:hover:bg-gray-300'
      : 'text-white bg-gray-500 dark:bg-orange-600 hover:bg-gray-600 dark:hover:bg-gray-700 rounded-md px-3 py-2 focus:bg-gray-500'
  )

export const getTranslateStyleMarketing = twMerge(
  `text-xl ${baseStyles}`,
  'rounded-md px-3 py-2 hover:bg-gray-300 dark:hover:bg-gray-600'
)
