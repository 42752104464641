import React, { memo, useState } from 'react'
import { useWebsiteContext } from '@app/components/providers/website'
import { AppManager } from '@app/managers'
import { ThemeKeys } from '../../select/theme-input'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { SelectThemeInput } from '../../inputs/theme-input'
// import { useWebsiteContext } from '@app/components/providers/website'

export const ThemeBoxWrapper = ({
  theme: prevTheme,
  url,
  domain,
  activeSubscription,
  demo,
}: {
  theme?: ThemeKeys[]
  url: string
  domain: string
  activeSubscription?: boolean
  demo?: boolean
}) => {
  const { t } = useTranslation('dashboard-theme') // todo: dashboard-theme

  const [theme, setTheme] = useState<ThemeKeys>(
    prevTheme && Array.isArray(prevTheme) && prevTheme.length
      ? prevTheme[0]
      : 'system'
  )
  const { updateWebsite } = useWebsiteContext()

  const onThemeChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event?.target?.value as ThemeKeys
    setTheme(value)

    try {
      if (!demo) {
        await updateWebsite({
          variables: { url, theme: [value] },
        })
      }
      AppManager.toggleSnack(true, t('updated', { theme: value }))
    } catch (e) {
      AppManager.toggleSnack(
        true,
        t('updated-error', { theme: value }),
        'error'
      )
    }
  }

  return (
    <SelectThemeInput
      activeSubscription={activeSubscription}
      theme={theme}
      onThemeChange={onThemeChange}
      domain={domain}
      url={url}
    />
  )
}

export const ThemeBox = memo(ThemeBoxWrapper)
