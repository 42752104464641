import { Fragment, memo, useCallback } from 'react'
import {
  useFullAnalytics,
  LiveAnalyticsData,
} from '@app/data/external/analytics/analytics-rest'
import { classNames } from '@app/utils/classes'
import { listHeight } from '../general/cells/card/analytics-style'
import dynamic from 'next/dynamic'
import { twMerge } from 'tailwind-merge'
import { Skeleton } from '@app/@/components/ui/skeleton'
import { type Analytic } from '@app/types'
import { type StackTooltipProps } from '@nivo/stream'
import { useIsResizing } from './use-is-resizing'
import { Translate } from 'next-translate'
import { useTranslation } from '@app/lib/hooks/useTranslation'

const ResponsiveStream = dynamic(
  () => import('@nivo/stream').then((m) => m.ResponsiveStream),
  { ssr: false }
)

const theme = {
  axis: {
    ticks: {
      text: {
        fill: 'rgb(115,115,115)',
        fontSize: '0.75rem',
      },
    },
  },
}

const axisRight = {
  orient: 'right',
  tickSize: 0,
  tickPadding: -28,
  tickRotation: 0,
  legend: '',
  legendOffset: -35,
}

const StackTipComponent = ({ item, t }: { item: Analytic; t: Translate }) => {
  if (!item) {
    return null
  }

  return (
    <div className='rounded bg-white dark:bg-black border'>
      <div className='p-2 border-b'>
        <div className='text-sm'>{item.pageUrl}</div>
      </div>
      <div className='p-3 space-y-1'>
        <div className='text-gray-600 dark:text-gray-200 text-xs flex gap-x-2'>
          <div className='w-4 h-4 bg-[rgb(242,108,85)]'></div>
          {item.errorCount} {t(item.errorCount === 1 ? 'error' : 'errors')}
        </div>
        <div className='text-gray-600 dark:text-gray-200 text-xs flex gap-x-2'>
          <div className='w-4 h-4 bg-[rgb(236,223,113)]'></div>
          {item.warningCount}{' '}
          {t(item.warningCount === 1 ? 'warning' : 'warnings')}
        </div>
      </div>
    </div>
  )
}

const StackTip = memo(StackTipComponent)

// stream full website analytics to the dashboard - use gQL for improved cache
const WebsiteAnalyticStreamComponent = ({
  domain,
  liveData = [],
  onlyLive,
}: LiveAnalyticsData & { pageUrl?: string }) => {
  const { data } = useFullAnalytics({ domain, liveData, onlyLive })
  const isResizing = useIsResizing()
  const { t } = useTranslation('dashboard')

  const RenderStackTips = useCallback(
    (stack: StackTooltipProps) =>
      data ? <StackTip item={data[stack.slice.index]} t={t} /> : null,
    [data, t]
  )

  if (!data.length) {
    return (
      <div className={classNames('bg-gray-200 dark:bg-gray-800', listHeight)} />
    )
  }

  // on screen resize we need to determine if we need to display a loader due to the fps drop
  const hideChartForResize = isResizing && data.length >= 120

  return (
    <Fragment>
      {hideChartForResize ? <Skeleton className='w-full h-full' /> : null}
      <div
        className={twMerge('w-full h-full', hideChartForResize ? 'hidden' : '')}
      >
        <ResponsiveStream
          data={data}
          aria-busy={hideChartForResize}
          keys={['noticeCount', 'errorCount', 'warningCount']}
          margin={{ top: 30, right: 2, bottom: 9, left: 2 }}
          axisBottom={null}
          axisLeft={null}
          axisRight={axisRight}
          enableGridX={false}
          enableGridY={false}
          offsetType='silhouette'
          colors={{ scheme: 'nivo' }}
          theme={theme}
          animate={false}
          layers={['dots', 'grid', 'layers', 'slices', 'legends', 'axes']}
          stackTooltip={RenderStackTips}
          motionConfig='gentle'
        />
      </div>
    </Fragment>
  )
}

export const WebsiteAnalyticStream = memo(WebsiteAnalyticStreamComponent)
