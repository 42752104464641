import { FC } from 'react'
import { IssueCard } from './card/issue-card'
import { PageCard } from './card/pages-card'
import type { WebsiteTableProps } from '@app/types/dashboard'
import { tableCSS } from '@app/styles/dashboard-styles'

/* Website table component rendering issues and pages */
export const WebsiteTable: FC<WebsiteTableProps> = ({
  issuesInfo,
  liveData,
  url,
  activeSubscription,
  handleMainClick,
  storageless,
  domain,
  blacklist,
  onSortEvent,
}) => {
  const fetchedData = issuesInfo || (liveData && liveData.length)

  return (
    <div className='grid grid-cols-1 md:grid-cols-2 gap-3 px-2 py-3'>
      <div className={tableCSS}>
        <IssueCard
          pageUrl={url}
          liveData={liveData}
          activeSubscription={activeSubscription}
          storageless={storageless}
          domain={domain}
          blacklist={blacklist}
          onSortEvent={onSortEvent}
          fetchedData={!!fetchedData}
        />
      </div>
      <div className={tableCSS}>
        <PageCard
          pageUrl={url}
          liveData={liveData}
          handleMainClick={handleMainClick}
          fetchedData={!!fetchedData}
          storageless={storageless}
          activeSubscription={activeSubscription}
        />
      </div>
    </div>
  )
}
