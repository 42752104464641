import { FC, PropsWithChildren } from 'react'
import dynamic from 'next/dynamic'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import Trans from 'next-translate/Trans'
import { ListSkeleton } from '@app/components/placeholders'
import { CardHeader } from '@app/components/stateless/card/header'
import { DashboardCellLoader } from '../placeholders/dashboard-cell-loader'
import { Button } from './buttons'
import { UserManager } from '@app/managers'
import { P } from '../stateless/typo/p'

// EMPTY display of website
const EmptyWebsiteForm = dynamic(
  () => import('./website/empty-form').then((mod) => mod.EmptyWebsiteForm),
  {
    loading: () => (
      <div className='flex flex-col w-full place-items-center py-10 my-2 text-center'>
        <p>Loading...</p>
      </div>
    ),
  }
)

type DataContainerProps = PropsWithChildren<{
  data?: any
  error?: boolean
  loading?: boolean
  emptyHeaderTitle?: string
  emptyHeaderSubTitle?: string
  avatar?: boolean
  dashboard?: boolean
  onlyLive?: boolean
  storageless?: boolean
}>

// data container to handle loading main application pages
export const DataContainer: FC<DataContainerProps> = ({
  data,
  error,
  loading,
  emptyHeaderTitle,
  emptyHeaderSubTitle,
  children,
  avatar = true,
  dashboard,
  onlyLive,
  storageless,
}) => {
  const { t } = useTranslation('menu')

  if (!data?.length) {
    if (loading) {
      return dashboard ? (
        <DashboardCellLoader />
      ) : (
        <ListSkeleton avatar={avatar} />
      )
    }

    if (!loading && error) {
      // log user out
      const onLogout = () => {
        UserManager.clearUser()
        window.location.href = '/'
      }

      // todo: TRANSLATE Error
      return (
        <CardHeader
          title='Error'
          subheader={
            <Trans
              i18nKey={'errors:general-error'}
              components={[<P key={'errors-g'} />]}
            />
          }
          className='min-h-[88px]'
        >
          <Button onClick={onLogout}>{t('logout')}</Button>
        </CardHeader>
      )
    }

    return (
      <EmptyWebsiteForm
        emptyHeaderTitle={emptyHeaderTitle}
        emptyHeaderSubTitle={emptyHeaderSubTitle}
        onlyLive={onlyLive}
        storageless={storageless}
      />
    )
  }

  return <>{children || null}</>
}
