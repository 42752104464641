import { useCallback, useDeferredValue, useReducer } from 'react'
import { useDebounce } from 'use-debounce'

const initialSearchState: SearchState = {
  visible: false,
  search: '',
}

export interface SearchState {
  search?: string
  visible: boolean // display the change password form
}

interface SearchAction {
  payload?: string
  type: 'toggleVisiblility' | 'mutateCurrent' | 'reset'
}

export type SearchReducer = (
  state: SearchState,
  action: SearchAction
) => SearchState

export const searchReducer: SearchReducer = (state, action) => {
  const { payload } = action

  switch (action.type) {
    case 'toggleVisiblility': {
      return { ...state, visible: !state.visible }
    }
    case 'mutateCurrent': {
      return { ...state, search: payload }
    }
    case 'reset': {
      return { ...initialSearchState }
    }
    default: {
      return state
    }
  }
}

// perform a search hook that binds to a graph analytics - pages
export const useSearchQuery = () => {
  const [{ visible, search }, dispatch] = useReducer(
    searchReducer,
    Object.assign({}, initialSearchState)
  )
  const [d] = useDebounce(search, 300)
  const value = useDeferredValue(d)

  const onSearchEvent = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({ type: 'mutateCurrent', payload: e.target.value })
    },
    [dispatch]
  )

  const onSearchOpenEvent = useCallback(
    (_: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({ type: 'toggleVisiblility' })
    },
    [dispatch]
  )

  return {
    onSearchOpenEvent,
    onSearchEvent,
    search: value,
    visible,
  }
}
