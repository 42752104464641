import React, { Fragment, useCallback } from 'react'
import { Button } from '../buttons'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { AppManager } from '@app/managers'
import { classNames } from '@app/utils/classes'
import { useWebsiteContext } from '@app/components/providers/website'
import { useWebhooks } from '../cells/blocks/webhooks/use-webhooks'
import { Checkbox } from '../check-box'
import { FormControl } from '../form-control'
import { Header3, Header4 } from '../header'
import { Paragraph } from '@app/components/stateless/typo/paragraph'
import { WebhookMap } from '../cells/blocks/webhooks/webhooks'
import { TextField } from '../text-field'
import Trans from 'next-translate/Trans'
import { dev } from '@app/configs'
import { GrStatusWarning } from 'react-icons/gr'

function deepEqual(obj1: any, obj2: any) {
  if (obj1 === obj2) {
    return true
  } else if (isObject(obj1) && isObject(obj2)) {
    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
      return false
    }
    for (var prop in obj1) {
      if (!deepEqual(obj1[prop], obj2[prop])) {
        return false
      }
    }
    return true
  }

  // Private
  function isObject(obj: any) {
    if (typeof obj === 'object' && obj != null) {
      return true
    } else {
      return false
    }
  }
}

// stand alone form to get new actions and update
export const WebhookForm = ({
  url,
  webhooks,
}: {
  url?: string
  webhooks?: WebhookMap
}) => {
  const { t } = useTranslation('forms')
  const { updateWebsite, loading } = useWebsiteContext()
  const { events, handleUpdate, handleReset } = useWebhooks({ webhooks })

  // TODO: translate events.
  const onSubmitEvent = useCallback(
    async (e?: React.FormEvent) => {
      e?.preventDefault()

      if (!url) {
        return
      }

      if (!events.endpoint) {
        return AppManager.toggleSnack(true, `Missing: Endpoint`, 'error')
      }

      const a1 = { ...webhooks, signature: undefined }
      const b1 = { ...events, signature: undefined }

      // if the events equal to the same object
      if (
        Object.is(webhooks, events) ||
        Object.is(a1, b1) ||
        deepEqual(a1, b1)
      ) {
        return
      }

      let parsedUrl: URL | null = null

      try {
        parsedUrl = new URL(events.endpoint)
      } catch (e) {
        console.error(e)
      }

      if (!parsedUrl?.hostname) {
        return AppManager.toggleSnack(true, `Invalid url`, 'error')
      }

      if (!dev && parsedUrl?.hostname === 'localhost') {
        return AppManager.toggleSnack(
          true,
          `You cannot use localhost. Please enter another url.`,
          'error'
        )
      }

      // todo: loop through values and validate events
      if (
        !Object.values(events).some((v) =>
          typeof v === 'object' ? Object.values(v).some((c) => c) : false
        )
      ) {
        return AppManager.toggleSnack(
          true,
          // TODO: translate error
          `You need to select at least one event.`,
          'error'
        )
      }

      try {
        await updateWebsite({
          variables: {
            url,
            webhooks: {
              account: {
                exceeded: events.account.exceeded,
              },
              endpoint: events.endpoint || '',
              all: events.all,
              crawl: {
                canceled: events.crawl.canceled,
                finished: events.crawl.finished,
                shutdown: events.crawl.shutdown,
                started: events.crawl.started,
              },
              website: {
                created: events.website.created,
                deleted: events.website.deleted,
                updated: events.website.updated,
              },
            },
          },
        })

        // AppManager.toggleSnack(true, 'Saved!')
      } catch (e) {
        console.error(e)
      }
    },
    [url, events, webhooks, updateWebsite]
  )

  const onClearEvent = async () => {
    handleReset()
    try {
      await updateWebsite({
        variables: {
          url,
          webhooks: {
            account: {
              exceeded: false,
            },
            endpoint: '',
            all: events.all,
            crawl: {
              canceled: false,
              finished: false,
              shutdown: false,
              started: false,
            },
            website: {
              created: false,
              deleted: false,
              updated: false,
            },
          },
        },
      })

      // AppManager.toggleSnack(true, 'Saved!')
    } catch (e) {
      console.error(e)
    }
  }

  const hooksTranslated: { website: any; account: any; crawl: any } = t(
    'dash/webhooks-cell:hooks',
    { url },
    { returnObjects: true }
  )

  const signature = events.signature ?? webhooks?.signature

  return (
    <form className='mx-auto container max-w-4xl' onSubmit={onSubmitEvent}>
      <div className='px-3 py-4 space-y-2'>
        <div>
          <Header3>{t('dash/webhooks-cell:events')}</Header3>
          <Paragraph>{t('dash/webhooks-cell:events-d')}</Paragraph>
        </div>
        <ul className='py-2 px-4 bg-gray-200 dark:bg-gray-700 gap-y-2 grid grid-cols-2'>
          {(['account', 'crawl', 'website'] as ['account', 'crawl', 'website'])
            .filter((key) => key !== 'account')
            .map((eventKey) => {
              return (
                <Fragment key={eventKey}>
                  {Object.entries(events[eventKey])
                    .filter(([key]) => key !== '__typename')
                    .map(([key, value]) => {
                      const inputID = `checkbox-${eventKey}-${key}`

                      // patch map for incorrect locale files
                      const eventKeyMap =
                        eventKey === 'crawl'
                          ? `crawl.${key}`
                          : eventKey === 'account'
                            ? `quota.${key}`
                            : key

                      return (
                        <li className={'space-x-2'} key={inputID}>
                          <Checkbox
                            checked={!!value}
                            id={inputID}
                            onChange={() => {
                              handleUpdate({
                                [eventKey]: {
                                  [key]: !value,
                                },
                              })
                            }}
                          />
                          <FormControl htmlFor={inputID} visible>
                            <span className='inline-flex flex-col'>
                              <span className='block'>
                                {hooksTranslated[eventKey][eventKeyMap]}
                              </span>
                              <span className='block text-xs'>
                                {eventKey === 'website'
                                  ? `website.${key}`
                                  : eventKeyMap}
                              </span>
                            </span>
                          </FormControl>
                        </li>
                      )
                    })}
                </Fragment>
              )
            })}
        </ul>
        <div className='py-2 space-y-2'>
          <div>
            <Header3>{t('dash/webhooks-cell:endpoint')}</Header3>
            <Trans
              i18nKey='dash/webhooks-cell:endpoint-d'
              components={[
                <Paragraph key={'w1'} />,
                <span className='text-teal-600 font-light' key={'w2'}></span>,
              ]}
            />
          </div>
          <TextField
            value={events.endpoint}
            placeholder={t('dash/webhooks-cell:endpoint-placeholder')}
            exactClass='border w-full md:w-auto min-w-[18rem] px-3 py-2 rounded border-gray-400 dark:border-gray-600'
            required
            type={'url'}
            onChange={(event) =>
              handleUpdate({
                endpoint: event.target.value,
              })
            }
          />
        </div>

        <div className='flex flex-wrap gap-3'>
          <Button
            type={'submit'}
            disabled={loading}
            className={classNames(
              loading ? '' : 'hover:text-blue-600 hover:border-blue-600',
              'w-full md:w-auto capitalize px-6 min-w-[10rem] text-gray-700 dark:text-gray-300'
            )}
          >
            {t(webhooks ? 'save' : 'dash/webhooks-cell:create')}
          </Button>
          {events && webhooks?.endpoint ? (
            <Button
              type={'button'}
              onClick={onClearEvent}
              disabled={loading}
              className={classNames(
                loading ? '' : 'hover:text-red-600 hover:border-red-600',
                'text-red-700 border-red-700 dark:border-red-700 dark:text-red-700 w-full md:w-auto capitalize px-6 min-w-[10rem]'
              )}
            >
              {t('dash/webhooks-cell:remove')}
            </Button>
          ) : null}
        </div>

        <div className='py-4'>
          {signature && webhooks?.endpoint ? (
            <div className='space-y-3'>
              <div>
                <Header4 className='font-semibold text-base'>
                  {t('dash/webhooks-cell:secure')}
                </Header4>
                <Trans
                  i18nKey='dash/webhooks-cell:secure-d'
                  components={[
                    <Paragraph
                      className='text-sm text-gray-700 dark:text-gray-300'
                      key='secure-1'
                    />,
                    <b className='font-bold' key='secure-2'>
                      x-a11ywatch-signature
                    </b>,
                  ]}
                />
              </div>
              <div>
                <div className='p-4 rounded shadow-xl'>
                  <Trans
                    i18nKey='dash/webhooks-cell:secret'
                    components={[
                      <Paragraph
                        className='text-sm text-gray-700 dark:text-gray-300 line-clamp-5 max-w-[90vw]'
                        key='ss-1'
                      />,
                      <b className='text-violet-600' key='ss-2'>
                        {signature}
                      </b>,
                    ]}
                  />
                </div>
              </div>
              <div className='border dark:border-gray-300 rounded flex place-items-center px-4 py-3 space-x-2 bg-yellow-50 dark:bg-black'>
                <GrStatusWarning className='grIcon text-yellow-500 dark:text-yellow-800' />
                <Paragraph>{t('dash/webhooks-cell:onetime')}</Paragraph>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </form>
  )
}
