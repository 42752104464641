import React, { useCallback } from 'react'
import { Button } from '../buttons'
import { useInputActions } from '../hooks'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { AppManager } from '@app/managers'
import { InputActions } from './input-actions'
import { classNames } from '@app/utils/classes'
import { useWebsiteContext } from '@app/components/providers/website'

// stand alone form to get new actions and update
export const ActionsForm = ({
  url,
  customFields,
}: {
  url?: string
  customFields?: any
}) => {
  const { t } = useTranslation('forms')
  const { updateWebsite, loading } = useWebsiteContext()
  const actions = useInputActions({ customFields }, true)

  const onActionSubmit = useCallback(
    async (e?: React.FormEvent) => {
      e?.preventDefault()

      try {
        await updateWebsite({
          variables: {
            url,
            actions: actions.customFields,
          },
        })

        AppManager.toggleSnack(true, 'Saved!')
      } catch (e) {
        console.error(e)
      }
    },
    [url, actions, updateWebsite]
  )

  return (
    <form className='mx-auto container max-w-4xl' onSubmit={onActionSubmit}>
      <div className='px-3 py-4 space-y-2'>
        <InputActions {...actions} />
        <Button
          type={'submit'}
          disabled={loading}
          className={classNames(
            loading ? '' : 'hover:text-blue-600 hover:border-blue-600',
            'w-full md:w-auto capitalize px-6 min-w-[10rem] text-gray-700 dark:text-gray-300'
          )}
        >
          {t('save')}
        </Button>
      </div>
    </form>
  )
}
