import React from 'react'
import { classNames } from '@app/utils/classes'

export type SkeletonProps = {
  className?: string
  style?: React.CSSProperties
}

// stock skeleton
export const Skeleton = ({ className }: SkeletonProps) => (
  <span className={classNames('block bg-gray-300', className || '')}></span>
)
