import { rateMap } from '@app/utils/ssr/ratemap'
import React from 'react'

// price block
export const PriceTotal = ({
  price,
  lang,
  noui,
}: {
  price: number
  lang?: string
  noui?: boolean
}) => {
  // always use USD unless converter is set.
  const priceFormatted = new Intl.NumberFormat(lang || 'en-US', {
    style: 'currency',
    currency:
      lang && lang in rateMap ? rateMap[lang as keyof typeof rateMap] : 'USD',
    minimumFractionDigits: 2,
    maximumSignificantDigits: 2,
  }).format(price / 100)

  if (noui) {
    return <>{priceFormatted}</>
  }

  return <div className='text-3xl'>{priceFormatted}</div>
}
