import { FC, PropsWithChildren } from 'react'

export const Paragraph: FC<PropsWithChildren<{ className?: string }>> = ({
  children,
  className,
}) => {
  return (
    <p
      className={`text-gray-800 dark:text-gray-200${
        className ? ` ${className}` : ''
      }`}
    >
      {children}
    </p>
  )
}
