import { memo } from 'react'

type TopBlockProps = {
  count?: number
  label?: string
  formatter: Intl.NumberFormat
}

const TopBlockComponent = ({ count, label, formatter }: TopBlockProps) => {
  return (
    <div className='px-3.5 py-2.5 flex gap-x-1.5 place-items-center text-sm'>
      <div className='uppercase text-gray-700 dark:text-gray-300'>
        {label}:{' '}
      </div>
      <b className='truncate min-w-[2.25rem] md:min-w-[4rem]'>
        {formatter.format(count || 0)}
      </b>
    </div>
  )
}

export const TopBlock = memo(TopBlockComponent)
