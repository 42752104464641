import { useMemo } from 'react'
import { wcagHref } from '../utils'

export const codeFormatter = (code?: string, helpUrl?: string) => {
  if (helpUrl) {
    return {
      codeDisplay: code,
      codeHref: helpUrl,
    }
  }

  const codeDisplay =
    code && typeof code === 'string' ? code?.replace(/_+/g, '') : ''
  const hrefCode = wcagHref(codeDisplay)

  return {
    codeDisplay: hrefCode,
    codeHref: undefined,
  }
}

// link to WCAG code
export const CodeLink = ({
  code,
  helpUrl,
}: {
  code?: string
  helpUrl?: string
}) => {
  const { codeHref, codeDisplay } = useMemo(
    () => codeFormatter(code, helpUrl),
    [code, helpUrl]
  )

  const codeCSS =
    'text-gray-600 dark:text-gray-300 text-xs p-0 hover:underline hover:text-blue-600 dark:hover:text-blue-500'

  return (
    <div className='truncate max-w-[88vw] overflow-hidden'>
      {codeDisplay && Array.isArray(codeDisplay) ? (
        <ul className='gap-x-3 flex divide-x rtl:place-content-end'>
          {codeDisplay.map((item, i) => {
            return (
              <li key={`cidemap-${i}`}>
                <ul className='gap-x-2 flex'>
                  {item.map((j, i) => {
                    return (
                      <li key={`${j[1]}-${i}`}>
                        <a
                          href={j[0]}
                          // title={`view technique for ${j[1]}`}
                          title={j[1]}
                          target='_blank'
                          rel='noreferrer'
                          className={codeCSS}
                        >
                          {j[1]}
                        </a>
                      </li>
                    )
                  })}
                </ul>
              </li>
            )
          })}
        </ul>
      ) : helpUrl || codeHref ? (
        <a
          href={helpUrl || codeHref}
          // title={`view technique for ${codeDisplay}`}
          target='_blank'
          title={codeDisplay}
          rel='noreferrer'
          className={codeCSS}
        >
          {codeDisplay}
        </a>
      ) : (
        <span
          className={codeCSS.replace(
            'hover:underline hover:text-blue-600 dark:hover:text-blue-500',
            ''
          )}
        >
          {code}
        </span>
      )}
    </div>
  )
}
