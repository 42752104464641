type AppModel = {
  pwaInstalled: boolean
}

const appModel: AppModel = {
  pwaInstalled: false,
}

const installed = 'appinstalled'

const setPwaInstalled = (event?: Event) => {
  appModel.pwaInstalled = true
  event && window.removeEventListener(installed, setPwaInstalled)
}

const initPWAWorker = () => {
  if (typeof window === 'undefined') {
    return
  }
  if (
    typeof window.matchMedia === 'function' &&
    window.matchMedia('(display-mode: standalone)').matches
  ) {
    setPwaInstalled()
  } else if (!appModel.pwaInstalled && 'serviceWorker' in navigator) {
    try {
      window.addEventListener(installed, setPwaInstalled)
    } catch (e) {
      console.error('PWA not supported.')
    }
  }
}

export { appModel, initPWAWorker }
