import React from 'react'
import { TextSkeleton } from '@app/components/placeholders'
import { classNames } from '@app/utils/classes'
import { Paragraph } from '@app/components/stateless/typo/paragraph'

// TODO replace nested ternarys
export const ProfileCell = ({
  skeletonLoad,
  title,
  subTitle,
  className,
  titleClassName = '',
  subChildren,
}: {
  skeletonLoad?: boolean
  title: string
  subTitle?: string
  titleClassName?: string // title class name
  className?: string // subtitle class name
  children?: any
  subChildren?: any
}) => {
  return (
    <div className='border rounded py-2 px-3 dark:border-gray-600'>
      <h3 className={classNames(`text-base font-semibold`, titleClassName)}>
        {title}
      </h3>
      <div className='flex space-x-1 place-items-center'>
        {skeletonLoad ? (
          <TextSkeleton className={className} size={'base'} />
        ) : subTitle ? (
          <Paragraph className={className}>{subTitle}</Paragraph>
        ) : null}
        {subChildren}
      </div>
    </div>
  )
}
