import React from 'react'
import { classNames } from '@app/utils/classes'
import { getErrorColor } from '@app/lib'

// get the circle color
export const IssueColor = ({ issueType }: { issueType?: string }) => {
  return (
    <div
      className={classNames(
        getErrorColor(issueType),
        `min-w-[0.75rem] min-h-[0.75rem] w-3 h-3 rounded-full`
      )}
    />
  )
}
