import { getAPIRoute } from '@app/configs'
import { UserManager } from '@app/managers'

const apiPath = `${getAPIRoute()}/ping`

// initial app ping
export const ping = () => {
  if (typeof navigator !== 'undefined' && navigator.sendBeacon) {
    try {
      navigator.sendBeacon(
        apiPath,
        JSON.stringify({
          jwt: UserManager?.token,
          role: UserManager?.jwtParsed?.audience,
        })
      )
    } catch (e) {
      console.error(e)
    }
  }
}
