import React from 'react'

// the amount of times the issue re-occured
export const Recurrence = ({ recurrence }: { recurrence?: number }) => {
  return (
    <div className='justify-end flex flex-shrink'>
      {recurrence ? (
        <div className='px-2 rounded bg-gray-200 dark:bg-gray-800'>
          <p className={'truncate text-xs font-semibold'}>
            Recurrence: {recurrence}
          </p>
        </div>
      ) : null}
    </div>
  )
}
