import { classNames } from '@app/utils/classes'

export const btnStyles =
  'px-3 py-3 block w-full capitalize ltr:text-left rtl:text-right rounded-none text-sm md:px-4 hover:no-underline'

// get active menu style
export const getActiveStyles = (active: boolean) =>
  active
    ? 'bg-gray-200 text-blue-600 dark:bg-gray-800 dark:text-gray-200 hover:text-blue-700 dark:hover:text-gray-200 active:text-blue-700'
    : ''

export const disabledBtnStyle =
  'flex place-items-center gap-x-2 hover:text-inherit dark:hover:text-inherit active:text-inherit'

// get the more option button style and enforce paidPlan to get collors
export const getMoreMenuStyle = (active: boolean, paidPlan: boolean) =>
  classNames(btnStyles, !paidPlan ? disabledBtnStyle : getActiveStyles(active))
