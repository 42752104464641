import { SyntheticEvent, memo, useCallback, useEffect, useState } from 'react'
import { useTheme } from '@app/components/themes/theme'
import { classNames } from '@app/utils/classes'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { GrMoon, GrSun, GrMonitor } from 'react-icons/gr'

const btnStyle =
  'rounded-full w-7 h-7 p-1 flex place-content-center place-items-center text-gray-700 hover:bg-gray-300 dark:text-gray-200 dark:hover:bg-gray-600'

const activeStyles =
  'bg-gray-300 text-gray-800 dark:text-gray-100 dark:bg-gray-700'

const ThemeSwitcherComponent = () => {
  const { t } = useTranslation('menu')
  const { theme, setTheme } = useTheme()
  const [activeTheme, setActiveTheme] = useState<string>()

  useEffect(() => {
    setActiveTheme(theme)
  }, [theme, setActiveTheme])

  const onThemeChange = useCallback(
    (e?: SyntheticEvent<HTMLButtonElement>) => {
      if (e && typeof e.preventDefault === 'function') {
        e?.preventDefault()
      }

      const currentTheme = e?.currentTarget.value ?? 'system'

      if (
        currentTheme &&
        typeof currentTheme === 'string' &&
        ['light', 'dark', 'system'].includes(currentTheme)
      ) {
        setTheme(currentTheme)
      }
    },
    [setTheme]
  )

  const onDarkPress = useCallback(
    (e: SyntheticEvent<HTMLButtonElement>) => {
      e.currentTarget.value = 'dark'
      onThemeChange(e)
    },
    [onThemeChange]
  )

  const onLightPress = useCallback(
    (e: SyntheticEvent<HTMLButtonElement>) => {
      e.currentTarget.value = 'light'
      onThemeChange(e)
    },
    [onThemeChange]
  )

  const onSystemPress = useCallback(
    (e: SyntheticEvent<HTMLButtonElement>) => {
      e.currentTarget.value = 'system'
      onThemeChange(e)
    },
    [onThemeChange]
  )

  const apperanceText = t('appearance')

  return (
    <div
      className={
        'border space-x-0.5 rounded-3xl px-[3px] py-[4px] flex place-items-center place-content-between dark:border-gray-700'
      }
    >
      <button
        onClick={onDarkPress}
        type={'button'}
        className={classNames(
          btnStyle,
          activeTheme === 'dark' ? activeStyles : ''
        )}
      >
        <span className='sr-only'>{`${t('dark')} ${apperanceText}`}</span>
        <GrMoon className='grIcon' />
      </button>

      <button
        onClick={onLightPress}
        type={'button'}
        className={classNames(
          btnStyle,
          activeTheme === 'light' ? activeStyles : ''
        )}
      >
        <span className='sr-only'>{`${t('light')} ${apperanceText}`}</span>
        <GrSun className='grIcon' />
      </button>

      <button
        onClick={onSystemPress}
        type={'button'}
        className={classNames(
          btnStyle,
          activeTheme === 'system' ? activeStyles : ''
        )}
      >
        <span className='sr-only'>{`${t('auto')} ${apperanceText}`}</span>
        <GrMonitor className='grIcon' />
      </button>
    </div>
  )
}

export const ThemeSwitcher = memo(ThemeSwitcherComponent)
