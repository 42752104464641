export const rateMap = {
  ja: 'JPY',
  en: 'USD',
  de: 'EUR',
  fr: 'EUR',
  'pt-PT': 'EUR',
  'pt-BR': 'BRL',
  'zh-CN': 'CNY',
  'zh-TW': 'CNY',
  ru: 'RUB',
}
