import { importRules } from '@app/utils/rules'
import type { Rule } from 'kayle/build/rules'
import { useState } from 'react'

export const useKayleRules = ({
  runners,
  lang,
  websiteIgnore,
}: {
  runners?: string[]
  lang: string
  websiteIgnore?: string[]
}) => {
  const [ignore, setIgnore] = useState<string[]>(websiteIgnore ?? [])
  const [ignoreList, setIgnoreList] = useState<Rule[]>([])

  const onLoadRulesEvent = async () => {
    if (Array.isArray(runners) && runners.length) {
      const _ignore: Rule[] = []
      for (const runner of runners) {
        const list = await importRules(lang as any, runner as 'htmlcs' | 'axe')
        _ignore.push(...list)
      }
      setIgnoreList(_ignore)
    } else {
      const list = await importRules(lang as any, 'htmlcs')
      setIgnoreList(list)
    }
  }

  return {
    onLoadRulesEvent,
    setIgnoreList,
    setIgnore,
    ignore,
    ignoreList,
  }
}
