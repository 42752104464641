import { FC, PropsWithChildren } from 'react'
// import { LightAsync } from 'react-syntax-highlighter'

// Syntax highlight light build
const WithSyntax: FC<PropsWithChildren<any>> = ({ children }) => {
  return (
    <div className='bg-black py-2 px-3 rounded dark:bg-gray-800'>
      <pre
        className={
          'text-sm bg-transparent text-gray-200 truncate overflow-hidden max-h-16'
        }
      >
        <code className='whitespace-pre'>{children}</code>
      </pre>
    </div>
  )
}

export { WithSyntax }
