import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { useWebsiteContext } from '@app/components/providers/website'
import { BlacklistInput } from '../../inputs/blacklist-input'

type BlacklistProps = {
  url: string
  blacklist?: string[]
  activeSubscription?: boolean
  translations?: string
}

export const BlacklistBox = ({
  blacklist: blist,
  url,
  activeSubscription,
}: BlacklistProps) => {
  const currentTimeout = useRef<NodeJS.Timeout>()
  const blacklistString = blist?.join()

  const [blacklist, setBlacklist] = useState<string>(blacklistString || '')

  const { updateWebsite } = useWebsiteContext()

  const onChangeBlacklist = (e: React.ChangeEvent<any>) =>
    setBlacklist(e?.target?.value)

  const mutateBlacklist = useCallback(async () => {
    if (blacklist !== blacklistString) {
      try {
        await updateWebsite({
          variables: {
            url,
            blacklist:
              blacklist && blacklist.length ? blacklist.split(',') : [],
          },
        })
      } catch (e) {
        console.error(e)
      }
    }
  }, [blacklist, blacklistString, updateWebsite, url])

  useEffect(() => {
    if (blacklist === blacklistString) {
      return
    }

    const debounce = setTimeout(mutateBlacklist, 3000)

    return () => {
      clearTimeout(debounce)
      if (currentTimeout.current) {
        clearTimeout(currentTimeout.current)
      }
      currentTimeout.current = setTimeout(mutateBlacklist, 200)
    }
  }, [blacklist, blacklistString, mutateBlacklist, currentTimeout])

  return (
    <BlacklistInput
      activeSubscription={activeSubscription}
      onChangeBlacklist={onChangeBlacklist}
      blacklist={blacklist}
      url={url}
    />
  )
}

export const UserBlacklistBoxWrapper = ({
  blacklist: blist,
  url,
  activeSubscription,
}: BlacklistProps) => (
  <BlacklistBox
    activeSubscription={activeSubscription}
    blacklist={blist}
    url={url}
  />
)

export const UserBlacklistBox = memo(UserBlacklistBoxWrapper)
