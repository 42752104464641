import { PropsWithChildren, FC } from 'react'
import { Logo, NavBarTitle } from '.'
import { Link } from '@app/components/stateless/typo/link'
import { GrLinkPrevious } from 'react-icons/gr'
import { NavbarInner } from './navbar-inner'
import { getNavbarStyles } from './styles'
import { TranslateLanguageLink } from './translate-link'
import { SkipContent } from '../skip-content'
import { companyName } from '@app/configs'
import useTranslation from 'next-translate/useTranslation'

type LeftbuttonWrapperProps = {
  marketing?: boolean
  backButton?: boolean // display a go back button
}

// navbar props
interface NavProps {
  title?: string
  backButton?: boolean
  marketing?: boolean
  toolbar?: any
  className?: string
  position?: 'fixed' | 'absolute' | 'relative' | 'static' // navbar position
  marketingLinks?: boolean
  notitle?: boolean
  authenticated?: boolean
  loading?: boolean
}

// start left button of the nav TODO: translations
const Leftbutton = ({ marketing, backButton }: LeftbuttonWrapperProps) => {
  const { t } = useTranslation('common')

  if (marketing) {
    return null
  }
  if (backButton) {
    const onClickEvent = () => typeof history !== 'undefined' && history.back()
    return (
      <button
        className={'text-lg hover:text-blue-600 rounded-3xl px-3 py-3'}
        title={t('go-home')}
        onClick={onClickEvent}
        type={'button'}
      >
        <GrLinkPrevious className='grIcon rtl:rotate-180' />
      </button>
    )
  }
  return (
    <Link
      className={'text-lg hover:opacity-70 rounded-3xl px-3 py-3'}
      title={t('go-home')}
      href={'/'}
    >
      <Logo t={t} />
    </Link>
  )
}

export const NavBar: FC<
  PropsWithChildren<NavProps & { authPage?: boolean }>
> = ({
  title = companyName,
  backButton,
  marketing,
  toolbar,
  className = '',
  position = 'static',
  children,
  marketingLinks,
  notitle,
  authenticated,
  loading,
  authPage,
}) => {
  return (
    <header className='min-h-[55px] px-3 py-1 flex place-items-center md:py-3'>
      <SkipContent />

      <nav className={getNavbarStyles({ position, className })}>
        <div className='relative flex items-center place-content-between min-h-[inherit]'>
          {toolbar || children ? (
            toolbar || children
          ) : (
            <div className={`flex flex-1 place-items-center gap-x-2`}>
              <Leftbutton backButton={backButton} marketing={marketing} />
              <NavBarTitle
                title={title}
                marketing={marketing}
                notitle={notitle}
                hidelogo={backButton}
                flex
              />
            </div>
          )}
          <NavbarInner
            loading={loading}
            marketingLinks={marketingLinks}
            authenticated={authenticated}
            title={title}
            authPage={authPage}
          />
        </div>
      </nav>
      {marketingLinks ? (
        <TranslateLanguageLink className='ltr:pl-3 rtl:pl-3' />
      ) : null}
    </header>
  )
}
