import { useState, memo, Fragment, useEffect, useRef } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { GrCheckmark } from 'react-icons/gr'
import { classNames } from '@app/utils/classes'
import { getSelectStyle } from './select/select-style'

const runners = ['htmlcs', 'axe']

// check to see if runners are duplicated
const filterRunnerDuplicates = (runners: string[]): string[] => {
  if (runners.length === 2) {
    if (runners[0] === runners[1]) {
      return [runners[0]]
    }
  }
  if (runners.length >= 3) {
    return [...new Set(runners)]
  }
  return runners
}

// selected runner drop down
const RunnerSelectComponent = ({
  cb,
  defaultRunners,
  disabled,
  borderLess,
  translation,
}: {
  defaultRunners?: typeof runners
  cb?(runners: string[]): void
  disabled?: boolean
  borderLess?: boolean
  translation?: string
}) => {
  const { t } = useTranslation(translation ?? 'dashboard')
  const [selectedRunners, setSelectedRunners] = useState<typeof runners>(
    defaultRunners ?? []
  )
  const mounted = useRef<boolean>(false)

  useEffect(() => {
    if (defaultRunners && !mounted.current) {
      setSelectedRunners(defaultRunners)
      mounted.current = true
    }
  }, [defaultRunners, setSelectedRunners, mounted])

  const onChangeEvent = (selected: typeof runners) => {
    const nextRunners: string[] = filterRunnerDuplicates(selected)
    setSelectedRunners(nextRunners)
    if (cb && typeof cb === 'function') {
      cb(nextRunners)
    }
  }

  return (
    <Listbox
      value={selectedRunners}
      onChange={onChangeEvent}
      defaultValue={defaultRunners}
      disabled={disabled}
      multiple
    >
      <Listbox.Label className={'text-xs sr-only'}>Runners:</Listbox.Label>
      <div className='relative space-y-1'>
        <Listbox.Button className={getSelectStyle({ disabled, borderLess })}>
          <span className='block truncate capitalize min-w-[120px]'>
            {selectedRunners && selectedRunners.length
              ? selectedRunners.map((runner) => runner).join(', ')
              : t('default-runner')}
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave='transition ease-in duration-100'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <Listbox.Options className='absolute max-h-60 w-32 z-20 rounded-md bg-white dark:bg-black py-1 shadow-lg border focus:outline-none text-sm'>
            {runners.map((runner, personIdx) => (
              <Listbox.Option
                key={personIdx}
                className={({ active }) =>
                  classNames(
                    `relative cursor-default select-none py-1.5 pl-10 pr-4`,
                    active
                      ? 'bg-blue-100 text-blue-900'
                      : 'text-gray-900 dark:text-gray-300'
                  )
                }
                value={runner}
              >
                {({ selected }) => {
                  const defaultSelected =
                    selected || selectedRunners.some((item) => item === runner)

                  return (
                    <>
                      <span
                        className={`block w-full truncate uppercase ${
                          defaultSelected ? 'font-medium' : 'font-normal'
                        }`}
                      >
                        {runner}
                      </span>
                      {defaultSelected ? (
                        <span className='absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600'>
                          <GrCheckmark
                            className='grIcon h-3 w-3'
                            aria-hidden='true'
                          />
                        </span>
                      ) : null}
                    </>
                  )
                }}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  )
}

export const RunnerSelect = memo(RunnerSelectComponent)
