import { initUrl } from '@app/lib/utils'

// search the query [url, autoTPT]
export const searchQuery = (
  url: string,
  insecureTransport?: boolean
): [string, boolean] => {
  // raw html
  if (!url) {
    return ['', false]
  }
  const [target, httpEnabled] = initUrl(url, insecureTransport)

  return [target, !httpEnabled]
}
