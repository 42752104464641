import { memo, useEffect, useRef, useState } from 'react'
import { WebsiteAnalyticStream } from '@app/components/charts/streams'
import { LazyMount } from '@app/components/lazy/lazymount'
import { Analytic } from '@app/types'
import { listHeight } from './analytics-style'
import { LiveAnalyticsData } from '@app/data/external/analytics/analytics-rest'

const AnalyticsCardComponent = ({
  domain,
  liveData,
  onlyLive,
  pageUrl,
}: LiveAnalyticsData & { pageUrl?: string }) => {
  const [_, setUpdate] = useState<boolean>(false)
  const liveDataClone = useRef<Analytic[]>(liveData || [])
  const liveDataThrottleTimer = useRef<NodeJS.Timeout>()

  // delay the updates to prevent the chart from jittering and causing excess updates
  useEffect(() => {
    if (liveData) {
      if (onlyLive) {
        liveDataClone.current = liveData
        setUpdate((x) => !x)
      } else if (liveData.length) {
        liveDataThrottleTimer.current = setTimeout(() => {
          liveDataThrottleTimer.current = undefined
          liveDataClone.current = liveData
          setUpdate((x) => !x)
        }, 500)
      }
    }

    return () => {
      liveDataThrottleTimer.current &&
        clearTimeout(liveDataThrottleTimer.current)
    }
  }, [liveData, liveDataThrottleTimer, liveDataClone, setUpdate, onlyLive])

  return (
    <div className={listHeight}>
      <LazyMount className={listHeight} full>
        <WebsiteAnalyticStream
          domain={domain}
          pageUrl={pageUrl}
          liveData={liveDataClone.current}
          onlyLive={onlyLive}
        />
      </LazyMount>
    </div>
  )
}

export const AnalyticsCard = memo(AnalyticsCardComponent)
