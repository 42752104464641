import { Fragment, memo } from 'react'
import { routeParseAbsolute } from '@app/lib/router-handler'
import { classNames } from '@app/utils'
import { translateMap } from './translate-map'
import type { Translate } from 'next-translate'

type PopOverProps = {
  open?: boolean
  defaultLocale?: string
  item: string
  t: Translate
  pathname?: string
  lang: string
  Component: any
  asComponent?: any
}

const PopoverBtnComponent = ({
  item,
  defaultLocale,
  pathname,
  lang,
  t,
  Component,
  asComponent,
}: PopOverProps) => {
  return (
    <Component key={item} value={item} as={asComponent ?? Fragment}>
      {({ active }: { active?: boolean }) => {
        const baseText =
          item in translateMap
            ? translateMap[item as keyof typeof translateMap]
            : t(item)

        const stared =
          item === defaultLocale && defaultLocale !== lang ? (
            <span>*</span>
          ) : null

        return (
          <a
            href={routeParseAbsolute(pathname === '/' ? '' : pathname, item)}
            className={classNames(
              `flex w-full text-sm px-4 py-2 items-center transition duration-150 ease-in-out hover:bg-gray-200 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-50`,
              active ? 'bg-gray-200 dark:bg-gray-700' : ''
            )}
          >
            {baseText} {stared}
          </a>
        )
      }}
    </Component>
  )
}

export const PopoverBtn = memo(PopoverBtnComponent)
