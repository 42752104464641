import { useMemo } from 'react'

// more menu base hook
export const useMoreMenu = (targetUrl?: string) => {
  return useMemo(() => {
    const encodedUrl = targetUrl ? encodeURIComponent(targetUrl) : ''

    const link = targetUrl ? `/website-details?url=${encodedUrl}` : ''
    const report = targetUrl ? `/reports/${encodedUrl}` : ''
    const downloadL = targetUrl ? `/download-data-export?url=${encodedUrl}` : ''

    return [link, report, downloadL]
  }, [targetUrl])
}
