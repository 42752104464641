// date to locale string
export const getDateLocale = (
  date: Date,
  lang?: string,
  dateConfiguration?: Intl.DateTimeFormatOptions
) => {
  let nextDate = ''

  if (dateConfiguration && lang === 'ar') {
    dateConfiguration.numberingSystem = 'arabic'
  }

  try {
    nextDate = date.toLocaleDateString(lang, dateConfiguration)
  } catch (e) {
    //
  }

  if (!nextDate) {
    try {
      nextDate = date.toLocaleDateString('en', dateConfiguration)
    } catch (e) {
      console.error(e)
    }
  }

  return nextDate
}
