import { FC, PropsWithChildren } from 'react'
import { classNames } from '@app/utils/classes'

type SectionContainerProps = PropsWithChildren<{
  className?: string
  id?: string
  gapY?: boolean | 'small'
  gapX?: boolean
  spaceX?: boolean
  container?: boolean
  block?: boolean
}>

const SectionContainer: FC<SectionContainerProps> = ({
  children,
  className,
  gapY = true,
  gapX = true,
  spaceX = true,
  container,
  block,
  ...props
}) => {
  return (
    <section
      className={classNames(
        gapY === 'small' ? 'pt-10 md:pt-14' : gapY ? 'pt-16 md:pt-24' : ''
      )}
      {...props}
    >
      <div className={classNames(className || '', spaceX ? 'px-4' : '')}>
        {container ? (
          <div
            className={classNames(
              `block md:flex place-items-center pb-0`,
              gapX ? 'gap-x-12' : ''
            )}
          >
            {block ? <div className={'flex-1 pb-4'}>{children}</div> : children}
          </div>
        ) : (
          children
        )}
      </div>
    </section>
  )
}

export { SectionContainer }
