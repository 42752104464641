export const translateMap = {
  en: 'English',
  es: 'Español',
  'pt-PT': 'Português',
  'pt-BR': 'Português (Brazil)',
  hi: 'हिन्दी',
  'zh-CN': '简体中文',
  'zh-TW': '繁體中文',
  de: 'Deutsch',
  fr: 'Français',
  ru: 'Русский',
  ja: '日本語',
  ar: 'عربي',
}
