import { memo } from 'react'
import { InfoBlock } from '../info-block'
import { useWebsiteContext } from '@app/components/providers/website'
import { RunnerSelect } from '../../runner-select'

const RunnersBoxWrapper = ({
  url,
  runners,
  activeSubscription,
  translation,
}: {
  url?: string
  runners?: string[]
  activeSubscription?: boolean
  translation?: string
}) => {
  const { updateWebsite } = useWebsiteContext()

  const onChangeEvent = async (selected: string[]) => {
    try {
      await updateWebsite({
        variables: { url, runners: selected },
      })
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <InfoBlock notrunc>
      <RunnerSelect
        cb={onChangeEvent}
        disabled={!activeSubscription}
        defaultRunners={runners ?? []}
        borderLess
        translation={translation}
      />
    </InfoBlock>
  )
}

export const RunnersBox = memo(RunnersBoxWrapper)
