import React from 'react'
import { useGoogleLogin } from '@react-oauth/google'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { GrGoogle } from 'react-icons/gr'

interface GoogleLoginButton {
  onSuccess: (a: any) => Promise<void>
  loginView?: boolean
  skeleton?: boolean // skeleton ui
  round?: boolean
  className?: string
}

// returns google login component if id enabled or mock
export const GoogleLoginButton = (props: GoogleLoginButton) => {
  const { t } = useTranslation()
  const { onSuccess, loginView, className } = props ?? {}

  const login = useGoogleLogin({
    onSuccess,
    flow: 'implicit',
    scope: 'email',
  })

  const withText = t('auth:with')

  const textValue = loginView
    ? `${t('common:login')} ${withText} Google`
    : `${t('common:register')} ${withText} Google`

  return (
    <button type={'button'} onClick={login as any} className={className}>
      <GrGoogle className='grIcon text-lg' />
      <div className='min-w-[120.61px] rendering-legibility'>{textValue}</div>
    </button>
  )
}
