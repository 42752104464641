import React, { Dispatch, SetStateAction, useState } from 'react'
import { classNames } from '@app/utils/classes'
import { GrLock, GrSort } from 'react-icons/gr'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { headStyle } from '@app/components/general/cells/card/head-style'
import { LiveDataButton } from '@app/components/general/buttons/live-button'
import { SortDirection } from '@app/components/general/dashboard-website-list'
import { useWebsiteContext } from '@app/components/providers/website'
import { Popover, PopoverTrigger } from '@app/@/components/ui/popover'
import { PopoverContent } from '@radix-ui/react-popover'
import { Button } from '@app/@/components/ui/button'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@app/@/components/ui/select'
import { Switch } from '@app/@/components/ui/switch'
import { Label } from '@app/@/components/ui/label'
import { useSortDirection } from './use-sort-direction'
import { MenuIcon, XIcon } from 'lucide-react'
import {
  TooltipProvider,
  TooltipTrigger,
  TooltipContent,
  Tooltip,
} from '@app/@/components/ui/tooltip'

type PageBarProps = {
  setExtraActive(x: boolean | 'edit'): void
  setLiveData?:
    | Dispatch<SetStateAction<boolean>>
    | ((x: (xx: boolean) => boolean) => void)
  setFilterActive(x?: 'errors' | 'warnings'): void
  onSortEvent?(_: string, __: SortDirection): void
  active: boolean | string // left tab
  filterActive?: string // right tab
  activeSubscription?: boolean
  translation?: string
  storageless?: boolean
  blockLive?: boolean // switch to view default state
  liveData?: boolean
  pageUrl?: string
}

export const PageIssuesBar = ({
  active,
  activeSubscription,
  translation,
  storageless,
  filterActive,
  liveData,
  blockLive,
  setExtraActive,
  setFilterActive,
  setLiveData,
  pageUrl,
}: PageBarProps) => {
  const { onSortEvent } = useWebsiteContext()
  const [sortType, setSortType] = useState<string>()
  const [sortDirectionType, setSort] = useState<SortDirection>('desc')

  const { t } = useTranslation(translation || 'dashboard')

  const variables = useSortDirection(pageUrl)

  const onTogglePages = () => setExtraActive(false)
  const onToggleMore = () => setExtraActive(true)
  const onToggleEdit = () => setExtraActive('edit')

  const onValueChange = (e: string) => {
    setSortType(e)
  }

  const onToggleSort = () => {
    if (onSortEvent) {
      onSortEvent(pageUrl || '', sortDirectionType, sortType || '')
    }
  }

  const onClearSort = () => {
    if (onSortEvent) {
      onSortEvent(pageUrl || '', undefined, undefined)
      setSortType('')
    }
  }

  const onFilterErrors = () =>
    setFilterActive(filterActive === 'errors' ? undefined : 'errors')

  const onFilterWarnings = () =>
    setFilterActive(filterActive === 'warnings' ? undefined : 'warnings')

  const onCheckedChange = () => {
    setSort((x) => (x === 'asc' ? 'desc' : 'asc'))
  }

  const isSorting = variables.sort || sortType

  return (
    <TooltipProvider>
      <div className={headStyle}>
        <div className='flex gap-x-3 text-left place-items-center'>
          <Tooltip>
            <TooltipTrigger
              className={classNames(
                !active
                  ? 'underline decoration-blue-700 underline-offset-4 decoration-2'
                  : 'opacity-70',
                'p-0 m-0'
              )}
              onClick={onTogglePages}
              type={'button'}
              disabled={!activeSubscription}
            >
              {t('issues')}
            </TooltipTrigger>
            <TooltipContent>
              {t('issues-cell-tab', { url: pageUrl })}
            </TooltipContent>
          </Tooltip>
          {storageless ? null : (
            <>
              <Tooltip>
                <TooltipTrigger
                  className={classNames(
                    active === true
                      ? 'underline decoration-blue-700 underline-offset-4 decoration-2'
                      : 'opacity-70 flex place-items-center space-x-1',
                    'p-0 m-0',
                    activeSubscription ? '' : 'opacity-50'
                  )}
                  onClick={onToggleMore}
                  type={'button'}
                  disabled={!activeSubscription}
                >
                  {t('more')}

                  {!activeSubscription ? (
                    <GrLock className='grIcon text-xs' />
                  ) : null}
                </TooltipTrigger>
                <TooltipContent>{t('download-tooltip')}</TooltipContent>
              </Tooltip>
              <Tooltip>
                <TooltipTrigger
                  className={classNames(
                    active === 'edit'
                      ? 'underline decoration-blue-700 underline-offset-4 decoration-2'
                      : 'opacity-70 flex place-items-center space-x-1',
                    'p-0 m-0',
                    activeSubscription ? '' : 'opacity-50'
                  )}
                  onClick={onToggleEdit}
                  type={'button'}
                  disabled={!activeSubscription}
                >
                  {t('edit')}
                  {!activeSubscription ? (
                    <GrLock className='grIcon text-xs' />
                  ) : null}
                </TooltipTrigger>
                <TooltipContent>{t('more-tooltip')}</TooltipContent>
              </Tooltip>
              <Tooltip>
                <Popover>
                  <TooltipTrigger asChild>
                    <PopoverTrigger>
                      <span className='sr-only'>{t('sort')}</span>
                      <GrSort className='grIcon text-xs' />
                    </PopoverTrigger>
                  </TooltipTrigger>
                  <TooltipContent>{t('sort-tooltip')}</TooltipContent>
                  <PopoverContent asChild>
                    <div className='z-10 p-3 flex flex-col gap-2 rounded bg-white dark:bg-black border-2 shadow-2xl'>
                      <div
                        className={
                          isSorting
                            ? 'flex gap-6 place-items-center place-content-between'
                            : 'hidden'
                        }
                      >
                        <MenuIcon />
                        <div className='flex items-center space-x-1.5'>
                          <Label htmlFor={`${pageUrl}-sort-direction`}>
                            {t('dashboard-modal:acs')}
                          </Label>
                          <Switch
                            id={`${pageUrl}-sort-direction`}
                            onCheckedChange={onCheckedChange}
                            checked={
                              sortDirectionType === 'desc'
                                ? false
                                : !sortDirectionType ||
                                  sortDirectionType === 'asc'
                            }
                          />
                        </div>

                        <Button
                          type={'button'}
                          onClick={onClearSort}
                          size={'icon'}
                          variant={'secondary'}
                        >
                          <XIcon />
                        </Button>
                      </div>

                      <div className={isSorting ? 'hidden' : 'text-sm'}>
                        <div className='font-semibold'>
                          {t('dashboard-modal:sort-header')}
                        </div>
                        <div>{t('dashboard-modal:sort-subheader')}</div>
                      </div>

                      <Select onValueChange={onValueChange}>
                        <SelectTrigger className='w-[180px]'>
                          <SelectValue placeholder={t('dashboard:errors')} />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup>
                            <SelectLabel>
                              {t('dashboard-modal:set-property')}
                            </SelectLabel>
                            <SelectItem value='warningCount'>
                              {t('dashboard:warnings')}
                            </SelectItem>
                            <SelectItem value='errorCount'>
                              {t('dashboard:errors')}
                            </SelectItem>
                          </SelectGroup>
                        </SelectContent>
                      </Select>

                      <Button onClick={onToggleSort} variant={'outline'}>
                        {t('dashboard-modal:apply-sort')}
                      </Button>
                    </div>
                  </PopoverContent>
                </Popover>
              </Tooltip>
              {liveData && setLiveData ? (
                <LiveDataButton
                  blockLive={blockLive}
                  onToggleLiveData={setLiveData}
                  activeSubscription={activeSubscription}
                  t={t}
                />
              ) : null}
            </>
          )}
        </div>

        <div className='flex flex-1 w-full place-content-end text-right'>
          <div className={`grid grid-cols-2 gap-5 md:gap-[1rem]`}>
            {!active ? (
              <>
                <Tooltip>
                  <TooltipTrigger
                    type={'button'}
                    onClick={onFilterWarnings}
                    className={classNames(
                      'text-right hover:text-gray-800 dark:text-gray-300 hover:underline dark:hover:text-gray-100',
                      filterActive === 'warnings'
                        ? 'underline decoration-yellow-600 decoration-2'
                        : ''
                    )}
                  >
                    {t('warnings')}
                  </TooltipTrigger>
                  <TooltipContent>{t('prioritize-warnings')}</TooltipContent>
                </Tooltip>
                <Tooltip>
                  <TooltipTrigger
                    type={'button'}
                    onClick={onFilterErrors}
                    className={classNames(
                      'text-right hover:text-gray-800 dark:text-gray-300 hover:underline dark:hover:text-gray-100',
                      filterActive === 'errors'
                        ? 'underline decoration-yellow-600 decoration-2'
                        : ''
                    )}
                  >
                    {t('errors')}
                  </TooltipTrigger>
                  <TooltipContent>{t('prioritize-errors')}</TooltipContent>
                </Tooltip>
              </>
            ) : active === 'edit' ? (
              <>
                <div className='text-right dark:text-gray-300'>
                  {t('blacklist')}
                </div>
                <div className='min-w-[3.75rem] dark:text-gray-300'>
                  {t('delete')}
                </div>
              </>
            ) : (
              <>
                <div className='uppercase dark:text-gray-300'>{t('excel')}</div>
                <div className='min-w-[3.75rem] dark:text-gray-300'>
                  {t('csv')}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </TooltipProvider>
  )
}
