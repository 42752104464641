import React from 'react'
import { Issue } from '@app/types'
import { FilterManager } from '@app/managers/filters'
import { observer } from 'mobx-react-lite'
import { classNames } from '@app/utils/classes'

type IssueCellProps = {
  item: Issue
  hideSelector?: boolean
  style?: any
  completed?: boolean
  children?: React.ReactElement
}

// Issue Cell used on Feed after authentication.
const IssueFeedCellContainerComponent = ({
  item,
  style,
  children,
}: IssueCellProps) => {
  const filtered =
    item &&
    FilterManager.filters.has(item.code) &&
    FilterManager.filters.get(item.code)?.checked

  const filterStyles = filtered ? 'grayscale' : ''

  return (
    <div className={classNames('h-[inherit]', filterStyles)} style={style}>
      {children}
    </div>
  )
}

export const IssueFeedCellContainer = observer(IssueFeedCellContainerComponent)
