import { memo } from 'react'
import { ThemeInput } from '../select/theme-input'
import { InfoBlock } from '../cells'

const ThemeInputWrapper = ({
  theme,
  url,
  domain,
  activeSubscription,
  onThemeChange,
}: {
  theme?: string
  url: string
  domain: string
  activeSubscription?: boolean
  demo?: boolean
  onThemeChange?: any
}) => {
  const label = `${domain || url}-theme-type`

  return (
    <InfoBlock>
      <ThemeInput
        theme={theme}
        onStandardChange={onThemeChange}
        disabled={!activeSubscription}
        label={label}
        borderLess
      />
    </InfoBlock>
  )
}

export const SelectThemeInput = memo(ThemeInputWrapper)
