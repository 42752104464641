import { FC, memo, PropsWithChildren } from 'react'
import { WebsiteCellDashboard } from '@app/components/general/cells'
import { Website } from '@app/types'
import { dashboardCellStyle } from '@app/styles/lists/tw'
import { classNames } from '@app/utils/classes'
import { WebsiteListProps } from '@app/types/websitelist'
import { SortDirection } from '../dashboard-website-list'

interface WebsiteDashboardProps extends WebsiteListProps {
  handleClickOpen(data: any, title: any, url: any, error: any): void
}

// Iterate over website dashboard cells
const WebSitesDashboardComponent: FC<
  PropsWithChildren<
    WebsiteDashboardProps & {
      onlyLive?: boolean
      storageless?: boolean
      analyticsEnabled?: boolean
      removeActiveCrawl?(x: any): void
      onSortEvent?(_: string, __: SortDirection): void
    }
  >
> = ({
  data,
  removePress,
  handleClickOpen,
  crawlWebsite,
  activeCrawls,
  children,
  onlyLive,
  storageless,
  analyticsEnabled,
  removeActiveCrawl,
  onSortEvent,
}) => {
  // empty list this logic does not fire with data Container wrapper
  if (!data || !data?.length) {
    return null
  }

  return (
    <ul className={classNames('gap-y-2 rounded divide-y', dashboardCellStyle)}>
      {data.map((props: Website, index: number) => (
        <WebsiteCellDashboard
          onSortEvent={onSortEvent}
          key={props._id}
          index={index}
          activeCrawl={
            activeCrawls
              ? props.domain in activeCrawls && activeCrawls[props.domain]
              : false
          }
          handleClickOpen={handleClickOpen}
          removePress={removePress}
          crawlWebsite={crawlWebsite}
          onlyLive={onlyLive}
          storageless={storageless}
          analyticsEnabled={analyticsEnabled}
          url={props.url as string}
          removeActiveCrawl={removeActiveCrawl}
          {...props}
        />
      ))}
      {children}
    </ul>
  )
}

export const WebSitesDashboard = memo(WebSitesDashboardComponent)
