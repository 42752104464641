import React, { useMemo } from 'react'
import { Skeleton } from '@app/components/placeholders/skeleton'
import { Analytic, Website } from '@app/types'
import {
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
  VictoryTooltip,
  VictoryScatter,
  VictoryVoronoiContainer,
} from 'victory'
import { useLineChart } from './use-chart'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { buildFormatter } from '@app/lib/utils/price-config'
import { useTheme } from '@app/components/themes/theme'

interface LineChartProps {
  title?: string // page title
  data?: Analytic[] | Website[]
}

// web safe colors for charts
const RED_COLOR = '#7c270b'
const RED_FILL_COLOR = 'rgb(216, 0, 12)'
const RED_STROKE_COLOR = 'rgb(248, 113, 113)'
const YELLOW_COLOR = 'rgb(199, 166, 0)'
const YELLOW_FILL_COLOR = 'rgb(250, 204, 21)'
const YELLOW_STROKE_COLOR = 'rgb(253, 224, 71)'

const YELLOW_FILL_COLOR_DARK = 'rgb(255, 255, 0)'
const RED_FILL_COLOR_DARK = 'rgb(255, 0, 0)'

// chart styles
const chartStyles = {
  title: {
    textAnchor: 'start' as any,
    verticalAnchor: 'end',
    fill: 'inherit',
  },
  labelNumber: {
    textAnchor: 'middle',
    fontFamily: 'inherit',
    fontSize: '6px',
    fill: 'inherit',
  },
  axisOne: {
    axis: { stroke: RED_COLOR, strokeWidth: 0 },
    ticks: { strokeWidth: 0 },
    tickLabels: {
      fontFamily: 'inherit',
      fontSize: 6.5,
      fill: 'inherit',
    },
  },
  labelOne: {
    fill: RED_COLOR,
    fontFamily: 'inherit',
    fontSize: 7,
    fontStyle: 'italic',
  },
  lineOne: {
    data: { stroke: RED_COLOR, strokeWidth: 0.8 },
  },
  lineOneScatter: {
    data: { stroke: RED_STROKE_COLOR, strokeWidth: 0.8, fill: RED_FILL_COLOR },
  },
  axisTwo: {
    axis: { stroke: YELLOW_COLOR, strokeWidth: 0 },
    tickLabels: {
      fontFamily: 'inherit',
      fontSize: 6.5,
      fill: 'inherit',
    },
  },
  labelTwo: {
    textAnchor: 'end' as any,
    fill: YELLOW_COLOR,
    fontFamily: 'inherit',
    fontSize: 7,
    fontStyle: 'italic',
  },
  lineTwo: {
    data: { stroke: YELLOW_COLOR, strokeWidth: 0.8 },
  },
  lineTwoScatter: {
    data: {
      stroke: YELLOW_STROKE_COLOR,
      strokeWidth: 0.8,
      fill: YELLOW_FILL_COLOR,
    },
  },
}

// error line chart for warnings, errors TODO: remove VICTORY
export const LineChart = ({ title, data }: LineChartProps) => {
  const { t, lang } = useTranslation('dashboard')

  const numberFormatter = useMemo(() => buildFormatter(lang, true), [lang])

  const {
    first,
    second,
    ticks,
    highestError,
    highestWarning,
    totalErrors,
    totalWarnings,
    determinedType,
  } = useLineChart({ data, numberFormatter })
  const { theme } = useTheme()

  const styles = useMemo(() => {
    const _style = Object.assign({}, chartStyles)

    const yellowColor =
      theme === 'dark' ? YELLOW_FILL_COLOR_DARK : YELLOW_FILL_COLOR
    const redColor = theme === 'dark' ? RED_FILL_COLOR_DARK : RED_FILL_COLOR

    _style.lineOneScatter.data.fill = redColor
    _style.lineTwoScatter.data.fill = yellowColor

    return _style
  }, [theme])

  // display empty loading state
  if (!data) {
    return (
      <div className='h-full w-full flex flex-1'>
        <Skeleton className='h-full w-full min-h-[30vh]' />
      </div>
    )
  }

  const headingText = t('website-analytics-details', {
    errors: numberFormatter.format(totalErrors),
    warnings: numberFormatter.format(totalWarnings),
    websites: numberFormatter.format(ticks.length),
  })

  // empty
  if (totalErrors === 0 && totalWarnings === 0 && ticks.length === 0) {
    return (
      <div className='w-full h-full px-4 flex place-items-center place-content-center'>
        <div className='text-xl'>No data yet!</div>
      </div>
    )
  }

  const analyticsTextTitle = title ?? t('website-analytics')

  // render website chart
  if (determinedType === 'website') {
    return (
      // @ts-ignore
      <VictoryChart
        containerComponent={
          <VictoryVoronoiContainer
            labels={({ datum }) => datum.l}
            labelComponent={<VictoryTooltip style={{ fontSize: 7 }} />}
          />
        }
        padding={{ top: 60, left: 54, right: 54, bottom: 40 }}
        domainPadding={{ y: 44 }}
      >
        <VictoryLabel
          x={12}
          y={18}
          className={'fill-gray-700 font-bold dark:fill-white'}
          text={analyticsTextTitle}
          style={{ fill: 'inherit' }}
        />
        <VictoryLabel
          x={12}
          y={34}
          className={'fill-gray-600 text-sm dark:fill-white'}
          style={{ fill: 'inherit', fontSize: 12, fontWeight: 400 }}
          text={headingText}
        />

        <VictoryAxis
          dependentAxis
          orientation='left'
          domain={[0, highestError]}
          offsetX={35}
          style={styles.axisOne}
          tickLabelComponent={
            <VictoryLabel className='text-xs dark:text-white dark:fill-white' />
          }
        />
        <VictoryAxis
          dependentAxis
          orientation='right'
          domain={[0, highestWarning]}
          style={styles.axisTwo}
          offsetX={35}
          tickLabelComponent={
            <VictoryLabel className='text-xs dark:text-white dark:fill-white' />
          }
        />
        <VictoryScatter data={first} style={styles.lineOneScatter} />
        <VictoryScatter data={second} style={styles.lineTwoScatter} />
      </VictoryChart>
    )
  }

  return (
    // @ts-ignore
    <VictoryChart
      containerComponent={
        <VictoryVoronoiContainer
          labels={({ datum }) => datum.l}
          labelComponent={<VictoryTooltip style={{ fontSize: 7 }} />}
        />
      }
    >
      <svg className='box-border w-full' viewBox='0 0 450 350'>
        <rect
          x='0'
          y='0'
          width='10'
          height='30'
          className={
            totalErrors > totalWarnings ? 'fill-red-600' : 'fill-blue-600'
          }
        />

        <VictoryLabel
          x={25}
          y={24}
          style={styles.title}
          className={'fill-gray-700 font-semibold dark:fill-gray-100'}
          text={analyticsTextTitle}
        />
        <VictoryLabel
          x={25}
          y={33.5}
          className={'fill-gray-600 text-sm dark:fill-white'}
          style={{ fontSize: 10, fill: '#525252' }}
          text={headingText}
        />

        <VictoryLabel
          x={25}
          y={46}
          style={styles.labelOne}
          text={t('errors')}
        />
        <VictoryLabel
          x={425}
          y={46}
          style={styles.labelTwo}
          text={t('warnings')}
        />
        <g transform={'translate(0, 33)'}>
          <VictoryAxis
            dependentAxis
            offsetX={20}
            orientation='left'
            standalone={false}
            domain={[0, highestError]}
            style={styles.axisOne}
            tickLabelComponent={
              <VictoryLabel className='text-xs dark:text-white dark:fill-white' />
            }
          />
          <VictoryAxis
            dependentAxis
            offsetX={20}
            orientation='right'
            domain={[0, highestWarning]}
            standalone={false}
            style={styles.axisTwo}
            tickLabelComponent={
              <VictoryLabel className='text-xs dark:text-white dark:fill-white' />
            }
          />
          <VictoryLine
            data={first}
            standalone={false}
            padding={20}
            style={styles.lineOne}
          />
          <VictoryLine
            data={second}
            standalone={false}
            style={styles.lineTwo}
            padding={20}
          />
        </g>
      </svg>
    </VictoryChart>
  )
}
