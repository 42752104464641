import React from 'react'
import dynamic from 'next/dynamic'
import { ListSkeleton } from '@app/components/placeholders'

// EMPTY display of website
const EmptyWebsiteForm = dynamic(
  () =>
    import('../general/website/empty-form').then((mod) => mod.EmptyWebsiteForm),
  {
    loading: () => (
      <div className='flex flex-col w-full place-items-center py-10 my-2 text-center'>
        <p>Loading...</p>
      </div>
    ),
  }
)

interface PageLoader {
  loading?: boolean
  empty?: boolean // has data
  children?: any
  emptyTitle?: string
  emptySubTitle?: string
  hasWebsite?: boolean
  error?: any
  goToPayments?: boolean // navigate to payments page on empty display
}

export const PageLoader = ({
  loading,
  empty,
  children,
  emptyTitle,
  emptySubTitle,
  hasWebsite = false,
  error = false,
  goToPayments = false,
}: PageLoader) => {
  // when empty display loaders
  if (empty) {
    // loader for list skeleton
    if (loading) {
      return <ListSkeleton />
    }

    if (!loading && !error) {
      return (
        <EmptyWebsiteForm
          emptyHeaderTitle={emptyTitle}
          emptyHeaderSubTitle={emptySubTitle}
          hasWebsite={hasWebsite}
          goToPayments={goToPayments}
        />
      )
    }

    // TODO: use error translations
    if (!loading && error) {
      return (
        <EmptyWebsiteForm
          emptyHeaderTitle={'An Error occurred, please try to again later'}
          emptyHeaderSubTitle={
            'If the issue persist please contact support or try to reload your browser.'
          }
          hasWebsite={hasWebsite}
          goToPayments={goToPayments}
        />
      )
    }
  }

  return children || null
}
