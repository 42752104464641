import React from 'react'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { companyName } from '@app/configs'

export const PricingNeedMore = () => {
  const { t } = useTranslation('price')

  return (
    <div className='text-center py-4 text-sm'>
      <div>
        {t('price-excerpt')}{' '}
        <a
          href='mailto:sales@a11ywatch.com'
          className='underline hover:text-blue-600'
        >
          {t('get-in-touch')}
        </a>{' '}
        {t('for-quote')}
      </div>
      <div className='py-1 text-xs'>
        <b>{companyName}</b> {t('contribute')}
      </div>
    </div>
  )
}
