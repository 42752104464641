import { memo } from 'react'
import { Timer, TestViewRest } from '@app/components/general'
import { CtaCdn } from '@app/components/cta'
import { InfoBar } from './info-bar'
import { WebsiteSecondary } from '../general/cells/render/website-secondary'
import { FeedList } from '../feed/list'
import type { Website } from '@app/types'
import { PDFReport } from './pdf-report'
import dynamic from 'next/dynamic'

const TestViewPdf = dynamic(
  import('../general/test-view-pdf').then((mod) => mod.TestViewPdf),
  {
    ssr: false,
  }
)

// container view for report or frame
const MainView = ({
  website,
  viewMode,
  partial,
}: {
  website: Website | PDFReport
  viewMode: 'list' | ''
  partial?: boolean
}) => {
  const pdfReport = website && 'validationSummary' in website

  if ((website && 'url' in website) || pdfReport) {
    if (viewMode === 'list') {
      return (
        <FeedList
          issue={website as any}
          isHidden={false}
          fullScreen
          partial={partial}
          pdfReport={pdfReport}
        />
      )
    }

    if (pdfReport) {
      return <TestViewPdf pdf={website as PDFReport} />
    }

    return (
      <TestViewRest url={website.url} marketing posRelative website={website} />
    )
  }

  return null
}

export const ReportViewComponentLeft = ({
  website = {},
  closeButton,
  disablePlayground,
  printable,
  download,
  authenticated,
  viewMode,
  onToggleViewModeEvent,
  activeSubscription,
  lang,
  t,
  empty,
}: any) => {
  const title = website?.url || (website?.issues?.length ? 'HTML Audit' : '')
  const pdfView = website && 'result' in website

  const websiteCrawlDuration =
    'crawlDuration' in website ? website.crawlDuration : null
  const pdfScanDuration =
    pdfView && website.result.length
      ? website.result[0].processingTime.finish -
        website.result[0].processingTime.start
      : 0

  return (
    <div className={'p-1 md:w-[38vw] dark:bg-black'}>
      <div className={`flex place-items-center py-3 gap-x-3 px-2`}>
        {closeButton}
        <p className={'flex-1 font-medium truncate text-lg'}>{title}</p>
        <Timer
          stop={!empty}
          duration={websiteCrawlDuration || pdfScanDuration}
          lang={lang}
          t={t}
        />
      </div>
      <div className='px-2 pb-2'>
        <div className='flex gap-x-2 place-items-center'>
          <div className='max-w-[85%]'>
            {empty || !download ? null : (
              <WebsiteSecondary {...website} pageIssueCount={1} />
            )}
          </div>
        </div>
        <CtaCdn
          website={website}
          disablePlayground={disablePlayground}
          authenticated={authenticated}
          dashboard={download}
        />
      </div>
      <InfoBar
        website={website}
        printable={printable}
        download={activeSubscription && download}
        onToggleViewModeEvent={onToggleViewModeEvent}
        pdfView={pdfView}
      />
      {empty ? null : (
        <div className='hidden md:block h-full' id={'report-left-main'}>
          <MainView website={website} viewMode={viewMode} partial />
        </div>
      )}
    </div>
  )
}

export const ReportViewLeft = memo(ReportViewComponentLeft)
