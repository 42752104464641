import React from 'react'
import { GrGithub } from 'react-icons/gr'
import type { BadgeProps } from './badge-types'
import { companyName } from '@app/configs'

// TODO: translate to language
export const GithubBadge = (props: Partial<BadgeProps>) => (
  <a
    href={process.env.NEXT_PUBLIC_GITHUB_URL}
    rel='noreferrer'
    target='_blank'
    className={props?.className}
  >
    <span className='sr-only'>{`${companyName} GitHub's organization`}</span>
    <GrGithub className={'grIcon'} />
  </a>
)
