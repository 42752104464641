import React from 'react'
import { Link } from '@app/components/stateless/typo/link'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { routeParseAbsolute } from '@app/lib/router-handler'
import { classNames } from '@app/utils/classes'
import { mainBaseCSS, mainBaseResPX } from '@app/styles/container'
import { activeHover, secondaryStyles } from './styles'
import { twMerge } from 'tailwind-merge'
import { FILTERED_MARKETING, SUPPORT_EMAIL } from '@app/configs/app-config'

const headingStyle = 'm-0 font-bold max-w-none text-xl md:text-2xl py-0 md:py-1'

// the header for the marketing display
const GetMarketingHeader = ({
  text,
  headerType,
}: {
  text: string
  headerType?: string
}) => {
  const MHeader: any = headerType ?? 'h3'

  return <MHeader className={headingStyle}>{text}</MHeader>
}

export const MarketingAlternatives = ({
  headerType,
  disabled,
}: {
  headerType?: string
  disabled?: boolean
}) => {
  const { t, lang } = useTranslation('common')

  return (
    <div className='pt-14'>
      <div className={classNames(secondaryStyles, 'py-6')}>
        <div
          className={classNames('max-w-screen-xl mx-auto px-3', mainBaseResPX)}
        >
          <div
            className={classNames(
              'flex flex-1 flex-col md:flex-row space-between place-items-center gap-4',
              mainBaseCSS
            )}
          >
            <div className='flex-1 flex flex-col space-y-1 text-center md:text-left'>
              <GetMarketingHeader
                text={t('wave-intro')}
                headerType={headerType}
              />
              <p className='md:text-lg leading-normal'>{t('wave-details')}</p>
            </div>
            {disabled ? null : (
              <div className='flex flex-1 md:place-content-end'>
                <Link
                  className={twMerge(
                    `px-8 py-4 rounded bg-white text-black dark:bg-gray-900 dark:text-gray-50`,
                    `tracking-wider font-medium hover:no-underline`,
                    activeHover
                  )}
                  href={
                    FILTERED_MARKETING
                      ? `mailto:${SUPPORT_EMAIL}`
                      : routeParseAbsolute(`/pricing`, lang)
                  }
                >
                  {t('get-started')}
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
