import { DOMAIN_NAME } from '@app/configs/app-config'

// needs to parse route to absolute domain with language code
export const routeParseAbsolute = (
  path?: string,
  lang?: string,
  r?: boolean
) =>
  !lang
    ? `${DOMAIN_NAME}${path || ''}`
    : `${DOMAIN_NAME}${lang === 'en' && !r ? '' : `/${lang}`}${path || ''}`
