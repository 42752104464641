import { useQuery } from '@apollo/client'
import { GET_WEBSITE_ANALYTICS } from '@app/queries'
import { useSearchQuery } from '@app/data/local/useSearchQuery'

// get analytics paginated by website
export const useAnalyticsData = (
  url?: string | string[],
  all?: boolean,
  skip?: boolean,
  filterActive?: 'warnings' | 'errors',
  customVariables?: Record<string, any>
) => {
  const { search, visible, onSearchEvent, onSearchOpenEvent } = useSearchQuery()

  const variables = {
    url,
    all,
    search,
    filter: filterActive,
    limit: 10,
    offset: 0,
    ...(customVariables ?? {}),
  }

  const {
    data,
    loading,
    refetch,
    error,
    fetchMore: fetchMorePages,
  } = useQuery(GET_WEBSITE_ANALYTICS, {
    variables,
    ssr: false,
    skip,
  })

  const updateQuery = (prev: any, { fetchMoreResult }: any) => {
    const nextAnalytics = fetchMoreResult?.website?.analytics || []

    if (!fetchMoreResult || !nextAnalytics?.length) {
      return prev
    }

    const analytics = [...(prev?.website?.analytics || []), ...nextAnalytics]

    return Object.assign({}, prev, {
      website: {
        ...prev?.website,
        analytics,
      },
    })
  }

  const analytics = data?.website?.analytics

  // pages page pagination
  const onLoadMore = async () => {
    try {
      await fetchMorePages({
        query: GET_WEBSITE_ANALYTICS,
        variables: {
          ...variables,
          offset: Number((analytics && analytics.length) || 0),
          search:
            search && search?.length > 400 ? search.substring(0, 400) : search,
        },
        updateQuery,
      })
    } catch (e) {
      console.error(e)
    }
  }

  return {
    data: analytics,
    loading: loading,
    refetch,
    error,
    searchVisible: visible,
    search,
    onLoadMore,
    onSearchEvent,
    onSearchOpenEvent,
  }
}
