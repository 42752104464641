import React, { memo, useCallback, useEffect, useState } from 'react'
import { useWebsiteContext } from '@app/components/providers/website'
import { RootSelectorInput } from '../../inputs/root-selector-input'
import type { InputBoxProps } from '@app/types/dashboard'

export const RootSelectorBoxWrapper = ({
  rootElement: currentRootSelector,
  url,
  activeSubscription,
}: InputBoxProps & { rootElement?: string }) => {
  const [rootElement, setRootSelector] = useState<string | undefined>(
    currentRootSelector || ''
  )
  const { updateWebsite } = useWebsiteContext()

  // todo: add snackbar message for starting selector validation
  const onChangeRootSelector = (e: React.ChangeEvent<any>) => {
    const nextValue = e?.target?.value
    const intValue = nextValue && isNaN(nextValue[0])

    if (nextValue && !intValue) {
      return
    }

    setRootSelector(nextValue)
  }

  const onMutate = useCallback(async () => {
    if (currentRootSelector !== rootElement) {
      try {
        await updateWebsite({
          variables: { url, rootElement },
        })
      } catch (e) {
        console.error(e)
      }
    }
  }, [url, rootElement, currentRootSelector, updateWebsite])

  useEffect(() => {
    if (rootElement === currentRootSelector) {
      return
    }

    const debounce = setTimeout(onMutate, 2300)

    return () => clearTimeout(debounce)
  }, [rootElement, currentRootSelector, onMutate])

  return (
    <RootSelectorInput
      onChange={onChangeRootSelector}
      value={rootElement}
      url={url}
      activeSubscription={activeSubscription}
    />
  )
}

export const RootSelectorBox = memo(RootSelectorBoxWrapper)
