import { memo, useState } from 'react'
import { useWebsiteContext } from '@app/components/providers/website'
import { TLDInput } from '../../inputs/tld-input'

const TLDBoxWrapper = ({
  tld,
  url,
  domain,
  activeSubscription,
  demo,
}: {
  tld?: boolean
  url?: string
  domain?: string
  activeSubscription?: boolean
  demo?: boolean
}) => {
  const [tldEnabled, setTLD] = useState<boolean>(!!tld)
  const { updateWebsite } = useWebsiteContext()

  const onChangeEvent = async () => {
    let nextValue = !tldEnabled
    setTLD(nextValue)

    if (!demo) {
      try {
        await updateWebsite({
          variables: { url, tld: nextValue },
        })
      } catch (e) {
        console.error(e)
      }
    }
  }

  return (
    <TLDInput
      tld={tldEnabled}
      activeSubscription={activeSubscription}
      onChange={onChangeEvent}
      domain={domain}
    />
  )
}

export const TLDBox = memo(TLDBoxWrapper)
