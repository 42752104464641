import { useEffect, useRef, useState } from 'react'

// determine if the browser is being resized.
export const useIsResizing = () => {
  const [isResizing, setIsResizing] = useState<boolean>(false)
  const resizeTimer = useRef<NodeJS.Timeout | number | undefined>()

  useEffect(() => {
    const handleResizeStart = () => {
      clearTimeout(resizeTimer.current)
      setIsResizing(true)
    }

    const handleResizeEnd = () => {
      clearTimeout(resizeTimer.current)
      resizeTimer.current = setTimeout(() => {
        setIsResizing(false)
      }, 500)
    }

    const onResize = () => {
      handleResizeStart()
      handleResizeEnd()
    }

    window.addEventListener('resize', onResize)

    return () => {
      clearTimeout(resizeTimer.current) // Clear any pending timeouts to cleanup
      window.removeEventListener('resize', onResize)
    }
  }, [])

  return isResizing
}
