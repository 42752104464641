export const downloadFile = (data: string, exportFilename: string) => {
  const csvData = new Blob([data], {
    type: `${
      exportFilename && exportFilename.endsWith('.json')
        ? 'text/json'
        : 'text/csv'
    };charset=utf-8;`,
  })

  // @ts-ignore
  if (navigator.msSaveBlob) {
    // @ts-ignore
    navigator.msSaveBlob(csvData, exportFilename)
  } else {
    // In FF link must be added to DOM to be clicked
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(csvData)
    link.setAttribute('download', exportFilename)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}
