import { observable, action, computed, IMapEntry } from 'mobx'
import { useStaticRendering as enableMobxStaticRendering } from 'mobx-react-lite'
import type { PageIssue } from '@app/types'

enableMobxStaticRendering(typeof window === 'undefined')

export interface FilterItem {
  checked?: boolean
}

// todo: map of pages issue happened between to get more details on where.

export interface GroupItem {
  count: number
  issue: PageIssue
  pages: Set<string>
}

class FilterManager {
  @observable
  visible = false

  // @observable
  grouping = false

  filters = observable<string, FilterItem>(new Map())
  groups = observable<string, GroupItem>(new Map())

  @action
  toggleVisibility = () => {
    this.visible = !this.visible
  }

  // @action
  toggleGrouping = () => {
    this.grouping = !this.grouping
  }

  @action
  addFilter = (item: string) => {
    if (!this.filters.has(item)) {
      this.filters.set(item, { checked: false })
    }
  }

  @action
  filterAll = () => {
    for (const item of this.filters) {
      item[1].checked = true
    }
  }

  @action
  unfilterAll = () => {
    for (const item of this.filters) {
      item[1].checked = false
    }
  }

  @action
  addGroupItem = (item: string, issue: PageIssue, page: string) => {
    if (!this.groups.has(item)) {
      const pages = new Set<string>()
      pages.add(page)

      this.groups.set(item, { count: 0, issue, pages })
    } else {
      // valid item exits
      const gitem = this.groups.get(item)

      if (gitem) {
        gitem.pages.add(page)
        gitem.count += 1
      }
    }
  }

  @action
  toggleFilter = (item: string) => {
    if (this.filters.has(item)) {
      const findItem = this.filters.get(item)

      if (findItem) {
        findItem.checked = !findItem.checked
      }
    }
  }

  @action clearFilters = () => {
    this.filters.clear()
  }

  @action clearGroups = () => {
    this.groups.clear()
  }

  @action sortGroups = () => {
    this.groups.replace(
      new Map(
        [...this.groups]
          .sort(
            (
              a: IMapEntry<string, GroupItem>,
              b: IMapEntry<string, GroupItem>
            ) => {
              const [key, value] = a
              const [keyB, valueB] = b

              const { count } = value ?? {
                count: 0,
              }

              const { count: bCount } = valueB ?? {
                count: 0,
              }

              return count - bCount - (key.length > keyB.length ? 1 : 0)
            }
          )
          .reverse()
      )
    )
  }

  @computed
  get filterList() {
    return [...this.filters.keys()]
  }

  @computed
  get groupList() {
    return [...this.groups.keys()]
  }

  @computed
  get groupCount() {
    return this.groups.size
  }
}

const manager = new FilterManager()

export { manager as FilterManager }
