import { SyntheticEvent } from 'react'
import { observer } from 'mobx-react-lite'
import Trans from 'next-translate/Trans'
import { useTranslation } from '@app/lib/hooks/useTranslation'

import { AppManager } from '@app/managers'
import { Link } from '@app/components/stateless/typo/link'
import { Button } from './buttons'
import { classNames } from '@app/utils/classes'
import { routeParseAbsolute } from '@app/lib/router-handler'
import { useRouter } from 'next/router'
import { dataErrors } from '@app/data/static/errors'
import { blueLink } from '../stateless/typo/link-style'

interface SnackProps {
  topLevel?: boolean
  snackID?: string
  adjustPlacement?: boolean // adjust the position to avoid overlaps
}

const SnackBarComponent = ({
  topLevel,
  snackID,
  adjustPlacement,
}: SnackProps) => {
  const router = useRouter()
  const { t } = useTranslation('common')

  // close the snackbar
  const handleClose = (
    event: SyntheticEvent<any, Event>,
    reason: string
  ): any => {
    event?.preventDefault()
    event?.stopPropagation()
    if (reason === 'clickaway') {
      return
    }
    AppManager.closeSnack()
  }

  const snackOpen =
    !!AppManager.snackbar.open && !(topLevel && AppManager.modalActive)

  const currentMessage = AppManager.snackbar.title

  // AUTO TRANSLATE ERROR
  const translatedMessage =
    currentMessage in dataErrors ? (
      <Trans
        i18nKey={`errors:${dataErrors[currentMessage]}`}
        components={{
          component: (
            <p key={'snack-1'} className={`md:text-base line-clamp-2`} />
          ),
          first: (
            <Link
              className={blueLink}
              key={'snack-2'}
              href={routeParseAbsolute('/payments', router.locale)}
              locale={router.locale}
            />
          ),
        }}
      />
    ) : (
      currentMessage
    )

  const targetID = snackID ?? 'app-snack'

  return (
    <div
      className={classNames(
        adjustPlacement ? 'bottom-36' : 'bottom-5',
        topLevel ? 'z-50' : 'z-40',
        snackOpen
          ? `transition transform scale-100 fixed inset-x-0 px-1.5 translate-y-0 ease-out duration-500`
          : 'hidden'
      )}
      role={'alert'}
      aria-live='assertive'
      aria-hidden={!snackOpen}
      aria-describedby={targetID}
    >
      <div
        className={classNames(
          AppManager.snackbar.type === 'error' ? 'border-red-500' : '',
          'max-w-screen-xl mx-auto md:text-base min-w-[50vw] md:min-w-[35vw]',
          `text-sm flex rounded space-x-4 px-4 py-3 place-items-center place-content-between border bg-white dark:bg-black`
        )}
      >
        <div
          className={classNames(
            !AppManager.snackbar.autoClose ? 'max-w-[70vw]' : '',
            AppManager.snackbar.type === 'error' ? 'text-red-600' : '',
            'overflow-hidden'
          )}
          id={targetID}
        >
          {snackOpen ? translatedMessage : null}
        </div>
        {!AppManager.snackbar.autoClose ? (
          <Button
            onClick={handleClose}
            border={false}
            className='hover:text-gray-700 dark:hover:text-gray-300 hover:opacity-0.8'
            disabled={!AppManager.snackbar.open}
          >
            {t('dismiss')}
          </Button>
        ) : null}
      </div>
    </div>
  )
}

export const SnackBar = observer(SnackBarComponent)
