import { classNames } from '@app/utils/classes'
import { PropsWithChildren } from 'react'
import { Header } from './header'

type PageTitleProps = {
  title?: string
  rightButton?: JSX.Element | null
  className?: string
  id?: string
  subTitle?: string
}

const classes = {
  title: 'text-xl md:text-2xl lg:text-3xl xl:text-4xl',
  topBlock: 'overflow-none',
}

const PageTitle = ({
  title = '',
  rightButton = null,
  children,
  className = '',
  id,
  subTitle,
}: PropsWithChildren<PageTitleProps>) => {
  const renderTitle = title || children

  if (!renderTitle) {
    return null
  }

  // render a right button next to the page like drop down menus
  if (rightButton) {
    return (
      <>
        <div
          className={classNames(
            className,
            classes.title,
            `flex items-center justify-between flex-wrap`
          )}
        >
          <Header className={classes.title}>{renderTitle}</Header>
          {rightButton}
        </div>
        {subTitle ? <p className='w-2/3'>{subTitle}</p> : null}
      </>
    )
  }

  return (
    <>
      <Header
        id={id}
        className={classNames(className, classes.title, classes.topBlock)}
      >
        {renderTitle}
      </Header>
      {subTitle ? <p className='w-2/3'>{subTitle}</p> : null}
    </>
  )
}

export { PageTitle }
