import React, { SetStateAction } from 'react'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { headStyle } from '@app/components/general/cells/card/head-style'
import { LiveDataButton } from '@app/components/general/buttons/live-button'

export const PageBar = ({
  translation,
  liveData,
  setLiveData,
  blockLive,
  activeSubscription,
  storageless,
}: {
  translation?: string
  blockLive?: boolean
  liveData?: boolean
  setLiveData?: React.Dispatch<SetStateAction<boolean>>
  activeSubscription?: boolean
  storageless?: boolean
}) => {
  const { t } = useTranslation(translation || 'dashboard')
  const onToggleLiveData = () => setLiveData && setLiveData((x) => !x)

  return (
    <div className={headStyle}>
      <div className='flex gap-x-2 place-items-center'>
        <div className='text-left'>{t('pages')}</div>
        {!storageless && liveData && onToggleLiveData ? (
          <LiveDataButton
            blockLive={blockLive}
            onToggleLiveData={onToggleLiveData}
            activeSubscription={activeSubscription}
            t={t}
          />
        ) : null}
      </div>
      <div className='flex flex-1 w-full place-content-end text-right'>
        <div className='grid grid-cols-2'>
          <div>{t('online')}</div>
          <div className='min-w-[4.45rem]'>{t('ttl')}</div>
        </div>
      </div>
    </div>
  )
}
