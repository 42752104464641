import React, { memo, useState, FC, useEffect, useMemo } from 'react'
import { FixedSizeList as List } from 'react-window'
import { IssueFeedCell } from '../general/cells'
import { issueExtractor } from '@app/utils'
import { FeedHeading } from './heading'
import type { FeedComponentProps } from './interface'
import { getListHeight } from './utils'
import { FilterManager } from '@app/managers/filters'
import { Header3 } from '../general/header'
import { classNames } from '@app/utils/classes'
import { PdfFeedCell } from '../general/cells/pdf-feed-cell'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { PDFReport } from '../ada/pdf-report'
import { IssueData, PageIssue } from '@app/types'

interface RowProps {
  index: number
  style?: React.CSSProperties
}

const overlayBG = 'bg-[rgba(172,182,192,0.06)] dark:bg-black w-full h-full'

// List of issues rendered.
const FeedListComponent: FC<
  FeedComponentProps & { partial?: boolean; pdfReport?: boolean }
> = ({
  onScanEvent,
  issue,
  isHidden,
  fullScreen,
  highlightErrors,
  partial,
  pdfReport,
}) => {
  const { t } = useTranslation()
  const [sectionHidden, onToggleSection] = useState<boolean>(!!isHidden)

  const pageIssues = useMemo(() => {
    return pdfReport
      ? (issue as PDFReport)?.result ?? []
      : issueExtractor(issue) || []
  }, [issue, pdfReport])

  useEffect(() => {
    if (pageIssues && !pdfReport) {
      for (const item of pageIssues as PageIssue[]) {
        FilterManager.addFilter(item?.code)
      }
    }
  }, [pageIssues, pdfReport])

  const Row = ({ index, style }: RowProps) =>
    pdfReport ? (
      <PdfFeedCell item={pageIssues[index] as any} style={style} t={t} />
    ) : (
      <IssueFeedCell item={pageIssues[index] as PageIssue} style={style} />
    )

  const issueCount = pageIssues ? pageIssues.length : 0

  const { size, height } = getListHeight({ fullScreen, issueCount, partial })

  // FULL SCREEN RENDERING
  if (fullScreen) {
    if (!issueCount) {
      return (
        <div
          className={classNames(
            overlayBG,
            'place-items-center place-content-center flex p-3'
          )}
        >
          <Header3>{t('audit-form:no-issues')}</Header3>
        </div>
      )
    }

    // if window is smaller in list - TODO: resize observer
    const listH = partial && height < 944 ? height - 300 : height

    return (
      <div className={overlayBG}>
        <List
          height={listH}
          itemCount={issueCount}
          itemSize={size}
          width={'100%'}
        >
          {Row}
        </List>
      </div>
    )
  }

  return (
    <li>
      <FeedHeading
        onScanEvent={onScanEvent}
        onToggleSection={onToggleSection}
        sectionHidden={sectionHidden}
        pageUrl={(issue as IssueData)?.pageUrl}
        totalIssues={(issue as IssueData)?.issues?.length || 0}
        highLight={
          !!(
            highlightErrors &&
            (issue as IssueData)?.issues?.length &&
            (issue as IssueData).issues[0]?.type === 'error'
          )
        }
      />
      {sectionHidden ? null : (
        <div className={`overflow-x-hidden bg-gray-50 dark:bg-gray-900`}>
          <List
            height={height}
            itemCount={issueCount}
            itemSize={size}
            width={'100%'}
          >
            {Row}
          </List>
        </div>
      )}
    </li>
  )
}

export const FeedList = memo(FeedListComponent)
