import React from 'react'
import { observer } from 'mobx-react-lite'
import { Jobs } from '@app/components/ada/pdf-report'
import { GrAlert, GrCheckmark } from 'react-icons/gr'
import { Paragraph } from '@app/components/stateless/typo/paragraph'
import { Translate } from 'next-translate'

type PdfFeedCellProps = {
  item: Jobs
  hideSelector?: boolean
  style?: any
  completed?: boolean
  t: Translate
}

// Pdf Cell used on Feed after authentication.
const PdfFeedCellComponent = ({ item, style, t }: PdfFeedCellProps) => {
  const validationResult = item?.validationResult ?? {}
  const details = validationResult?.details?.ruleSummaries ?? []

  return (
    <div style={style}>
      <div className='px-4 py-3'>
        <div className='flex space-x-2 place-items-center'>
          {validationResult.compliant ? (
            <>
              <GrCheckmark className='grIcon' />
              <div>{t('audit-form:pdf-compliant')}</div>
            </>
          ) : (
            <>
              <GrAlert className='grIcon' />
              <div>{t('audit-form:pdf-nocompliant')}</div>
            </>
          )}
        </div>
      </div>
      <div className='p-4 flex border-t border-b gap-x-4 divide-x-2 text-sm text-center md:text-left dark:border-gray-700 dark:divide-gray-700'>
        <div className='px-2'>
          {t('audit-form:failed-c', {
            total: validationResult?.details?.failedChecks,
          })}
        </div>
        <div className='px-2'>
          {t('audit-form:failed-r', {
            total: validationResult?.details?.failedRules,
          })}
        </div>
        <div className='px-2'>
          {t('audit-form:passed-c', {
            total: validationResult?.details?.passedChecks,
          })}
        </div>
        <div className='px-2'>
          {t('audit-form:passed-r', {
            total: validationResult.details.passedRules,
          })}
        </div>
      </div>

      <ul className='divide-y dark:divide-gray-700'>
        {details.map((summary) => {
          return (
            <li
              key={`${summary.testNumber}-${summary.object}-${summary.ruleStatus}`}
              className='p-4 space-y-1'
            >
              <div>
                <div className='font-bold uppercase'>{summary.ruleStatus}</div>
                <div className='text-sm'>{summary.object}</div>
              </div>

              <Paragraph>{summary.description}</Paragraph>

              <ul className='flex space-x-2 truncated max-w-[90vw] overflow-x-auto'>
                {summary.checks.map((checks, i) => {
                  return (
                    <li key={checks.context + i} className='capitalize'>
                      {checks.status} - {checks.context}
                    </li>
                  )
                })}
              </ul>

              <div className={'text-xs'}>{summary.specification}</div>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export const PdfFeedCell = observer(PdfFeedCellComponent)
