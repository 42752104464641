import {
  useState,
  createContext,
  type SyntheticEvent,
  type FC,
  type PropsWithChildren,
  useEffect,
} from 'react'
import { Score } from '@app/types'
import { fetcher } from '@app/utils/fetcher'
import { API_ENDPOINT } from '@app/configs'
import { slugRoutes } from '../tables/scores/api-route-builder'
import { useRouter } from 'next/router'

type ScoresProps = {
  pages: Score[]
  domain?: string
  userID?: string
}

// get all the scores.
export const useScores = ({ pages, domain }: ScoresProps) => {
  const router = useRouter()
  const [$pages, setPages] = useState<Score[]>(pages)
  const [$pageLimit, setPagination] = useState({
    page: 0,
    limit: 20,
  })

  const [totalScores, _setTotalScores] = useState<number>(0)
  // visibility
  const [visible, setVisible] = useState<boolean>(false)
  // filter settings
  const [filterLoading, setFilterLoading] = useState<boolean>(false)
  const [filteredScoresList, setFiltered] = useState<Score[]>([])
  const [filteredPageIndex, setFilteredIndex] = useState<number>(1)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, _setError] = useState<string | undefined>()

  const [categories, setCategories] = useState<{
    [key: string]: { count: number; averageScore?: number }
  }>()
  const [categoriesLoading, setCategoriesLoading] = useState<boolean>()

  // pagination
  const filteredLength = filteredScoresList?.length || 0
  const pagesSet = filteredLength ? filteredScoresList : $pages

  useEffect(() => {
    setPages(pages)
  }, [pages])

  const params = router?.query

  const onLoadMoreEvent = async (_?: any, _page?: number) => {
    setLoading(true)
    const _currentPage = _page ?? $pageLimit.page

    // set the base page of the request.
    const pageTo = $pageLimit.limit * _currentPage

    const { scoresUrl } = slugRoutes(params?.slug, pageTo, $pageLimit.limit)

    const res = await fetcher(scoresUrl.href, null, 'GET', 'force')

    const responseLength = res?.data?.length ?? 0

    if (res) {
      // allow data to update without conflicts.
      setPages((prev: Score[] = []) => {
        const prevPagesMap = prev.reduce((acc: any, page: Score) => {
          acc[page.domain] = page
          return acc
        }, {})

        for (const newPage of res.data || []) {
          prevPagesMap[newPage.domain] = newPage
        }

        return Object.values(prevPagesMap)
      })

      // bump pagination if the page returns exactly the limit
      if (responseLength === $pageLimit.limit) {
        setPagination({ limit: $pageLimit.limit, page: _currentPage + 1 })
      }
    }

    setLoading(false)
  }

  const onSearchEvent = async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()
    const formData = new FormData(e.currentTarget)
    const { search } = Object.fromEntries(formData)
    setFilterLoading(true)
    const offset = $pageLimit.page * $pageLimit.limit

    const u = new URL(
      `${API_ENDPOINT}/list/scores?limit=${$pageLimit.limit}&offset=${offset}&search=${search}`
    )

    const res = await fetcher(u.href, null, 'GET', 'force')

    if (res) {
      setPages(res.data || [])
    }
    //   setFiltered(data || []);
    setFilteredIndex(1)
    setFilterLoading(false)
  }

  const onLoadMoreFiltersEvent = async (
    e: SyntheticEvent<HTMLButtonElement>
  ) => {
    e.preventDefault()
    const element = document.getElementById(
      `search-${domain}`
    ) as HTMLInputElement

    if (element) {
      const nextPage = filteredPageIndex + 1
      setFilterLoading(true)
      const search = element.value

      const { scoresUrl } = slugRoutes(
        params?.slug,
        nextPage,
        $pageLimit.limit,
        search
      )

      const res = await fetcher(scoresUrl.href, null, 'GET')

      if (res) {
        setPages((prev) => {
          const combined = [...prev, ...(res.data || [])]
          const uniqueMap = new Map(combined.map((item) => [item.domain, item]))
          return Array.from(uniqueMap.values())
        })
      }

      setFilterLoading(false)
    }
  }

  const onClearSearch = () => {
    setFiltered([])
    setFilteredIndex(1)
  }

  const onSetVisible = async () => {
    if (!Object.keys($pages ?? {}).length) {
      await onLoadMoreEvent(undefined, 0)
    }
    setVisible((x) => !x)
  }

  const onLoadCategoriesEvent = async () => {
    setCategoriesLoading(true)
    const res = await fetcher(`/scores-categories`, null, 'GET')
    if (res.data) {
      setCategories(res.data)
    }
    setCategoriesLoading(false)
  }

  return {
    onClearSearch,
    onLoadMoreFiltersEvent,
    onSearchEvent,
    onSetVisible,
    onLoadMoreEvent,
    filterLoading,
    filteredLength,
    pagesSet,
    totalScores,
    visible,
    loading,
    error,
    $pageLimit,
    $pages,
    categories,
    categoriesLoading,
    onLoadCategoriesEvent,
  }
}

export const ScoresContext = createContext({} as ReturnType<typeof useScores>)
export const ScoresProvider: FC<PropsWithChildren<ScoresProps>> = ({
  pages,
  domain,
  children,
}) => {
  const data = useScores({ pages, domain })

  return (
    <ScoresContext.Provider value={data}>{children}</ScoresContext.Provider>
  )
}
