import { FC } from 'react'
import { Link } from '@app/components/stateless/typo/link'

import { classNames } from '@app/utils/classes'

type NavItemProps = {
  href?: string
  name?: string
  className?: string
  as?: string
  route?: string
}

export const NavItem: FC<NavItemProps> = ({
  href,
  name,
  className = '',
  as,
  route,
}) => {
  const home = `/${href}` === route // display home route

  return (
    <Link
      href={home ? '/' : href || '/'}
      className={classNames(className, `font-sm hover:text-blue-600`)}
      as={as}
      shouldPrefetch={false}
    >
      {home ? 'Home' : name}
    </Link>
  )
}
