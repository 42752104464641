import { PropsWithChildren } from 'react'
import { GrFormAdd, GrFormCheckmark } from 'react-icons/gr'

const SubHeading = ({ children, pricingPage, ...extra }: any) =>
  pricingPage ? <h3 {...extra}>{children}</h3> : <h4 {...extra}> {children}</h4>

export const PriceFeat = ({
  details,
  pricingPage,
  title,
  subTitle,
  children,
  hobby,
  enterprise,
}: PropsWithChildren<any>) => {
  return (
    <div
      className={`flex-1 min-w-[330px] rounded flex flex-col justify-between bg-white dark:bg-black border-[#2A2A2A] border-t-[4px] border-2 md:flex-none md:w-auto md:max-w-[310px]`}
    >
      <div>
        <div className='text-left w-full flex-col px-4 py-1 border-b dark:border-gray-700'>
          <SubHeading
            className='text-lg md:text-lg lg:text-lg xl:text-xl font-semibold'
            pricingPage={pricingPage}
          >
            <span>{title}</span>
          </SubHeading>
          {subTitle ? (
            <div className='max-w-[350px] xl:max-w-[380px]'>
              <p className='text-xl max-w-[320px]'>{subTitle}</p>
            </div>
          ) : null}
        </div>

        <ul className='list-none px-4 space-y-2 py-4'>
          {details?.map((item: string, i: number) => (
            <li
              className={'flex gap-x-3 place-items-center text-left'}
              key={`${'feat'}-${i}`}
            >
              <div className='rounded-xl text-white stroke-white bg-gray-800'>
                {(hobby && (i === 1 || i === 2)) ||
                (!hobby &&
                  !enterprise &&
                  (i === 3 || i === 4 || i === 7 || i === 12 || i === 13)) ? (
                  <GrFormAdd className='grIcon' />
                ) : (
                  <GrFormCheckmark className='grIcon' />
                )}
              </div>
              <p className='text-sm'>{item}</p>
            </li>
          ))}
        </ul>
      </div>
      {children}
    </div>
  )
}
