import React from 'react'
import { ListSkeleton } from './list'

export function ReportEmptyView() {
  return (
    <div className={'w-full'}>
      <ListSkeleton count={9} avatar={false} report />
    </div>
  )
}
