import { type FC, type PropsWithChildren, memo } from 'react'
import { classNames } from '@app/utils/classes'

const Loader = () => {
  const circleCommonClasses = 'h-3 w-3 bg-current rounded-full'

  return (
    <div className='flex animate-in fade-in gap-1'>
      <div className={`${circleCommonClasses} animate-bounce delay-150`} />
      <div className={`${circleCommonClasses} animate-bounce delay-300`} />
      <div className={`${circleCommonClasses} animate-bounce delay-700`} />
    </div>
  )
}

// TODO: Loading... translation
const LinearBottomComponent: FC<PropsWithChildren<{ loading: boolean }>> = ({
  children,
  loading,
}) => {
  return (
    <div
      aria-hidden={!loading}
      className={classNames(
        loading ? '' : 'hidden',
        `fixed bottom-0 z-[100] left-0 right-0 px-4 py-1.5 w-full border-t border-gray-200 dark:border-gray-700 bg-gray-100 dark:bg-gray-900`
      )}
    >
      <div className='flex flex-1 py-2 place-items-center place-content-center text-lg'>
        {children ? children : <Loader />}
      </div>
    </div>
  )
}

export const LinearBottom = memo(LinearBottomComponent)
