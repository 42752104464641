import {
  forwardRef,
  DetailedHTMLProps,
  InputHTMLAttributes,
  ForwardedRef,
} from 'react'
import { classNames } from '@app/utils/classes'

interface InputProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  underline?: boolean // underline styles
  exactClass?: string
}

export const TextField = forwardRef(function TextField(
  { className, underline, exactClass, ...props }: InputProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  return (
    <input
      className={
        exactClass ??
        classNames(
          'rounded-sm',
          underline
            ? 'flex-1 px-4 py-2 border-b dark:border-gray-600 focus:outline-none focus:border-blue-700'
            : 'px-4 py-3 border-2 border-blue-600 rounded focus:border-sky-500 focus:ring-1 focus:ring-sky-500 disabled:bg-slate-50 disabled:text-slate-500 invalid:border-gray-700',
          className || ''
        )
      }
      ref={ref}
      {...props}
    ></input>
  )
})
